import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as $ from "jquery";
import { ApiService } from 'src/app/services/api.service';
import { constants } from 'src/app/providers/constants';
import { MenuService } from 'src/app/services/menu.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { state, keyframes, style, animate, trigger, transition } from '@angular/animations';
import { Platform } from '@ionic/angular';
import { TourService } from 'src/app/services/tour.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';

// interface page {
//   id: Number,
//   title: String,
//   sections: section[]
// }
class ListItem {
  id: Number;
  name: String;
}

class Field {
  id: Number;
  open: boolean;
  label: String;
  category: String;
  fieldDetails: {};
  listType: String;
  list_id: Number;
  list: Array<ListItem> = [];
  is_required: boolean;
  is_inactive: boolean;
  is_deleted: boolean;

  constructor() {
    this.label = '';
    this.open = true;
    this.fieldDetails = {};
    this.list_id = 0;
    // this.listType = 1;
    this.category = 'text';
    this.is_required = false;
    this.is_inactive = false;
    this.is_deleted = false;

    this.list.push(new ListItem());
  }
}

class Section {
  id: any;
  name: String;
  show_section: any;
  is_repeatable: boolean;
  show_if_field_id: Number;
  show_if_field_value_id: Number;
  fields: any = [];

  constructor() {
    this.show_section = '1';
    this.fields.push(new Field());
  }
}

class Page {
  id: any;
  name: String;
  sections: any = [];

  constructor() {
    // super();
    // this.id = null;
    // this.title = 'Page Title';
    this.sections.push(new Section());
  }
}

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  animations: [
    trigger("inOutAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [
        animate(
          500,
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0.25, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.75, offset: 0.75 }),
            style({ opacity: 1, offset: 1 }),
          ])
        )
      ]),
      transition(":leave", [
        animate(
          500,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0.75, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.25, offset: 0.75 }),
            style({ opacity: 0, offset: 1 }),
          ])
        )
      ])
    ]),
    trigger("slideAnimation", [
      state("in", style({ opacity: 1 })),
      state("out", style({ opacity: 1 })),
      transition("in => out", [
        animate(
          300,
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0.25, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.75, offset: 0.75 }),
            style({ opacity: 1, offset: 1 }),
          ])
        )
      ]),
      transition(":leave", [
        animate(
          300,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0.75, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.25, offset: 0.75 }),
            style({ opacity: 0, offset: 1 }),
          ])
        )
      ])
    ])
  ]
})

export class FormBuilderComponent implements OnInit {

  // @ViewChild('loadTemplate', { read: ViewContainerRef }) template: ViewContainerRef;
  // @ViewChild('previewTemplate', { read: ViewContainerRef }) previewTemplate: ViewContainerRef;
  // @ViewChild('textTemplate', { read: TemplateRef }) textTemplate: TemplateRef<any>;
  // @ViewChild('addTemplate', { read: TemplateRef }) addTemplateRef: TemplateRef<any>;
  // @ViewChild('dropdownTemplate', { read: TemplateRef }) dropdownTemplateRef: TemplateRef<any>;
  // @ViewChild('checkboxTemplate', { read: TemplateRef }) checkboxTemplate: TemplateRef<any>;
  // @ViewChild('questionTemplate', { read: TemplateRef }) questionTemplate: TemplateRef<any>;

  formId: any;
  moduleId: any;
  templateId: any;

  textTemplates: any = [];
  templatesList: any = [];
  questionTypes: any = [];
  previewTemplatesList: any = [];

  showSectionDefault: any = "1";
  isRendered: any = false;
  disableAnimation = true;
  //dynamic

  pages: any = [];
  formData: any;
  predefinedDropdownList: any = [];
  settingsForm: FormGroup;
  detailsForm: FormGroup;
  connectedTo = [];

  ownersList: any = [];

  dependentFieldsArray: any = [];
  dependentFieldValuesArray: any = [];
  dependentFieldId: any;
  dependentFieldValue: any;

  allDropdownList: any = [];

  showAddClass: any;
  selectedField: any;

  removedPages: any = [];
  removedSections: any = [];
  removedFields: any = [];

  file: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private platform: Platform,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public menuService: MenuService,
    private formBuilder: FormBuilder,
    public tourService: TourService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.initializeDetailsForm();
    this.initializeSettingsForm();
  }

  ionViewDidEnter() {
    this.setHeightForSegmentContent();
    // this.getDropdownList();
    // this.getFormFields();
    this.getOwners();
    this.getFieldTypes();
    console.log(this.pages);
    this.formId = this.route.snapshot.paramMap.get('formId');
    this.moduleId = this.route.snapshot.paramMap.get('moduleId');
    this.templateId = this.route.snapshot.paramMap.get('templateId');
    // console.log(this.route.snapshot.paramMap.get('moduleId'));
    console.log(this.route.snapshot.paramMap.get('templateId'));
    // this.getForm();
    this.showSectionDefault = 1;
  }

  getOwners() {
    // this.getTypeLists("user");
    this.apiService.get(constants.api_end_points.GET_FORM_OWNERS).subscribe(response => {
      if (response && response.code == 200) {
        if (response.data.length > 0) {
          this.ownersList = response.data;
        }
      }
    });
  }

  getAllDropdownLists(pageIndex, sectionIndex, section) {
    let list = [];
    this.pages.forEach((page, pIndex) => {
      if (pIndex < pageIndex || pIndex == pageIndex) {
        page.sections.forEach((_section, sIndex) => {
          if ((pageIndex == pIndex && sIndex < sectionIndex) || pageIndex != pIndex) {
            _section.fields.forEach(field => {
              if (field.fieldDetails.type == 'dropdown' || field.fieldDetails.type == 'radio' || field.fieldDetails.type == 'checkbox') {
                list.push(field);
              }
            });
          }
        });
      }
    });
    // this.pages[pageIndex].sections[sectionIndex - 1].fields.forEach(field => {
    //   if (field.fieldDetails.type == 'dropdown') {
    //     list.push(field);
    //   }
    // });
    // this.allDropdownList = list;
    section['conditionalDropdownList'] = list;
    return list;
  }

  getTypeLists(type) {
    this.loaderService.showLoader();
    let requestObject = {
      type: type
    };

    return new Promise((resolve, reject) => {
      this.apiService.post(constants.api_end_points.GET_TYPE_LISTS, requestObject).subscribe(response => {
        if (response && response.code == 200) {
          this.loaderService.hideLoader();
          if (response.data.length > 0) {
            resolve(response.data);
          }
          reject();
        } else {
          reject();
          this.loaderService.hideLoader();
        }
      }, err => {
        reject();
        this.loaderService.hideLoader();
      });
    });
  }

  getReservedDropdownsList() {
    let requestObject = {
      template_id: this.formId ? this.formData.template_id : Number(this.templateId)
      // module_id: this.formId ? this.formData.template.module_id : Number(this.moduleId)
    };

    this.apiService.post(constants.api_end_points.GET_RESERVED_DROPDOWNS, requestObject).subscribe(response => {
      if (response && response.code == 200) {
        if (response.data.length > 0) {
          this.dependentFieldsArray = response.data;
          let dependent_field_id, dependent_field_value;
          if (this.formId && this.formData.form_required_if) {
            this.detailsForm.patchValue({ required: 3 });
            dependent_field_id = this.dependentFieldsArray.find(x => x.field_id == this.formData.form_required_if.dependent_field_id);
            if (dependent_field_id)
              this.detailsForm.patchValue({ field_id: dependent_field_id });
          }
          response.data.forEach(element => {
            let choiceTypes = ['dropdown', 'checkbox', 'radio'];
            if (!choiceTypes.includes(element.type)) {
              // field.list.list_values = this.getTypeLists(field.fieldDetails.type);
              this.getTypeLists(element.type).then(value => {
                element.values = value;
                if (this.formId && this.formData.form_required_if && !dependent_field_value) {
                  element.values.forEach(element => {
                    if (element.id == this.formData.form_required_if.dependent_list_value_id)
                      dependent_field_value = element;
                    if (element.children && element.children.length > 0) {
                      element.children.forEach(e => {
                        if (e.id == this.formData.form_required_if.dependent_list_value_id)
                          dependent_field_value = e;
                      });
                    }
                  });
                  if (dependent_field_value) {
                    this.detailsForm.patchValue({ field_value: dependent_field_value });
                    this.dependentFieldValuesArray = element.values;
                  }
                }
              })
            }
          });

          console.log(this.dependentFieldsArray);
        }
      }
    });
  }

  updateValuesArray(event) {
    console.log(event);
    this.dependentFieldValuesArray = event.value.values;
  }

  drop(event: CdkDragDrop<string[]>, list) {
    //moveItemInArray(list, event.previousIndex, event.currentIndex);
    this.disableAnimation = true
    if (event.previousContainer === event.container) {
      moveItemInArray(list, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    setTimeout(() => this.disableAnimation = false)
  }

  initializeSettingsForm(data = null) {
    this.settingsForm = this.formBuilder.group({
      is_inactive: new FormControl(((data && data.is_inactive) ? data.is_inactive : false), []),
      title: new FormControl(((data && data.name) ? data.name : null), [Validators.required]),
      description: new FormControl(((data && data.description) ? data.description : null), []),
      // userType: new FormControl(null, [Validators.required])
    });
  }

  initializeDetailsForm(data = null) {
    this.detailsForm = this.formBuilder.group({
      owner: new FormControl(((data && data.form_owner_id) ? data.form_owner_id : null), [Validators.required]),
      required: new FormControl(((data && (data.is_required || data.is_required == false)) ? data.is_required : true), [Validators.required]),
      field_id: new FormControl(((data && data.field_id) ? data.field_id : null), []),
      field_value: new FormControl(((data && data.field_value) ? data.field_value : null), []),
    });
  }

  addPage() {
    this.disableAnimation = true
    this.pages.push(new Page());
    setTimeout(() => this.disableAnimation = false);
  }

  addSection(page) {
    this.disableAnimation = true
    page.sections.push(new Section());
    setTimeout(() => this.disableAnimation = false);
  }

  addField(section) {
    let previousField = section.fields[section.fields.length - 1];
    if (previousField) {
      let field = new Field();
      if(!previousField.reserved_field_id) {
        field.fieldDetails = previousField.fieldDetails;
      } else {
        field.fieldDetails = this.questionTypes[0].items[0];
        field.label = 'Question';
      }
      section.fields.push(field);
    } else
      section.fields.push(new Field());
  }

  addOption(field) {
    field.list.push(new ListItem());
  }

  onFocus(event, field) {
    if (!event.target.value) {
      this.addOption(field);
    }
  }

  itemMove(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  removePage(page, list, index) {
    page['is_deleted'] = true;
    this.removedPages.push(page);
    list.splice(index, 1);
  }

  removeSection(page, section, list, index) {
    section['is_deleted'] = true;
    if (!page['removedSections'])
      page['removedSections'] = [];
    page['removedSections'].push(section);
    // this.removedSections.push(section);
    list.splice(index, 1);
  }

  removeField(section, field, list, index) {
    field['is_deleted'] = true;
    if (!section['removedFields'])
      section['removedFields'] = [];
    section['removedFields'].push(field);
    // this.removedFields.push(field);
    list.splice(index, 1);
  }

  removeOption(list, index) {
    list.splice(index, 1);
  }

  resetListSelectionValue(field) {
    field.list_id = 0;
    field.list = [];
    field.listName = '';
    field.list.push(new ListItem());
    this.selectedField = field;
    if (field.fieldDetails.category == 'choice')
      field.focused = true;
    if (field.listType == 'new')
      this.displayAddSection();
  }

  listChange(field, event) {
    console.log(event);
    let value = event.value;
    field.listName = value.label;
    field.list = value.list_values;
    field.list_id = value.id;
  }

  updateCondtionalFlag(event, section, sectionIndex) {
    let value = event.value;

    // this.allDropdownList.forEach(field => {
    //   if (field.conditional_values && field.conditional_values.length > 0) {
    //     let indexArray = [];
    //     field.conditional_values.forEach((element, index) => {
    //       if (element.section_id && element.section_id == section.id) {
    //         indexArray.push(index);
    //       } else if (element.section_position && element.section_position == sectionIndex) {
    //         indexArray.push(index);
    //       }
    //     });
    //     if (indexArray.length > 0) {
    //       indexArray.forEach(index => {
    //         field.conditional_values.splice(index, 1);
    //       });
    //     }
    //   }
    // });

    // let sectional_object = {

    // };

    // if (value.conditional_values && value.conditional_values.length > 0) { }


    // value['isConditionallyRendered'] = true;
  }

  updateCondtionalValue(event, section, sectionIndex, pageIndex, pageId) {
    let value = event.value;
    section.conditionalDropdownList.forEach(field => {
      console.log(field);
      if (field.conditional_values && field.conditional_values.length > 0) {
        let indexArray = [];
        field.conditional_values.forEach((element, index) => {
          if ((pageId && pageId == element.pageId) || pageIndex && pageIndex == element.pageIndex) {
            if (element.section_id && element.section_id == section.id) {
              indexArray.push(index);
            } else if (element.section_position && element.section_position == sectionIndex) {
              indexArray.push(index);
            }
          }
        });
        if (indexArray.length > 0) {
          indexArray.forEach(index => {
            field.conditional_values.splice(index, 1);
          });
        }
      }
    });

    let sectional_object = {
      value: value.id,
      pageIndex: pageIndex,
      pageId: pageId,
      section_id: section.id
    };

    // if (section.id)
    //   sectional_object['section_id'] = section.id;
    // else
    sectional_object['section_position'] = (sectionIndex + 1);

    if (section.conditional_question.conditional_values && section.conditional_question.conditional_values.length > 0)
      section.conditional_question.conditional_values.push(sectional_object)
    else
      section.conditional_question['conditional_values'] = [sectional_object]
  }

  addTemplate() {
    // let obj = {
    //   id: this.templatesList.length,
    //   // previewTemplateId: 'previewTemplate_' +  ,
    //   questionName: '',
    //   questionType: null,
    //   mandatory: false,
    //   textType: null,
    //   options: [],
    //   checkboxes: [],
    //   showTemplate: true
    // };
    // this.templatesList.push(obj);
    // this.template.createEmbeddedView(this.addTemplateRef, { $implicit: obj });
  }

  updateView(event, templateData) {
    //   let element = document.getElementById('previewTemplate_' + templateData.id);
    //   if (!element) {
    //     let obj = JSON.parse(JSON.stringify(templateData));
    //     obj['template_id'] = 'previewTemplate_' + templateData.id;
    //     this.previewTemplatesList.push(obj);
    //     let template;
    //     switch (templateData.questionType) {
    //       case 1: template = this.textTemplate; break;
    //       case 2: template = this.dropdownTemplateRef; break;
    //       case 3: template = this.checkboxTemplate; break;
    //     }
    //     this.previewTemplate.createEmbeddedView(this.questionTemplate, { $implicit: obj });
    //   } else {
    //     let template = this.previewTemplatesList.find(x => x.id == templateData.id);
    //     if (template) {
    //       // template = JSON.parse(JSON.stringify(templateData));
    //       template.questionName = templateData.questionName;
    //       template.questionType = templateData.questionType;
    //       template.options = templateData.options;
    //       template.mandatory = templateData.mandatory;
    //       template.inputType = templateData.inputType;
    //       template.checkboxes = templateData.checkboxes;
    //       template['template_id'] = 'previewTemplate_' + templateData.id;
    //       console.log(template);
    //     }
    //   }
    //   console.log(this.templatesList);
    // }

    // removeTemplate(event, templateData) {
    //   let template = this.templatesList.find(x => x.id == templateData.id);
    //   if (template) {
    //     template.showTemplate = false;
    //   }
    //   let previewTemplate = this.previewTemplatesList.find(x => x.id == templateData.id);
    //   if (previewTemplate) {
    //     previewTemplate.showTemplate = false;
    //   }
  }

  addOptionToList(templateData, inputElement) {
    let obj = {
      id: templateData.options.length,
      name: inputElement.value
    };
    if (templateData.questionType == 2) {
      templateData.options.push(obj);
    } else {
      templateData.checkboxes.push(obj);
    }
  }

  injectTemplate() {
    this.addTemplate();
    // let formObject = {

    // };
    // let id = this.textTemplates.length;
    // let obj = {
    //   id: 'previewTemplate' + '_' + id,
    //   label: 'some description' + this.textTemplates.length
    // };
    // this.textTemplates.push(obj);
    // this.template.createEmbeddedView(this.textTemplate, { $implicit: { fullName: this.textTemplates[id].label, id: 'textTemplate' + '_' + id } });
    // this.previewTemplate.createEmbeddedView(this.textTemplate, { $implicit: obj });
  }

  onBlurMethod(event, id) {
    if (id.includes('textTemplate')) {
      console.log($(id), event.target.value);
      let x = document.getElementById(id);
      let _id = id.split('_')[1];
      let b;
      if (id)
        b = document.getElementById('previewTemplate_' + _id);
      b.value = event.target.value;
      console.log(x);
      this.textTemplates[_id].label = event.target.value;
    }
  }

  setHeightForSegmentContent() {
    let addClassHeight = $('body')[0].clientHeight - 50;
    if (addClassHeight > 20) {
      // $(".form-builder-content-class").css('height', addClassHeight + 'px');
      // $(".form-builder-content-class").css('max-height', addClassHeight + 'px');
      return {
        height: addClassHeight + 'px',
        'max-height': addClassHeight + 'px',
        overflow: 'auto'
      };
    } else {
      return {
        height: 670 + 'px',
        'max-height': 670 + 'px',
        overflow: 'auto'
      };
    }
  }

  setStylesForLeftContainer() {
    let addClassHeight = $(".form-builder-content-class")[0].clientHeight;
    return {
      height: addClassHeight + 'px',
      'max-height': addClassHeight + 'px',
      overflow: 'auto',
      width: this.showAddClass ? '75%' : '100%'
    };
  }

  setHeightForAddSection() {
    let addClassHeight = $(".form-builder-content-class")[0].clientHeight;
    return {
      height: addClassHeight + 'px'
    };
  }

  cancel() {
    this.location.back();
  }

  getFormFields() {
    let requestObject = {
      form_id: 21
    };
    this.apiService.post(constants.api_end_points.GET_FORM_FIELDS, requestObject).subscribe(response => {
      if (response.code == 200) {
        console.log(response);
      }
    })
  }

  getFieldTypes() {
    this.apiService.get(constants.api_end_points.GET_FIELD_TYPES).subscribe(response => {
      if (response.code == 200) {
        console.log(response);
        this.questionTypes = response.data;
        this.getDropdownList();
      }
    });
  }

  getDropdownList() {
    this.apiService.get(constants.api_end_points.GET_DROPDOWN_LIST).subscribe(response => {
      if (response.code == 200) {
        console.log(response);
        this.predefinedDropdownList = response.data;
        // this.getForm();
        if (this.formId) {
          this.getForm();
        } else {
          let newPage = new Page();
          this.pages.push(newPage);
          this.isRendered = true;
          // console.log(this.pages);
          this.isRendered = true;
          this.getReservedDropdownsList();
        }
      }
    });
  }

  stopToggle(field) {
    console.log('toggle', event);
    field.focused = !field.focused;
    // event.preventDefault();
    // event.stopPropagation();
  }

  getForm() {
    let requestObject = {
      "form_id": Number(this.formId)
    };
    this.apiService.post(constants.api_end_points.GET_FORM, requestObject).subscribe(response => {
      if (response.code == 200) {
        console.log(response);
        this.pages = response.data.pages;
        this.initializeSettingsForm(response.data);
        this.initializeDetailsForm(response.data)
        this.formData = response.data;
        this.getReservedDropdownsList();
        console.log(this.formData);
        let allFormFields = [];
        this.pages.forEach(page => {
          page.sections.forEach(section => {
            section.fields.forEach(field => {
              let formField;
              this.questionTypes.forEach(element => {
                element.items.forEach(item => {
                  if (item.id == field.form_field_metadata_id)
                    formField = item;
                });
                // if(element.category == )
              });
              if (formField) {
                field.fieldDetails = formField;
              } else {
                field.fieldDetails = this.questionTypes[0].items[0];
              }
              field.list_id ? field['listType'] = 'old' : 'new';
              if (field.list_id && field.list) {
                field['listType'] = 'old';
                let list_values = field.list.list_values;
                let listName = field.list.name;
                field['list'] = list_values;
                field['listName'] = listName;
              }
              allFormFields.push(field);
            });
            if (section.show_if_field_id && section.show_if_field_value) {
              let conditional_field = allFormFields.find(x => x.id == section.show_if_field_id);
              if (conditional_field) {
                section['conditional_question'] = conditional_field;
                let conditional_value = section.conditional_question.list.find(x => x.id == section.show_if_field_value);
                if (conditional_value) {
                  section['conditional_question_value'] = conditional_value;
                  section['show_section'] = '2';
                }
              }
            }
          });
        });
        // this.predefinedDropdownList = response.data;
        // Workaround for angular component issue #13870
        setTimeout(() => this.disableAnimation = false);
        this.isRendered = true;
      }
    });
  }

  // addList(field) {
  //   let listObjects = [];
  //   field.list.forEach((element, i) => {
  //     let listObject = {};
  //     if (element.value) {
  //       listObject['value'] = element.value;
  //       listObject['position'] = (i + 1);
  //       listObject['is_inactive'] = false;
  //       listObject['score'] = 0;
  //       listObject['colour_rgb'] = "";
  //       listObjects.push(listObject);
  //     }
  //   });

  //   let requestObject = {
  //     name: field.listName,
  //     // description: this.listForm.value.description,
  //     label: field.listName,
  //     listValues: listObjects,
  //     is_account_list: false,
  //     template_id: this.templateId ? this.templateId : this.formData.template_id,
  //   };

  //   if (this.formData.id) {
  //     requestObject['id'] = this.formData.id;
  //     requestObject['module_id'] = this.formData.template.module_id
  //   }

  //   this.apiService.post(constants.api_end_points.ADD_LIST, requestObject).subscribe(response => {
  //     if (response.code == 200) {
  //       field.list_id = response.data.id;
  //       alert('List Added!');
  //       // this.listForm.reset();
  //       // if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb')) {
  //       //   this.router.navigate(['account-settings/' + this.elementId]);
  //       // } else {
  //       //   this.updateView.emit(true);
  //       // }
  //     } else {
  //       alert(response.message);
  //     }
  //   });
  // }

  updateList(event) {
    console.log('update list');
    this.selectedField.list_id = event.id;
    this.selectedField.listName = event.listName;
    this.selectedField.list = event.listValues;
    this.showAddClass = false;
  }

  checkFormValidity() {
    if (this.formId && (this.formData.position > 1 && !this.detailsForm.valid) || !this.settingsForm.valid) {
      return true
    }
    return false;
  }

  saveForm() {
    let fieldsCount = 0, validFields = 0;
    this.loaderService.showLoader();

    let checkCustomValidations = (field) => {
      if (!field.is_deleted) {
        if (!field.label) {
          this.apiService.errorSnackBar('Please enter a label for question!');
          return false;
        }
        if (field.fieldDetails && !field.fieldDetails.type) {
          this.apiService.errorSnackBar('Please select a question type!');
          return false;
        }
        if (field.fieldDetails && (field.fieldDetails.type == 'dropdown' || field.fieldDetails.type == 'checkbox' || field.fieldDetails.type == 'radio') && !field.list_id) {
          this.apiService.errorSnackBar('Please select list values for dropdown question type!');
          return false;
        }
      }
      return true;
    }

    let deepClone = (data) => {
      let returnValue, value, key;
      let keysArray = ['conditional_question', 'conditional_question_value', 'show_section', 'conditionalDropdownList']
      returnValue = Array.isArray(data) ? [] : {};
      for (key in data) {
        if (!keysArray.includes(key)) {
          value = data[key];
          returnValue[key] = (typeof value === "object" && value !== null && key != 'datetime') ? deepClone(value) : value;
        }
      }
      return returnValue;
    };

    this.pages.forEach((page, pageIndex) => {
      page['position'] = pageIndex + 1;
      page.sections.forEach((section, sectionIndex) => {
        section['position'] = sectionIndex + 1;
        section.fields.forEach((field, fieldIndex) => {
          field['position'] = fieldIndex + 1;
        });
      });
    });
    // let pages = JSON.parse(JSON.stringify(this.pages));
    let pages = deepClone(this.pages);

    if (this.removedPages) {
      pages = pages.concat(this.removedPages);
    }

    pages.forEach(page => {

      if (page.removedSections) {
        page.sections = page.sections.concat(page.removedSections);
        delete page['removedSections'];
      }

      page.sections.forEach(section => {
        if (section.removedFields) {
          section.fields = section.fields.concat(section.removedFields);
          delete section['removedFields'];
        }
        let fields = [];

        section.fields.forEach((field, index) => {
          if (!field.is_deleted)
            fieldsCount++;
          if (checkCustomValidations(field)) {
            if (!field.is_deleted)
              validFields++;
            let fieldObject = {
              form_field_metadata_id: field.fieldDetails.id,
              label: field.label,
              is_inactive: field.is_inactive,
              is_required: field.is_required,
              is_deleted: field.is_deleted,
              position: (index + 1),
              is_multiple_choice: field.is_multiple_choice,
            };

            if (field.id)
              fieldObject['id'] = field.id;

            if (field.fieldDetails.category == 'choice')
              fieldObject['listType'] = field.listType;

            if (field.fieldDetails.category == 'choice' && field.list && field.list.length > 0) {
              let list = [];
              field.list.forEach((element, i) => {
                let listObject = {};

                if (element.name) {
                  listObject['value'] = element.name;
                  listObject['position'] = (i + 1);
                  listObject['parent_id'] = "";
                  listObject['additional_value'] = "";
                }
                list.push(listObject);
              });
              // if (field.fieldDetails.category == 'choice' && field.listType == 'new') {
              //   fieldObject['list'] = list;
              //   fieldObject['listName'] = field.listName;
              // }
            }
            if (field.fieldDetails.category == 'choice' && field.list_id) {
              fieldObject['listType'] = 'old';
              fieldObject['list_id'] = field.list_id;
            }
            if (field.conditional_values && field.conditional_values.length > 0)
              fieldObject['conditional_values'] = field.conditional_values;
            fields.push(fieldObject);
          } else {
            this.loaderService.hideLoader();
            console.log('retuen value');
            return;
          }

        });


        if (section.conditional_question) {
          // delete section['conditional_question'];
          // delete section['conditional_question_value'];


          // if (section.conditional_question.id) {
          //   section['conditionalFieldId'] = section.conditional_question.id;
          // } else {
          //   let f = section.fields.find(x => x.position == section.conditional_question.position);
          //   if (f)
          //     f['isConditionallyRendered'] = true;
          // }

          // if (section.conditional_field_value) {
          //   section['conditionalFieldValue'] = section.conditional_field_value.name;
          //   delete section['conditional_field_value'];
          //   delete section['conditional_question'];
          // }
        }
        // if (section.show_section)
        //   // delete section['show_section'];

        // if (section.conditionalDropdownList)
        //   delete section['conditionalDropdownList'];
        section.fields = fields;

      });
    });

    if (fieldsCount == validFields) {
      let formSettings = {
        is_required: this.detailsForm.value.required,
        form_owner_id: this.detailsForm.value.owner
      }

      let requestObject = {
        // template_id: this.templateId,
        template_id: this.formId ? this.formData.template_id : this.templateId,
        name: this.settingsForm.value.title,
        description: this.settingsForm.value.description,
        is_inactive: this.settingsForm.value.is_inactive,
        pages: pages,
        settings: formSettings
      };

      if (this.formId) {
        // requestObject['module_id']
        requestObject['id'] = Number(this.formId);
      }

      console.log(requestObject);

      let url = this.formId ? constants.api_end_points.UPDATE_FORM : constants.api_end_points.ADD_FORM;

      this.apiService.post(url, requestObject).subscribe(response => {
        if (response.code == 200) {
          // console.log(response);
          // this.location.back();
          if (this.detailsForm.value.required == 3) {
            this.setFormSettings(this.formId ? this.formId : response.data.form_id).then(() => {
              this.apiService.successSnackBar('Form Saved Successfully');
              this.loaderService.hideLoader();
              if (this.formId) {
                this.router.navigate(['template-settings/' + this.formData.template.module_id + '/' + this.formData.template_id]);
              } else {
                this.router.navigate(['template-settings/' + this.moduleId + '/' + this.templateId]);
              }
            })
          } else {
            this.apiService.successSnackBar('Form Saved Successfully');
            this.loaderService.hideLoader();
            if (this.formId) {
              this.router.navigate(['template-settings/' + this.formData.template.module_id + '/' + this.formData.template_id]);
            } else {
              this.router.navigate(['template-settings/' + this.moduleId + '/' + this.templateId]);
            }
          }
          // this.getForm();
          // this.questionTypes = response.data;
        } else {
          this.loaderService.hideLoader();
          alert(response.message);
        }
      });
    }
  }

  setFormSettings(formId) {
    let requestObject = {
      form_id: formId,
      dependent_field_id: this.detailsForm.value.field_id.field_id,
      dependent_list_value: this.detailsForm.value.field_id.type == 'business_unit' || this.detailsForm.value.field_id.type == 'site' ? this.detailsForm.value.field_value.name : this.detailsForm.value.field_value.value,
      dependent_list_value_id: this.detailsForm.value.field_value.id,
    };
    return new Promise((resolve, reject) => {
      this.apiService.post(constants.api_end_points.SAVE_FORM_SETTINGS, requestObject).subscribe(response => {
        if (response.code == 200) {
          resolve(response);
        } else {
          this.apiService.errorSnackBar('Unable to save form settings!');
          reject();
        }
      }, err => {
        reject();
      });
    })
  }

  displayAddSection() {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      // let routeData: NavigationExtras = {
      //   state: {
      //     // elementId: id
      //     // elementId: 1,
      //   }
      // }
      // this.router.navigate(['add/' + 6], routeData);
      this.openDialog();

    } else {
      this.showAddClass = true;
      // this.setAbsoluteClassForDetails = false;
      this.setHeightForAddSection();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '400px',
      data: {
        dialogTitle: 'ADD LIST',
        buttonTitle: 'ADD',
        type: 'list'
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      console.log('dialog close', response);
      if (response)
        this.updateList(response);
      // this.feedDataService.getFeedData(this.formDetails.eventId);
    })
  }

  uploadFile(event) {
    this.file = event;
  }

  // cancelUpload() {
  //   this.file = null;
  // }

  addAttachment(event, field) {
    this.loaderService.showLoader();
    let requestObject = new FormData();
    requestObject.set('file', event);
    this.apiService.fileUpload(constants.api_end_points.UPLOAD_FILE, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.file = response.data;
        this.loaderService.hideLoader();
        field.label = this.file.url;
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    });
  }

  removeAttachment(field) {
    console.log(this.file);
    this.file = null;
    field.label = null;
  }

}

