import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteAuthGuard implements CanActivate {

  constructor(private platform: Platform, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      console.log(state);
    if (state.url.includes('register') || state.url.includes('reset-password')) {
      return true;
      
    } else {
      if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    }
    // return true;
  }

}
