import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import * as $ from 'jquery';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { state, keyframes, style, animate, trigger, transition } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomDialogComponent } from 'src/app/components/custom-dialog/custom-dialog.component';
import { FeedDataService } from 'src/app/services/feed-data.service';

@Component({
  selector: 'app-view-form',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.scss'],
  animations: [
    trigger("inOutAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [
        animate(
          500,
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0.25, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.75, offset: 0.75 }),
            style({ opacity: 1, offset: 1 }),
          ])
        )
      ]),
      transition(":leave", [
        animate(
          500,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0.75, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.25, offset: 0.75 }),
            style({ opacity: 0, offset: 1 }),
          ])
        )
      ])
    ]),
  ]
})

export class ViewFormComponent implements OnInit {
  @Input() editForm: any;
  @Input() formDetails: any;
  @Input() isEditForm: any;
  @Input() isEventForm: any;
  @Input() isActionForm: any;
  @Input() isRelatedEvent: any;
  @Input() fieldAnswerId: any;
  @Input() addActionToNewField: any;
  @Input() formEventId: any;
  @Input() formModuleId: any;
  @Input() formTemplateId: any;
  @Output() updateView: any = new EventEmitter<any>();
  @Output() cancelEvent: any = new EventEmitter<any>();
  @Output() addActionToEvent: any = new EventEmitter<any>();
  formId: any;
  formData: any;
  pages: any = [];
  currentPage: any = 0;
  isAddItemVisible: any = 0;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private platform: Platform,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private feedDataService: FeedDataService,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    // this.formId = this.route.snapshot.paramMap.get('formId');
    // if (this.formId)
    //   this.getForm();
    this.currentPage = 0;
  }

  ionViewWillEnter() {
    this.currentPage = 0;
  }

  setHeightForSegmentContainer() {
    // $(document).ready(function () {
    //   setTimeout(() => {
    // console.log($(".content-class")[0].clientHeight);
    // console.log($(".verification-header")[0].clientHeight);
    // console.log($(".button-container")[0].offsetHeight);
    // let addClassHeight = $(".content-class")[0].clientHeight - 94 - 25;
    let addClassHeight = $('body')[0].clientHeight - 50;
    // $(".actions-segment-container").css('height', addClassHeight + 'px');
    return {
      // 'max-height': addClassHeight + 'px',
      // 'overflow-y': 'auto'
    };
    // console.log(addClassHeight)
    // }, 100);
    // });
  }

  toggleSection(section) {
    section.collapsed = !section.collapsed;
  }

  toggleIsAddItemVisible(item) {
    //console.log(item);
    //console.log(this.isAddItemVisible);
    if (this.isAddItemVisible == item) {
      this.isAddItemVisible = 0;
    }
    else {
      this.isAddItemVisible = item;
    }
    //console.log(this.isAddItemVisible);
  }

  getForm(id = null) {
    let requestObject = {
      "form_id": id ? id : this.formId
    };
    this.apiService.post(constants.api_end_points.GET_FILLED_FORM, requestObject).subscribe(response => {
      if (response.code == 200) {
        console.log(response);
        this.pages = response.data.pages;
        // this.initializeSettingsForm(response.data);
        this.formData = response.data;
        console.log(this.formData);
        this.pages.forEach(page => {
          page.sections.forEach((section, sectionIndex) => {
            section['collapsed'] = false;
            let sectionForm;
            let formObject = {};
            section.fields.forEach(field => {
              let controlName = field.label.toLowerCase();
              controlName = controlName.replace(/ /g, "_");
              field['controlName'] = controlName;
              if (field.fieldDetails.category == 'choice' && (!field.list || (field.list && !field.list.list_values))) {
                field['list'] = {
                  list_values: []
                };
                let choiceTypes = ['dropdown', 'checkbox', 'radio'];
                if (!choiceTypes.includes(field.fieldDetails.type)) {
                  // field.list.list_values = this.getTypeLists(field.fieldDetails.type);
                  this.getTypeLists(field.fieldDetails.type).then(value => {
                    field.list.list_values = value;
                  })
                }
              }
              formObject[field.controlName] = new FormControl(null, field.is_required ? Validators.required : []);
              if (field.fieldDetails.type == 'whole')
                formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]{0,20}"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]{0,20}")]);
              formObject[field.controlName].updateValueAndValidity();
              if (field.fieldDetails.type == 'decimal')
                formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]+(\.[0-9]{1,6})?$"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]+(\.[0-9]{0,6})?$")]);
              formObject[field.controlName].updateValueAndValidity();
              if (field.fieldDetails.type == 'currency')
                formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]+(\.[0-9]{1,2})?$"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]+(\.[0-9]{1,2})?$")]);
              formObject[field.controlName].updateValueAndValidity();
            });
            section['sectionForm'] = this.formBuilder.group(formObject);
          });
        });
        // this.predefinedDropdownList = response.data;
        console.log(this.pages);
      }
    });
  }

  getTypeLists(type) {
    let requestObject = {
      type: type == 'business_unit' ? 'businessUnit' : type
    };
    return new Promise((resolve, reject) => {
      this.apiService.post(constants.api_end_points.GET_TYPE_LISTS, requestObject).subscribe(response => {
        if (response && response.code == 200) {
          if (response.data.length > 0) {
            resolve(response.data);
          }
          reject();
        }
      }, err => {
        reject();
      });
    })
  }

  openDialog(type, fieldId = null, field = null) {
    let title;
    switch (type) {
      case 'comment': title = 'Add Comment'; break;
      case 'attachment': title = 'Add Attachment'; break;
      case 'action': title = 'Add Action'; break;
    }

    if (type == 'action' && !this.formDetails.isComplete)
      this.addActionToNewField = true;
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: type == 'action' ? '450px' : '400px',
      height: type == 'action' ? '620px' : 'auto',
      data: {
        dialogTitle: title,
        buttonTitle: 'ADD',
        eventId: this.formDetails.eventId,
        formId: this.formDetails.formData.id,
        fieldId,
        type,
        isComplete: this.formDetails.isComplete ? this.formDetails.isComplete : false,
        moduleId: this.formDetails.formData.template.module_id,
        templateId: this.formDetails.formData.template_id,
        addActionToNewField: this.addActionToNewField
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      console.log('dialog close', response);
      if (response) {
        if (response.type) {
          if (response.type == 'Action')
            this.addActionToNewField = false;
          this.formDetails.eventId = response.eventId;
          if (field.attachments)
            field.attachments.push(response);
          else
            field['attachments'] = [response];
        } else
          this.feedDataService.getFeedData(this.formDetails.eventId);
      }
    })
  }

  removeAttachment(feed) {
    this.loaderService.showLoader();
    let requestObject = {
      id: feed.id
    };

    this.apiService.post(constants.api_end_points.REMOVE_ATTACHMENT, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.feedDataService.getFeedData(this.formDetails.eventId);
        this.apiService.successSnackBar('Attachment Removed!')
        this.loaderService.hideLoader();
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, error => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went wrong!');
    })
  }

  getFeedData(id) {
    return this.feedDataService.feedData.filter(x => x.form_id && x.field_id && x.form_id == this.formDetails.formData.id && x.field_id == id);
  }

  validateForm() {
    let sectionsCount = 0, validSections = 0;
    this.formDetails.formData.pages.forEach(page => {
      page.sections.forEach(section => {
        if (section.show_section) {
          sectionsCount++;
          if (section.sectionForm.valid)
            validSections++;
        }
      });
    });
    if (sectionsCount == validSections || this.currentPage != (this.formDetails.pages.length - 1))
      return true;
    return false;
  }

  saveForm(isDraft = false, navigate = true) {
    if (!isDraft)
      this.loaderService.showLoader();
    let deepClone = (data) => {
      let returnValue, value, key;
      returnValue = Array.isArray(data) ? [] : {};
      for (key in data) {
        if (key != 'sectionForm' && key != 'fields' && key != 'template') {
          value = data[key];
          returnValue[key] = (typeof value === "object" && value !== null && key != 'datetime') ? deepClone(value) : value;
        }
      }
      return returnValue;
    };

    let requestObject = this.formDetails.formData;
    requestObject['module_id'] = this.formDetails.formData.template.module_id;
    // delete requestObject['template'];
    // requestObject['is_draft_event'] = (isDraft && (this.formDetails.formData.position == 1 || this.formDetails.formData.form_answers[0].event_id)) ? true : false;
    requestObject['is_draft_event'] = (isDraft && this.formDetails.formData.position == 1) ? true : false;
    requestObject['is_draft_form'] = (isDraft && this.formDetails.formData.position >= 1) ? true : false;

    // let pages = JSON.parse(JSON.stringify(this.formDetails.formData.pages));
    requestObject.pages.forEach(page => {
      page.sections.forEach((section, sectionIndex) => {
        let answers = [];
        section['position'] = sectionIndex + 1;
        section.fields.forEach((field, index) => {
          let answerObject = {
            field_id: field.id,
            form_field_metadata: field.form_field_metadata_id,
            is_inactive: false,
            position: field.position ? field.position : (index + 1)
          };
          if (field.field_answer)
            answerObject['field_answer'] = field.field_answer;
          answerObject[field.fieldDetails.style] = section.sectionForm.value[field.controlName];

          if (field.attachments && field.attachments.length > 0) {
            let attachments = [];
            field.attachments.forEach(element => {
              let attachmentObject = {
                attachment_type: element.type,
                attachment_id: element.attachmentId
              };
              attachments.push(attachmentObject);
            });
            // answerObject['attachment_type'] = field.attachments[0].type;
            // answerObject['attachment_id'] = field.attachments[0].attachmentId;
            answerObject['attachments'] = attachments;
          }

          if (answerObject[field.fieldDetails.style] || answerObject['attachments'])
            answers.push(answerObject);

        });
        section['is_repeated'] = section.is_repeated ? section.is_repeated : false;
        section['answers'] = answers;
        // delete section['fields'];
        // delete section['sectionForm'];
      });
    });

    let finalRequestObject = deepClone(requestObject);

    if (this.formDetails.eventId)
      finalRequestObject['event_id'] = Number(this.formDetails.eventId);

    // if (isDraft && this.formDetails.isComplete)
    //   finalRequestObject['event_activity_id'] = Number(this.formDetails.eventId);

    if (this.formDetails.event_activity_id)
      finalRequestObject['event_activity_id'] = Number(this.formDetails.event_activity_id);

    finalRequestObject['form_type'] = this.isActionForm ? 'Action' : 'Form';
    if (this.isActionForm) {
      finalRequestObject['is_event_related_action'] = this.isRelatedEvent;
      if (this.fieldAnswerId) {
        finalRequestObject['is_field_action'] = true;
        finalRequestObject['field_answer_id'] = this.fieldAnswerId;
      }
    }

    console.log(requestObject, finalRequestObject);

    // finalRequestObject.pages.forEach(page => {
    //   page.sections.forEach(section => {
    //     delete section['fields'];
    //     delete section['is_repeatable'];
    //   });
    // });

    if (this.addActionToNewField) {
      this.addAction(finalRequestObject);
    } else {
      let url = constants.api_end_points.SAVE_FORM;
      if ((this.formDetails.eventId && !isDraft && this.formDetails.isComplete) || (isDraft && this.formDetails.isComplete))
        url = constants.api_end_points.UPDATE_FILLED_FORM;

      this.apiService.post(url, finalRequestObject).subscribe(response => {
        if (response && response.code == 200) {
          this.loaderService.hideLoader();
          if (response.data.event_id)
            this.formDetails.eventId = response.data.event_id;
          if (response.data.event_activity_id)
            this.formDetails.event_activity_id = response.data.event_activity_id;
          if (this.isRelatedEvent && this.dialog) {
            // this.updateView.emit();
            this.addActionToEvent.emit('action');
          } else {
            if (navigate && !this.isActionForm) {
              if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
                this.router.navigate(['feed']);
                if (!isDraft)
                  this.apiService.successSnackBar('Event Saved');
              } else {
                this.router.navigate(['feed']);
                if (!isDraft)
                  this.apiService.successSnackBar('Event Saved');
              }
            } else {
              if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
                this.router.navigate(['actions']);
                if (!isDraft)
                  this.apiService.successSnackBar('Event Saved');
              } else {
                this.updateView.emit();
                if (!isDraft)
                  this.apiService.successSnackBar('Event Saved');
              }
            }
          }
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar(response.message);
        }
      }, err => {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar('Something Went Wrong!');
      });
    }

  }

  addAction(finalRequestObject) {
    let request = {
      form_type: 'Form',
      template_id: this.formTemplateId,
      module_id: this.formModuleId,
      form_id: this.formId,
      event_id: this.formEventId ? Number(this.formEventId) : null,
      attachmentDetails: [
        {
          attachment_type: 'Action',
          actionFormDetails: finalRequestObject
        }
      ]
    };

    this.apiService.post(constants.api_end_points.ADD_ATTACHMENT_TO_FORM, request).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Action Added!');
        this.addActionToEvent.emit({
          type: 'Action',
          eventId: response.data[0].event_id,
          title: response.data[0].title,
          attachmentId: response.data[0].attachment_id
        });
      } else {
        this.loaderService.hideLoader();
      }
    }, err => {
      this.loaderService.hideLoader();
    });
  }

  renderConditionalSection(event, field) {
    console.log("event", event);
    console.log("field", field);
    if ((field.fieldDetails.type == 'dropdown' && field.is_multiple_choice == false) || field.fieldDetails.type == 'radio') {
      if (field.dependentSections) {
        field.dependentSections.forEach(element => {
          if (element.dependentValue == event.value) {
            this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].show_section = true;
          } else {
            this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].sectionForm.reset();
            this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].show_section = false;
          }
        });
      }
    }
    else
      if ((field.fieldDetails.type == 'dropdown' && field.is_multiple_choice == true)) {
        if (field.dependentSections) {
          field.dependentSections.forEach(element => {
            let correctDropDownCheck = false;
            event.value.forEach(selectedDropChecks => {
              if (selectedDropChecks == element.dependentValue) {
                correctDropDownCheck = true;
              }
            });
            if (correctDropDownCheck) {
              this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].show_section = true;
            } else {
              this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].sectionForm.reset();
              this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].show_section = false;
            }
          });
        }
        //this deals with the difference in how mat-selection-list holds information
      } else if (field.fieldDetails.type == 'checkbox') {
        if (field.dependentSections) {
          field.dependentSections.forEach(element => {
            let correctCheck = false;
            event.source.selectedOptions.selected.forEach(selectedChecks => {
              if (selectedChecks.value == element.dependentValue && selectedChecks.selected == true) {
                correctCheck = true;
              }
            });
            if (correctCheck) {
              this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].show_section = true;
            } else {
              this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].sectionForm.reset();
              this.formDetails.pages[element.pageIndex].sections[element.sectionIndex].show_section = false;
            }
          })
        }
      }
  }

  repeatSection(sections, section, sectionIndex) {

    let deepClone = (data) => {
      let returnValue, value, key;
      returnValue = Array.isArray(data) ? [] : {};
      for (key in data) {
        if (key != 'sectionForm' && key != 'datetime') {
          value = data[key];
          returnValue[key] = (typeof value === "object" && value !== null) ? deepClone(value) : value;
        }
      }
      return returnValue;
    };

    let sectionObject = deepClone(section);
    let formObject = {};
    sectionObject.fields.forEach(field => {
      let controlName = field.label.toLowerCase();
      controlName = controlName.replace(/ /g, "_");
      field['controlName'] = controlName;
      formObject[field.controlName] = new FormControl(null, field.is_required ? Validators.required : []);
      if (field.fieldDetails.type == 'whole')
        formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]{0,20}"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]{0,20}")]);
      formObject[field.controlName].updateValueAndValidity();
      if (field.fieldDetails.type == 'decimal')
        formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]+(\.[0-9]{1,6})?$"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]+(\.[0-9]{0,6})?$")]);
      formObject[field.controlName].updateValueAndValidity();
      if (field.fieldDetails.type == 'currency')
        formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]+(\.[0-9]{1,2})?$"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]+(\.[0-9]{1,2})?$")]);
      formObject[field.controlName].updateValueAndValidity();
    });
    sectionObject['sectionForm'] = this.formBuilder.group(formObject);
    // let sectionObject = [section].slice(0);
    sectionObject['is_repeated'] = true;
    // sectionObject[0].sectionForm.reset();
    sections.splice((sectionIndex + 1), 0, sectionObject);
  }

  hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  goToPreviousPage() {
    this.currentPage = this.currentPage - 1;
  }

  goToNextPage(saveDraft = true) {
    this.currentPage = this.currentPage + 1;
    if (saveDraft)
      this.saveForm(true, false);
  }

  cancel() {
    this.cancelEvent.emit();
  }

}
