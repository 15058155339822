import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddElementComponent } from '../add-element/add-element.component';
import * as $ from 'jquery';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-module-settings-content',
  templateUrl: './module-settings-content.component.html',
  styleUrls: ['./module-settings-content.component.scss'],
})
export class ModuleSettingsContentComponent implements OnInit {

  @ViewChild(AddElementComponent, { static: false }) addElementComponent: AddElementComponent;

  selectedSegment: any = "1";
  moduleStatus: any;

  modulesData: any = [];

  moduleData: any = [];
  moduleReferenceName: any;
  moduleType: any;
  moduleIsEdit: boolean = false;

  templatesList: any = [];
  isAddTemplate: any = false;
  dataSource: any;
  moduleDataSource: any;

  displayColumns: any = ['template_icon', 'name', 'prefix', 'description', 'is_inactive', 'forms', 'events', 'actions'];

  settingsForm: FormGroup;

  showAddClass: any;
  elementId: any;
  templateId: any;
  userTypes: any = [];

  moduleId: any;

  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private platform: Platform,
    private location: Location,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    public menuService: MenuService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    public commonMethodsService: CommonMethodsService,
  ) { }

  ngOnInit() {
    this.getUserTypes();
    this.initializeSettingsForm();
  }

  ionViewDidEnter() {
    this.moduleId = this.router.url.split('/')[2];
    this.templateId = null;
    //this.selectedSegment = this.route.snapshot.paramMap.get('tabId') ? this.route.snapshot.paramMap.get('tabId') : '1';
    //this.onSegmentChange(this.selectedSegment);
    this.getTemplates();
    this.getModule();
  }

  initializeSettingsForm(data = null) {
    this.settingsForm = this.formBuilder.group({
      is_inactive: new FormControl((data && data.is_inactive) ? data.is_inactive : null, []),
      moduleName: new FormControl((data && data.name) ? data.name : null, [Validators.required]),
      description: new FormControl((data && data.description) ? data.description : null, []),
      prefix: new FormControl((data && data.prefix) ? data.prefix : null, [Validators.required])
    });
  }

  getUserTypes() {
    this.apiService.get(constants.api_end_points.USER_TYPES).subscribe(response => {
      if (response.code == 200) {
        this.userTypes = response.data;
      }
    })
  }

  changeEditMode() {
    this.moduleIsEdit = !this.moduleIsEdit;
  }

  getModule(moduleId = null) {
    this.loaderService.showLoader();
    if (this.moduleId) {
      let id = this.moduleId;
      console.log(id);
      this.initializeSettingsForm();
      let requestObject = {
        id: Number(moduleId ? moduleId : id)
      };
      this.apiService.post(constants.api_end_points.GET_MODULE, requestObject).subscribe(response => {
        if (response && response.code == 200) {
          this.loaderService.hideLoader();
          this.modulesData = [];
          this.modulesData.push(response.data);

          this.moduleData = response.data;
          this.moduleReferenceName = response.data.name;
          this.moduleType = response.data.module_type.name;
          this.displayAddTemplateButton();

          this.moduleDataSource = new MatTableDataSource(this.modulesData);
          this.initializeSettingsForm(this.modulesData[0]);
          this.moduleReferenceName = response.data.name;
          console.log(this.modulesData[0]);
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar(response.message)
        }
      }, err => {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar('Something Went Wrong!');
      });
    }
  }

  getTemplates() {
    let requestObject = {
      module_id: Number(this.moduleId)
    };
    this.apiService.post(constants.api_end_points.GET_TEMPLATES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.templatesList = response.data;
        this.dataSource = new MatTableDataSource(this.templatesList);
      }
    })
  }

  updateModule() {
    let requestObject = {
      id: this.modulesData[0].id,
      name: this.settingsForm.value.moduleName,
      description: this.settingsForm.value.description,
      is_inactive: this.settingsForm.value.is_inactive
    };
    this.apiService.post(constants.api_end_points.UPDATE_MODULE, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Module Updated');
        this.getModule(this.modulesData[0].id);
        this.moduleIsEdit = false;
      }
      else if (response.code == 401) {
        this.apiService.errorSnackBar(response.message);
      }
      this.apiService.errorSnackBar(response.message);
    })
  }

  updateView() {
    this.getTemplates();
  }

  // onSegmentChange(event) {
  //   switch (this.selectedSegment) {
  //     case '1': this.getTemplates(); this.getModuleName(); this.getModule(); break;
  //     case '2':  break;
  //   }
  // }

  // navigateToTabs() {
  //   this.onSegmentChange(this.selectedSegment);
  //   this.router.navigate(['module-settings/' + this.route.snapshot.paramMap.get('moduleId') + '/' + this.selectedSegment]);
  // }

  displayAddSection(id = null) {
    if (this.showAddClass = true) { this.templateId == null }

    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb')) {
      if (id) {
        let routeData: NavigationExtras = {
          state: {
            userTypes: this.userTypes,
            elementId: this.moduleId,
            templateId: id,
          }
        }
        this.router.navigate(['add/' + 4], routeData);
      } else {
        let routeData: NavigationExtras = {
          state: {
            userTypes: this.userTypes,
            elementId: this.moduleId,
          }
        }
        this.router.navigate(['add/' + 4], routeData);
      }
    } else {
      this.showAddClass = true;
      this.setHeightForAddSection();
      this.elementId = this.moduleId;
      if (id) {
        this.templateId = id
      }
      if (this.addElementComponent) {
        if (id) {
          this.addElementComponent.templateId = id;
          this.addElementComponent.getElementData();
        } else {
          this.addElementComponent.templateId = null;
          this.addElementComponent.ngOnInit();
        }
      }
    }
  }

  setHeightForAddSection() {
    let addClassHeight = $(".module-settings-content-class")[0].clientHeight
    return {
      height: addClassHeight + 'px'
    }
  }

  setHeightForSegmentContent() {
    let addClassHeight = $('.module-settings-content-class')[0].clientHeight;
    if (addClassHeight > 0) {
      return {
        height: addClassHeight + 'px',
        'max-height': addClassHeight + 'px',
        overflow: 'auto'
      };
    }
  }

  displayAddTemplateButton() {
    if (this.moduleType != 'Incidents' && this.moduleType != 'Hazards' && this.moduleType != 'Actions') {
      this.isAddTemplate = true;
    }
  }

  goToTemplateSettings(template) {
    this.router.navigate(['template-settings/' + this.moduleId + '/' + template.id]);
  }

  // editTemplate(element) {
  //     this.router.navigate(['template-settings/' + this.moduleId + '/' + element.id + '/2']);
  // }

  goToFormCompletion(item) {
    this.router.navigate(['view-form/' + item.forms[0].id]);
  }

  goBack() {
    this.location.back();
    // this.router.navigate(['account-settings/5']);
    // let currentState = this.location.getState();
    // if (currentState['navigationId'] && currentState['navigationId'] > 1) {
    //   this.location.back();
    // } else {
    // }
  }

  ionViewDidLeave() {
    this.showAddClass = false;
  }

}
