import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MenuService } from 'src/app/services/menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as $ from 'jquery';


declare var google;

@Component({
  selector: 'app-add-element',
  templateUrl: './add-element.component.html',
  styleUrls: ['./add-element.component.scss'],
})

export class AddElementComponent implements OnInit {
  @Input() data: any;
  @Input() type: any;
  @Input() title: any;
  @Input() elementId: any;
  @Input() templateId: any;
  @Input() options: any = [];
  @Output() updateView: any = new EventEmitter<any>();
  @Output() appendList: any = new EventEmitter<any>();
  @Output() cancelEvent: any = new EventEmitter<any>();
  @Output() openAddActionSection: any = new EventEmitter<any>();

  @Input() userTypes: any = [];
  @Input() countries: any = [];
  @Input() timeZones: any = [];

  elementData: any;
  filteredCountries: Observable<any[]>;

  profileForm: FormGroup;
  businessUnitForm: FormGroup;
  templateForm: FormGroup;
  moduleForm: FormGroup;
  siteForm: FormGroup;
  listForm: FormGroup;
  // actionForm: FormGroup;

  editSite: any;
  editAction: any;
  editBusinessUnit: any;
  editList: any;

  listItems: any = [{
    label: '',
    value: ''
  }];

  colourData: any = [
    { name: "Red", value: "Red", rgbValue: "#F44336" },
    { name: "Pink", value: "Pink", rgbValue: "#E91E63" },
    { name: "Purple", value: "Purple", rgbValue: "#9C27B0" },
    { name: "Deep Purple", value: "DeepPurple", rgbValue: "#673AB7" },
    { name: "Indigo", value: "Indigo", rgbValue: "#5C6BC0" },
    { name: "Blue", value: "Blue", rgbValue: "#42A5F5" },
    { name: "Light Blue", value: "LightBlue", rgbValue: "#03A9F4" },
    { name: "Cyan", value: "Cyan", rgbValue: "#00BCD4" },
    { name: "Teal", value: "Teal", rgbValue: "#009688" },
    { name: "Green", value: "Green", rgbValue: "#4CAF50" },
    { name: "Light Green", value: "LightGreen", rgbValue: "#8BC34A" },
    { name: "Lime", value: "Lime", rgbValue: "#CDDC39" },
    { name: "Yellow", value: "Yellow", rgbValue: "#FFEB3B" },
    { name: "Amber", value: "Amber", rgbValue: "#FFC107" },
    { name: "Orange", value: "Orange", rgbValue: "#FF9800" },
    { name: "Deep Orange", value: "DeepOrange", rgbValue: "#FF5722" },
    { name: "Brown", value: "Brown", rgbValue: "#795548" },
    { name: "Gray", value: "Gray", rgbValue: "#9E9E9E" },
    { name: "Blue Gray", value: "BlueGray", rgbValue: "#607D8B" },
  ];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private location: Location,
    private apiService: ApiService,
    private menuService: MenuService,
    private loaderService: LoaderService,
    private _snackBar: MatSnackBar) { }

  drop(event: CdkDragDrop<string[]>, list) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }


  ngOnInit() {
    if (this.route.snapshot.paramMap.get('type')) {
      this.type = this.route.snapshot.paramMap.get('type');
      this.route.queryParams.subscribe(data => {
        if (this.router.getCurrentNavigation().extras.state) {
          let routeData = this.router.getCurrentNavigation().extras.state;
          if (this.type == 1) {
            this.userTypes = routeData.userTypes;
            // this.timeZones = routeData.timeZones;
            this.countries = routeData.countries;
          } else if (this.type == 2) {
            this.options = routeData.options;
            this.elementId = routeData.elementId
          } else if (this.type == 3) {
            this.options = routeData.options;
            this.elementId = routeData.elementId
            // this.bindAutoCompleteFunction();
          } else if (this.type == 4) {
            this.options = routeData.options;
            this.userTypes = routeData.userTypes;
            this.elementId = routeData.elementId;
            this.templateId = routeData.templateId;
          } else if (this.type == 5) {
            this.options = routeData.options;
            this.elementId = routeData.elementId;
          } else if (this.type == 6) {
            this.options = routeData.options;
            this.elementId = routeData.elementId
            // this.bindAutoCompleteFunction();
          }
        }
      })
    }

    this.initializeAddSiteForm();
    this.initializeProfileForm();
    this.initializeAddTemplateForm();
    this.initializeAddModuleForm();
    this.initializeAddBusinessUnitForm();
    this.initializeAddListForm();

    switch (this.type) {
      case '1': this.title = 'Add User'; this.initializeProfileForm(); break;
      case '2': {
        this.title = 'Add Business Unit';
        this.initializeAddBusinessUnitForm();
        break;
      }
      case '3': this.title = 'Add Site'; this.initializeAddSiteForm(); this.bindAutoCompleteFunction(); break;
      case '4': this.title = 'Add Template'; this.initializeAddTemplateForm(); break;
      case '5': this.title = 'Add Module'; this.initializeAddModuleForm(); break;
      case '6':
        this.title = "Add List";
        this.initializeAddListForm();
        this.listItems =
          [{
            label: '',
            value: ''
          }];
        break;
    }
    console.log(this.type, this.profileForm, this.businessUnitForm, this.siteForm, this.moduleForm, this.templateForm, this.listForm);

    if (this.elementId)
      this.getElementData();
  }

  ionViewDidEnter() {

  }

  setHeightForTextarea() {
    let x = document.getElementsByClassName('matTextarea');
    for(let i = 0; i < x.length; i++) {
      let id = x[i].id;
      $('#' + id).css('height', x[i]['scrollHeight'] + 'px');
    }
  }

  onFocus(event, index) {
    if (!event.target.value && index == (this.listItems.length - 1) || (this.listItems[this.listItems.length - 1].value != "")) {
      this.addOption();
    }
  }

  addOption() {
    this.listItems.push({
      label: '',
      value: ''
    });
  }

  removeField(section, index) {
    section.formFields.splice(index, 1);
  }

  removeOption(list, index) {
    list.splice(index, 1);
  }

  initializeProfileForm() {
    this.profileForm = this.formBuilder.group({
      fullName: new FormControl(null, [Validators.maxLength(80), Validators.required]),
      preferredName: new FormControl(null, [Validators.maxLength(30), Validators.required]),
      emailId: new FormControl(null, [Validators.required]),
      //confirmEmailId: new FormControl(null, [Validators.required]),
      //mobile: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{10}$")]),
      userType: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      timezone: new FormControl(null, [Validators.required]),
      // password: [null, Validators.compose([Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$")])],
      // confirmPassword: new FormControl(null, [Validators.required])
    });
    let countryControl = this.profileForm.get('country');
    countryControl.reset();
    this.filteredCountries = countryControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.country),
        map(name => name ? this.filterCountries(name) : this.countries.slice())
      );
  }

  initializeAddBusinessUnitForm(data = null, disable = false) {
    this.businessUnitForm = this.formBuilder.group({
      unitName: new FormControl({ value: (data && data.name) ? data.name : null, disabled: disable }, [Validators.maxLength(40), Validators.required]),
      parentUnit: new FormControl({ value: (data && data.parent_id) ? data.parent_id : 'none', disabled: disable }, [Validators.required]),
      is_inactive: new FormControl({ value: (data && data.is_inactive) ? data.is_inactive : false, disabled: disable }, [])
    });
  }

  initializeAddSiteForm(data = null, disable = false) {
    this.siteForm = this.formBuilder.group({
      siteName: new FormControl({ value: (data && data.name) ? data.name : null, disabled: disable }, [Validators.maxLength(40), Validators.required]),
      parentSite: new FormControl({ value: (data && data.parent_id) ? data.parent_id : 'none', disabled: disable }, [Validators.required]),
      mobile: new FormControl({ value: (data && data.phone_work) ? data.phone_work : null, disabled: disable }, [Validators.pattern("^[0-9]{10}$")]),
      address: new FormControl({ value: (data && data.address) ? data.address : null, disabled: disable }, []),
      city: new FormControl({ value: (data && data.city) ? data.city : 'City', disabled: true }, [Validators.required],),
      state: new FormControl({ value: (data && data.state) ? data.state : 'State', disabled: true }, [Validators.required]),
      postCode: new FormControl({ value: (data && data.postal_code) ? data.postal_code : 'Post Code', disabled: true }, [Validators.required]),
      country: new FormControl({ value: (data && data.country) ? data.country : 'Country', disabled: true }, [Validators.required]),
      longitude: new FormControl((data && data.longitude) ? data.longitude : null, []),
      latitude: new FormControl((data && data.latitude) ? data.latitude : null, []),
      is_inactive: new FormControl({ value: (data && data.is_inactive) ? data.is_inactive : false, disabled: disable }, [])
    });
  }

  initializeAddTemplateForm(data = null) {
    this.templateForm = this.formBuilder.group({
      prefix: new FormControl({ value: (data && data.prefix) ? data.prefix : null, disabled: false }, [Validators.maxLength(10), Validators.required]),
      is_inactive: new FormControl({ value: (data && data.is_inactive) ? data.is_inactive : false, disabled: false }, []),
      templateName: new FormControl({ value: (data && data.name) ? data.name : null, disabled: false }, [Validators.maxLength(50), Validators.required]),
      description: new FormControl({ value: (data && data.description) ? data.description : null, disabled: false }, [Validators.maxLength(500)]),
      // userType: new FormControl({ value: (data && data.user_modify_type_id) ? data.user_modify_type_id : null, disabled: false }, [Validators.required]),
      access_to_team_lead: new FormControl((data && data.access_to_team_lead) ? data.access_to_team_lead : false, [Validators.required])
    });
  }

  initializeAddModuleForm(data = null) {
    this.moduleForm = this.formBuilder.group({
      moduleName: new FormControl((data && data.name) ? data.name : null, [Validators.required]),
      description: new FormControl((data && data.description) ? data.description : null, []),
      is_inactive: new FormControl((data && data.is_inactive) ? data.is_inactive : false, []),
    });
  }

  initializeAddListForm(data = null) {
    this.listForm = this.formBuilder.group({
      name: new FormControl((data && data.name) ? data.name : null, [Validators.maxLength(40), Validators.required]),
      description: new FormControl((data && data.description) ? data.description : null, [Validators.maxLength(255)]),
      is_scored: new FormControl((data && data.is_scored) ? data.is_scored : false, [])
    });
  }

  getElementData() {
    this.loaderService.showLoader();
    let api_url;
    let requestObject
    // let api_url = this.type == 2 ? constants.api_end_points.GET_BUSINESS_UNIT : constants.api_end_points.GET_SITE;

    switch (this.type) {
      case "2":
        requestObject = {
          id: this.elementId,
        };
        api_url = constants.api_end_points.GET_BUSINESS_UNIT;
        break;
      case "3":
        requestObject = {
          id: this.elementId,
        };
        api_url = constants.api_end_points.GET_SITE;
        break;
      case "4":
        requestObject = {
          template_id: Number(this.templateId),
          module_id: Number(this.elementId),
        };
        api_url = constants.api_end_points.GET_TEMPLATE;
        break;
      case "5":
        requestObject = {
          id: Number(this.elementId),
        };
        api_url = constants.api_end_points.GET_MODULE;
        break;
      case "6":
        requestObject = {
          id: this.elementId,
        };
        api_url = constants.api_end_points.GET_LIST;
        break;
    }

    this.apiService.post(api_url, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.elementData = response.data;
        if (this.type == 2) {
          this.editBusinessUnit = false;
          this.initializeAddBusinessUnitForm(this.elementData, true);
        } else if (this.type == 3) {
          this.editSite = false;
          this.initializeAddSiteForm(this.elementData, true);
        } else if (this.type == 4) {
          this.initializeAddTemplateForm(this.elementData);
        } else if (this.type == 5) {
          this.initializeAddModuleForm(this.elementData);
        } else if (this.type == 6) {
          this.editList = false;
          this.initializeAddListForm(this.elementData);
          this.listItems = response.data.list_values;
        }

        setTimeout(() => {
          this.setHeightForTextarea();
        }, 500);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    });
  }

  getTimeZones(id = null) {
    let requestObject = {
      "countryId": id ? id : 1
    };

    this.apiService.post(constants.api_end_points.TIME_ZONES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.timeZones = response.data;
        // this.registrationForm.patchValue({timezone: ''});
        let timezoneControl = this.profileForm.get('timezone');
        timezoneControl.reset();
      }
    });
  }

  displayFn(value) {
    // console.log(event);
    return value && value.country ? value.country : '';
  }

  getStylesForAddContainer() {
    //if (this.type == 4)
    return {
      'padding-bottom': '54px',
      'position': 'relative'
    };
  }

  //getStylesForColourTextbox(colourValue) {
  //  if(colourValue && this.type == 6) {
  //  this.filteredColour = this.colourData.filter(colour => colour.value == colourValue);    
  //  this.selectedColour = this.filteredColour[0].rgbValue  
  //  console.log("this.selectedColour");
  //  console.log(this.selectedColour);
  //  console.log("colourValue");
  //  console.log(colourValue);
  //  return {
  //    'background-color': this.selectedColour
  //  }
  //  }
  //}

  optionChange(event) {
    event.option.value ? this.getTimeZones(event.option.value.id) : '';
  }

  editBusinessUnitForm() {
    this.editBusinessUnit = true;
    this.initializeAddBusinessUnitForm(this.elementData, false);
  }

  editSiteForm() {
    this.editSite = true;
    this.initializeAddSiteForm(this.elementData, false);
  }

  editActionForm() {
    this.editAction = true;
  }

  editListForm() {
    this.editList = true;
    this.initializeAddListForm(this.elementData);
  }

  editTemplateForm() {
    this.initializeAddTemplateForm(this.elementData);
  }

  editModuleForm() {
    this.initializeAddTemplateForm(this.elementData);
  }

  cancel() {
    this.elementId = null;
    this.editSite = false;
    this.editAction = false;
    this.editBusinessUnit = false;
    this.editList = false;
    this.siteForm.reset();
    // this.actionForm.reset();
    this.profileForm.reset();
    this.templateForm.reset();
    this.moduleForm.reset();
    this.businessUnitForm.reset();
    this.listForm.reset();
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      this.location.back();
      // this.router.navigate(['account-settings']);
    } else {
      this.cancelEvent.emit(true);
    }
  }

  addUser() {
    if (this.profileForm.valid) {
      this.loaderService.showLoader();
      let requestObject = {
        full_name: this.profileForm.value.fullName,
        preferred_name: this.profileForm.value.preferredName,
        email: this.profileForm.value.emailId,
        //mobile_number: this.profileForm.value.mobile,
        timezone_id: this.profileForm.value.timezone,
        // "password": this.profileForm.value.password,
        type_id: this.profileForm.value.userType,
      };
      this.apiService
        .post(constants.api_end_points.ADD_USER, requestObject)
        .subscribe((response) => {
          if (response && response.code == 200) {
            this.loaderService.hideLoader();
            this.apiService.successSnackBar("User Added");
            this.profileForm.reset();
            if (
              (this.platform.is("pwa") && !this.platform.is("desktop")) ||
              this.platform.is("mobileweb")
            ) {
              this.router.navigate(["account-settings"]);
            } else {
              this.updateView.emit(true);
            }
          } else {
            this.loaderService.hideLoader();
            this.apiService.errorSnackBar(response.message);
          }
        }, err => {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar('Something Went Wrong!');
        });
    } else {
      this.validateAllFormFields(this.profileForm);
    }
  }

  addBusinessUnit() {
    if (this.businessUnitForm.valid) {
      let requestObject = {
        name: this.businessUnitForm.value.unitName,
        parent_id: this.businessUnitForm.value.parentUnit,
        is_inactive: this.businessUnitForm.value.is_inactive
      };
      if (this.elementId)
        requestObject['id'] = this.elementId;
      let api_url = this.elementId ? constants.api_end_points.UPDATE_BUSINESS_UNIT : constants.api_end_points.ADD_BUSINESS_UNIT;
      this.apiService.post(api_url, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.apiService.successSnackBar('Business Unit ' + (this.elementId ? 'Updated' : 'Added'));
          if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
            this.router.navigate(['account-settings']);
          } else {
            this.updateView.emit(true);
            if (!this.elementId)
              this.businessUnitForm.reset();
          }
        }
      })
    } else {
      this.validateAllFormFields(this.businessUnitForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  /* validation template
  onSubmit() {
  if (this.form.valid) {
    console.log('form submitted');
  } else {
    this.validateAllFormFields(this.form);
  }
  }*/


  bindAutoCompleteFunction() {

    console.log('called');
    var options = {
      componentRestrictions: {
        country: 'au'
      }
    };

    setTimeout(() => {

      var input = document.getElementById('id_address');
      let autocomplete = new google.maps.places.Autocomplete(input, options);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        var place = autocomplete.getPlace();
        console.log(place, place.address_components);

        let city, state, country, postal_code;
        for (var i = 0; i < place.address_components.length; i++) {
          for (var j = 0; j < place.address_components[i].types.length; j++) {
            // console.log(place.address_components[i].types);
            if (place.address_components[i].types[j] == "administrative_area_level_2") {
              city = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == "administrative_area_level_1") {
              state = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == "country") {
              country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == "postal_code") {
              postal_code = place.address_components[i].long_name;
            }
          }
        }
        this.siteForm.patchValue({ address: place.formatted_address, city, state, country, postCode: postal_code, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() });
        this.siteForm.controls.city.markAllAsTouched();
        // this.siteForm.updateValueAndValidity();
        console.log(place.formatted_address, city, state, country, postal_code, place.geometry.location.lat(), place.geometry.location.lng());

      });
    }, 100);
  }

  filterCountries(value) {
    const filterValue = value ? value.toLowerCase() : '';
    return this.countries.filter(option => option.country.toLowerCase().startsWith(filterValue));
  }

  addSite() {
    if (this.siteForm.valid) {
      let requestObject = {
        name: this.siteForm.controls.siteName.value,
        address: this.siteForm.controls.address.value,
        parent_id: this.siteForm.controls.parentSite.value,
        city: this.siteForm.controls.city.value,
        state: this.siteForm.controls.state.value,
        country: this.siteForm.controls.country.value,
        postal_code: this.siteForm.controls.postCode.value,
        latitude: this.siteForm.controls.latitude.value,
        longitude: this.siteForm.controls.longitude.value,
        phone_work: this.siteForm.controls.mobile.value,
        is_inactive: this.siteForm.value.is_inactive
      };

      if (this.elementId)
        requestObject['id'] = this.elementId;

      let api_url = this.elementId ? constants.api_end_points.UPDATE_SITE : constants.api_end_points.ADD_SITE;

      this.apiService.post(api_url, requestObject).subscribe(response => {
        if (response.code == 200) {
          //this.editSite = false;
          this.apiService.successSnackBar('Site ' + (this.elementId ? 'Updated' : 'Added'));
          if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
            this.router.navigate(['account-settings']);
          } else {
            this.updateView.emit(true);
            if (!this.elementId)
              this.siteForm.reset();
          }
        }
        else {
          this.apiService.errorSnackBar(response.message);
        }
      })
    } else {
      this.validateAllFormFields(this.siteForm);
    }
  }

  addTemplate() {
    this.loaderService.showLoader();
    if (this.templateForm.valid) {
      let requestObject = {
        template_id: this.templateId,
        module_id: Number(this.elementId),
        prefix: this.templateForm.value.prefix,
        name: this.templateForm.value.templateName,
        description: this.templateForm.value.description,
        type_id: this.templateForm.value.userType,
        is_inactive: this.templateForm.value.is_inactive,
        access_to_team_lead: this.templateForm.value.access_to_team_lead
      };
      let api_url = this.templateId ? constants.api_end_points.UPDATE_TEMPLATE : constants.api_end_points.ADD_TEMPLATE;
      this.apiService.post(api_url, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.loaderService.hideLoader();
          this.apiService.successSnackBar('Template ' + (this.templateId ? 'Updated' : 'Added'));
          this.templateForm.reset();
          this.menuService.getActiveModules();
          if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
            this.router.navigate(['module-settings/' + this.elementId]);
          } else {
            this.updateView.emit(true);
          }
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar(response.message);
        }
      }, err => {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar('Something Went Wrong');
      })
    } else {
      this.validateAllFormFields(this.templateForm);
      this.loaderService.hideLoader();
    }
  }

  addModule() {
    if (this.moduleForm.valid) {
      console.log(this.moduleForm);
      let requestObject = {
        id: this.elementId,
        name: this.moduleForm.value.moduleName,
        description: this.moduleForm.value.description,
        is_inactive: this.moduleForm.value.is_inactive
      };
      // let api_url = this.elementId ? constants.api_end_points.UPDATE_MODULE : constants.api_end_points.ADD_MODULE;
      // this.apiService.put(api_url, requestObject).subscribe(response => {
      this.apiService.post(constants.api_end_points.UPDATE_MODULE, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.apiService.successSnackBar('Module ' + (this.elementId ? 'Updated' : 'Added'));
          this.moduleForm.reset();
          if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
            this.router.navigate(['account-settings/' + 5]);
          } else {
            this.updateView.emit(true);
          }
        } else {
          this.apiService.errorSnackBar(response.message);
        }
      })
    } else {
      this.validateAllFormFields(this.moduleForm);
    }
  }

  addList() {
    this.loaderService.showLoader();
    if (this.listForm.valid) {
      let listObjects = [];
      this.listItems.forEach((element, i) => {
        let listObject = {};
        if (element.value) {
          listObject['value'] = element.value;
          listObject['position'] = (i + 1);
          listObject['is_inactive'] = false;
          listObject['score'] = element.score;
          listObject['colour_rgb'] = element.colour_rgb;
          listObjects.push(listObject);
        }
      });

      let requestObject = {
        name: this.listForm.value.name,
        description: this.listForm.value.description,
        label: this.listForm.value.name,
        is_scored: this.listForm.value.is_scored,
        listValues: listObjects,
        is_account_list: true
      };

      if (this.elementId)
        requestObject['id'] = this.elementId;

      let url = this.elementId ? constants.api_end_points.UPDATE_LIST : constants.api_end_points.ADD_LIST;
      this.apiService.post(url, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.loaderService.hideLoader();
          this.apiService.successSnackBar('List Added!');
          this.updateView.emit(true);
          this.appendList.emit(
            {
              id: response.data[0].id,
              listName: this.listForm.value.name,
              listValues: response.data[0].list_values
            }
          );
          this.listForm.reset();
          if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
            this.router.navigate(['account-settings/' + this.elementId]);
          } else {
            // this.
          }
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar(response.message)
        }
      }, err => {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar('Something Went Wrong!');
      })
    } else {
      this.validateAllFormFields(this.listForm);
    }
  }

  goBack() {
    let currentState = this.location.getState();
    if (currentState['navigationId'] && currentState['navigationId'] > 1) {
      this.location.back();
    } else {
      if (this.type == 4) {
        this.router.navigate(['module-settings/1']);
      } else {
        this.router.navigate(['account-settings/', this.type]);
      }
    }
  }

  ionViewDidLeave() {
    this.elementId = null;
    this.templateId = null;
    this.editSite = false;
    this.editAction = false;
    this.editBusinessUnit = false;
    this.editList = false;
    this.siteForm.reset();
    // this.actionForm.reset();
    this.profileForm.reset();
    this.templateForm.reset();
    this.moduleForm.reset();
    this.businessUnitForm.reset();
    this.listForm.reset();

  }

  displayAddSection() {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      let routeData: NavigationExtras = {
        state: {}
      }
      // this.router.navigate(['actions/add/' + this.selectedSegment], routeData);
      this.router.navigate(['actions/add/4/add/4'], routeData);
    } else {
      this.openAddActionSection.emit();
    }
  }

}
