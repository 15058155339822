import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { constants } from '../providers/constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {

  segmentId: any = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private apiService: ApiService,
    private menuCtrl: MenuController) { }

  logout() {
    if (localStorage.getItem('rememberMe') == "true") {
      let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      let username = localStorage.getItem('username')
      localStorage.clear();
      localStorage.setItem('username', username);
      localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
    } else {
      localStorage.clear();
    }
    // localStorage.removeItem('SS-user');
    // localStorage.removeItem('account');
    // this.router.navigate(['login']);
    this.navCtrl.navigateRoot(['logout']);
    this.apiService.get(constants.api_end_points.LOGOUT).subscribe(response => {
      if (response.code == 200) {
        this.menuCtrl.close();
      }
    })
  }

  setSegmentId(id) {
    this.segmentId.next(id);
  }

  nameToInitials(fullName) {
    if (fullName) {
      const namesArray = fullName.trim().split(' ');
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
    }
  }
}
