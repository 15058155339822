import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';

import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() columns: any = [];
  @Input() tableData: any = [];
  @Input() moduleId: any;
  @Output() eventView: any = new EventEmitter<any>();
  events: any = [];
  columnsArray: any = [
    {
      field: "id",
      title: "ID",
      position: 1
    },
    {
      field: "full_name",
      title: "Name",
      position: 2
    },
    {
      field: "job_title",
      title: "Job Title",
      position: 3
    },
    {
      field: "country",
      title: "Country",
      position: 1
    },
    {
      field: "is_online",
      title: "Status",
      position: 1
    },
    {
      field: "phone",
      title: "Contact",
      position: 1
    },
   ];

  gridView: any = [
    { "id": "848e6002-8a92-447d-951b-1ffd5e695578", "full_name": "Sig Jeannel", "job_title": "Human Resources Assistant III", "country": "US", "is_online": true, "rating": 3, "target": 100, "budget": 47601, "phone": "(936) 9429601", "address": "138 Buhler Avenue", "img_id": 1, "gender": "M" },
    { "id": "19d18d40-0e64-4837-9420-92130a0ed253", "full_name": "Shelden Greyes", "job_title": "Operator", "country": "GB", "is_online": true, "rating": 5, "target": 40, "budget": 12253, "phone": "(343) 6656271", "address": "2 Waxwing Point", "img_id": 2, "gender": "M" },
    { "id": "bebdc6eb-9179-484a-917d-2e16a23bfdfe", "full_name": "Megen Cody", "job_title": "Operator", "country": "BR", "is_online": true, "rating": 1, "target": 66, "budget": 96183, "phone": "(178) 2336256", "address": "4082 Stephen Court", "img_id": 6, "gender": "F" },
    { "id": "38b08b88-e482-46fc-8976-83590c02ec23", "full_name": "Clevey Thursfield", "job_title": "VP Quality Control", "country": "BR", "is_online": true, "rating": 2, "target": 58, "budget": 54936, "phone": "(277) 7415010", "address": "1563 Glacier Hill Parkway", "img_id": 5, "gender": "M" },
    { "id": "2aac53f8-b72d-4629-9082-6d8239a8fecf", "full_name": "Ruthi Baldini", "job_title": "Data Coordiator", "country": "BR", "is_online": true, "rating": 3, "target": 37, "budget": 46572, "phone": "(766) 5691615", "address": "6 Laurel Avenue", "img_id": 8, "gender": "F" },
    { "id": "1aa789e5-de01-406e-a2ee-cc5ce20f7e34", "full_name": "Annecorinne Morter", "job_title": "Professor", "country": "FR", "is_online": false, "rating": 2, "target": 35, "budget": 37198, "phone": "(807) 2524830", "address": "106 Green Street", "img_id": 3, "gender": "F" },
    { "id": "d2ff1b02-3808-44aa-9056-3b5df34bf865", "full_name": "Gracia Punyer", "job_title": "Assistant Manager", "country": "ES", "is_online": true, "rating": 4, "target": 64, "budget": 84752, "phone": "(515) 9749536", "address": "69 Brentwood Alley", "img_id": 2, "gender": "F" },
    { "id": "26b2b760-27e8-47a6-81c2-07870d1b2b30", "full_name": "Duky Hurring", "job_title": "Account Executive", "country": "BR", "is_online": false, "rating": 3, "target": 61, "budget": -1266, "phone": "(897) 7202034", "address": "39 Morning Circle", "img_id": 3, "gender": "M" },
    { "id": "91c6b652-4206-4a0c-bac6-dc21283a72d7", "full_name": "Briana Shemelt", "job_title": "Professor", "country": "US", "is_online": false, "rating": 3, "target": 63, "budget": -9308, "phone": "(205) 2560799", "address": "11 Walton Court", "img_id": 2, "gender": "F" },
    { "id": "1e8289dc-2ef3-4045-ad6b-786d00368427", "full_name": "Lexis Mostin", "job_title": "Analyst Programmer", "country": "FR", "is_online": true, "rating": 4, "target": 81, "budget": 38153, "phone": "(903) 8388089", "address": "38547 Westend Way", "img_id": 4, "gender": "F" },
    { "id": "797387bd-c247-48b3-97b6-5e75791f8809", "full_name": "Felizio Gooda", "job_title": "GIS Technical Architect", "country": "DE", "is_online": true, "rating": 3, "target": 89, "budget": 81585, "phone": "(372) 2389397", "address": "9 Summer Ridge Circle", "img_id": 2, "gender": "M" },
    { "id": "24c541b0-4978-4072-84d0-abe94fcd0756", "full_name": "Aubry Oxberry", "job_title": "Financial Advisor", "country": "BR", "is_online": false, "rating": 2, "target": 3, "budget": -6095, "phone": "(665) 4176083", "address": "06 Lerdahl Point", "img_id": 10, "gender": "F" },
    { "id": "d3416440-7411-42cc-a913-7dd319ca8311", "full_name": "Orly Glasbey", "job_title": "Environmental Tech", "country": "BR", "is_online": true, "rating": 5, "target": 63, "budget": 63945, "phone": "(449) 8482879", "address": "4035 Porter Parkway", "img_id": 6, "gender": "F" },
    { "id": "139066b5-60c5-4cf5-9afe-fb4e5558b087", "full_name": "Stephanus Culp", "job_title": "Cost Accountant", "country": "BR", "is_online": false, "rating": 2, "target": 60, "budget": 10613, "phone": "(148) 3124030", "address": "57028 Moland Terrace", "img_id": 8, "gender": "M" },
    { "id": "eb844971-b97d-4f79-bd5a-a266fcfaaf70", "full_name": "Roseanna Janecek", "job_title": "Database Administrator IV", "country": "FR", "is_online": false, "rating": 4, "target": 97, "budget": 77351, "phone": "(125) 4421623", "address": "21973 Beilfuss Alley", "img_id": 4, "gender": "F" },
    { "id": "5cb391fe-4855-445c-a8b8-617c04d1d999", "full_name": "Weidar McCombe", "job_title": "Civil Engineer", "country": "FR", "is_online": true, "rating": 1, "target": 77, "budget": 35924, "phone": "(488) 7911627", "address": "7 Dahle Terrace", "img_id": 1, "gender": "M" },
    { "id": "4ba9ad7e-d8b7-40e7-b8cd-67a8e743a249", "full_name": "Evelin Spirritt", "job_title": "Analyst Programmer", "country": "BR", "is_online": false, "rating": 2, "target": 18, "budget": 58552, "phone": "(821) 9538078", "address": "89418 Knutson Pass", "img_id": 1, "gender": "M" },
    { "id": "e4a31407-39d1-4ab7-aad1-4e026a8c42fa", "full_name": "Andria Helbeck", "job_title": "Nurse Practicioner", "country": "BR", "is_online": true, "rating": 4, "target": 53, "budget": 72526, "phone": "(387) 9614638", "address": "8589 Vernon Drive", "img_id": 8, "gender": "F" },
    { "id": "cd050499-dbf1-4a43-86ab-54feaacef401", "full_name": "Mariellen Ravelus", "job_title": "Systems Administrator I", "country": "DE", "is_online": true, "rating": 2, "target": 22, "budget": -6659, "phone": "(300) 6741661", "address": "707 Gale Hill", "img_id": 9, "gender": "F" },
    { "id": "fda1c419-c0b8-4278-94b0-2ebef6bda164", "full_name": "Corri Pergens", "job_title": "Help Desk Operator", "country": "BR", "is_online": true, "rating": 2, "target": 74, "budget": 12376, "phone": "(769) 7145603", "address": "856 Forest Crossing", "img_id": 3, "gender": "F" },
    { "id": "4a962db9-136e-4fcc-a683-2f961e27fb6e", "full_name": "Friedrick Macknish", "job_title": "Human Resources Assistant II", "country": "FR", "is_online": true, "rating": 2, "target": 38, "budget": 97848, "phone": "(196) 4156385", "address": "157 Talisman Trail", "img_id": 9, "gender": "M" },
    { "id": "fa47e456-83c4-4fdc-b57b-07effb279967", "full_name": "Georgette Trevorrow", "job_title": "VP Accounting", "country": "FR", "is_online": true, "rating": 3, "target": 3, "budget": 17327, "phone": "(319) 1412549", "address": "07 Marquette Point", "img_id": 3, "gender": "F" },
    { "id": "67656444-fbcd-4813-84e7-1bdcd82f803e", "full_name": "Vanya Goalby", "job_title": "Senior Cost Accountant", "country": "FR", "is_online": false, "rating": 2, "target": 77, "budget": 33584, "phone": "(496) 7538982", "address": "2192 Iowa Lane", "img_id": 6, "gender": "F" },
    { "id": "05c098c2-9617-4e66-928a-e29b97297138", "full_name": "Abel Ansell", "job_title": "Actuary", "country": "US", "is_online": false, "rating": 4, "target": 52, "budget": 12312, "phone": "(571) 9908377", "address": "4355 Iowa Parkway", "img_id": 4, "gender": "M" },
    { "id": "3b263e99-51bf-4461-bb20-d4303d326cd9", "full_name": "Odille Barus", "job_title": "Speech Pathologist", "country": "FR", "is_online": true, "rating": 4, "target": 60, "budget": 46911, "phone": "(974) 1137672", "address": "496 Lotheville Avenue", "img_id": 8, "gender": "F" },
    { "id": "c09ddfaf-569e-4a75-8e53-1be27cf25927", "full_name": "Rudolf Consadine", "job_title": "Structural Analysis Engineer", "country": "FR", "is_online": true, "rating": 4, "target": 20, "budget": 94258, "phone": "(450) 7612220", "address": "93 Thierer Park", "img_id": 6, "gender": "M" },
    { "id": "a48abbc9-6143-4f77-a720-0b76ad862a9a", "full_name": "Christabel Bick", "job_title": "Engineer III", "country": "FR", "is_online": true, "rating": 5, "target": 0, "budget": 65359, "phone": "(361) 5159566", "address": "191 Bunting Pass", "img_id": 5, "gender": "F" },
    { "id": "d8816aee-4230-4392-a045-0a9c297451f7", "full_name": "Lancelot Tanzer", "job_title": "Senior Developer", "country": "US", "is_online": true, "rating": 5, "target": 80, "budget": 13246, "phone": "(502) 3949900", "address": "4287 Corben Plaza", "img_id": 8, "gender": "M" },
    { "id": "9f5af32c-fb0d-4449-a658-f2c7f182b63a", "full_name": "Bink Byk", "job_title": "Software Engineer I", "country": "FR", "is_online": false, "rating": 3, "target": 17, "budget": 56472, "phone": "(475) 9070061", "address": "075 Eggendart Avenue", "img_id": 9, "gender": "M" },
    { "id": "dbd7f48a-6540-4560-bb7c-cd60456b55b8", "full_name": "Rhys Pheazey", "job_title": "Speech Pathologist", "country": "BR", "is_online": false, "rating": 5, "target": 98, "budget": 62483, "phone": "(365) 8904529", "address": "987 Carioca Lane", "img_id": 9, "gender": "M" },
    { "id": "844189fe-d36e-4e06-9761-932259701fc8", "full_name": "Lyndell Howieson", "job_title": "Speech Pathologist", "country": "DE", "is_online": true, "rating": 2, "target": 82, "budget": 16694, "phone": "(461) 3130038", "address": "9 Pennsylvania Crossing", "img_id": 8, "gender": "F" },
    { "id": "5544d9d5-40ea-4e5a-8fb8-f5016b313b8f", "full_name": "Cassey Fitchell", "job_title": "Software Engineer III", "country": "FR", "is_online": true, "rating": 2, "target": 10, "budget": 91253, "phone": "(985) 5060547", "address": "652 Merry Place", "img_id": 9, "gender": "F" },
    { "id": "1c669764-3cff-424a-b7e4-e7711d6c7d6c", "full_name": "Coralyn Steljes", "job_title": "Accounting Assistant III", "country": "FR", "is_online": false, "rating": 2, "target": 81, "budget": -9871, "phone": "(760) 5696853", "address": "4 Norway Maple Pass", "img_id": 9, "gender": "F" },
    { "id": "4cc649dc-7f85-40ab-bc59-950f37268a65", "full_name": "Bruis Creavin", "job_title": "Nuclear Power Engineer", "country": "BR", "is_online": false, "rating": 1, "target": 59, "budget": -5798, "phone": "(570) 8801169", "address": "71 Carioca Park", "img_id": 6, "gender": "M" },
    { "id": "8c9505f1-e8f8-4ba6-8c9a-5a1d089c6f09", "full_name": "Adrianne Peery", "job_title": "Chief Design Engineer", "country": "FR", "is_online": false, "rating": 2, "target": 2, "budget": 56575, "phone": "(793) 1143493", "address": "3 2nd Drive", "img_id": 7, "gender": "F" },
    { "id": "ced0905e-44ac-4317-a203-76994e53a751", "full_name": "Port Gerauld", "job_title": "Senior Cost Accountant", "country": "FR", "is_online": true, "rating": 2, "target": 27, "budget": 97919, "phone": "(155) 5488067", "address": "9 High Crossing Center", "img_id": 5, "gender": "M" },
    { "id": "f0bcc7cd-999e-4611-a04f-7bdc16bc6160", "full_name": "Boy Antoszewski", "job_title": "VP Accounting", "country": "GB", "is_online": true, "rating": 2, "target": 11, "budget": 74779, "phone": "(715) 9192627", "address": "3773 Hazelcrest Road", "img_id": 6, "gender": "M" }
  ];
  currentDate: any = new Date();

  constructor(private apiService: ApiService) {

  }

  ngOnInit() {
    if (this.moduleId) {
      // this.getRegisters();
    }
  }

  formatData() {
    let columnsArray = [], columnsKeyArray = [], data = [];
    this.events.forEach(event => {
      let dataObject = {};
      event.columns.forEach(element => {
        if (!columnsKeyArray.includes(element.key)) {
          let columnObject = {
            field: element.key,
            title: element.label,
            position: element.position
          };
          columnsKeyArray.push(element.key);
          columnsArray.push(columnObject);
        }
        dataObject[element.key] = element.value;
      });
      data.push(dataObject);
    });
    this.gridView = data;
    this.columnsArray = columnsArray.sort((a, b) => {
      return a.position < b.position ? -1 : a.position > b.position ? 1 : 0;
    });
    console.log(columnsArray, data, columnsKeyArray);
  }

  getRegisters() {
    let requestObject = {
      module_id: this.moduleId
    };
    this.apiService.post(constants.api_end_points.GET_REGISTERS, requestObject).subscribe(response => {
      if (response.code == 200) {
        if (response.data.length > 0) {
          this.events = response.data;
          this.formatData();
        }
      }
    });
  }

  viewEvent(e) {
    console.log(e);
    this.eventView.emit(e.dataItem);
  }

  exportToExcel(grid) {
    grid.saveAsExcel();
  }

  exportToPDF(grid) {
    grid.saveAsPDF();
    let currentDate = new Date()
  }

}
