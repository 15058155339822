import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { constants } from 'src/app/providers/constants';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';
import { ApiService } from 'src/app/services/api.service';
import { TourService } from 'src/app/services/tour.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-web-menu',
  templateUrl: './web-menu.component.html',
  styleUrls: ['./web-menu.component.scss'],
})

export class WebMenuComponent implements OnInit {

  appConstants = constants;

  constructor(
    public commonMethodsService: CommonMethodsService,
    public menuService: MenuService,
    public router: Router,
    private apiService: ApiService,
    private actionSheetController: ActionSheetController,
    private readonly TourService: TourService,
    ) { }

  modulesList: any = [];
  activeModulesList: any = [];
  templatesList: any = [];
  moduleId: any;

  ngOnInit(
  ) {
    // this.getModules();
    // this.getActiveModules();
  }

  getClassForActiveLink(link) {
    if (this.router.url.includes(link)) {
      return 'link-active active';
    }
  }

  navigateTo(url) {
    this.router.navigate([url]);
  }

  getModules() {
    this.apiService.get(constants.api_end_points.GET_MODULES).subscribe(response => {
      if (response.code == 200) {
        this.modulesList = response.data;
        this.modulesList.forEach(element => {
          let iconName = element.name.toLowerCase() + '.svg'
          element['icon'] = 'assets/images/icons/' + iconName;
        });
      }
    });
  }

  getActiveModules() {
    this.apiService.get(constants.api_end_points.GET_ACTIVE_MODULES).subscribe(response => {
      if (response.code == 200) {
        response.data.forEach(element => {
          let controlName = element.name.toLowerCase();
          controlName = controlName.replace(/ /g, "_");
          element['value'] = controlName;
          element.templates.forEach(e => {
            e['module_id'] = element.id
          });
        });
        this.activeModulesList = response.data;
        // this.modulesList = response.data;
        // this.modulesList.forEach(element => {
        //   let iconName = element.name.toLowerCase() + '.svg'
        //   element['icon'] = 'assets/images/icons/' + iconName;
        // });
      }
    });
  }

  getTemplates() {
    let requestObject = {
      //module_id: Number(this.moduleId)
    };
    this.apiService.post(constants.api_end_points.GET_TEMPLATES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.templatesList = response.data;
      }
    })
  }

  goToFormCompletion(item) {
    // this.router.navigate(['template-settings/' + item.module_id + '/' + item.id + "/1"]);
    this.router.navigate(['view-form/' + item.forms[0].id]);
  }

  setHeightForWebMenu() {
    let addClassHeight = $('body')[0].clientHeight - 47;
    return {
      height: addClassHeight + 'px'
    }
  }

}
