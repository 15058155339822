import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  constructor(
    public router: Router,
    public apiService: ApiService,
    public menuService: MenuService,
    private menuCtrl: MenuController,
    public commonMethodsService: CommonMethodsService) { }

  ngOnInit() {
    // this.getModules()
  }

  modulesList: any = [];

  getClassForActiveLink(link) {
    if (this.router.url.includes(link)) {
      return 'link-active';
    }
  }

  navigateTo(url) {
    this.router.navigate([url]);
  }

  logout() {
    this.commonMethodsService.logout();
    // this.apiService.get(constants.api_end_points.LOGOUT).subscribe(response => {
    //   if (response.code == 200) {
    //     this.menuCtrl.close();
    //     localStorage.removeItem('SS-user');
    //     this.router.navigate(['login']);
    //   }
    // })
  }

  getModules() {
    this.apiService.get(constants.api_end_points.GET_MODULES).subscribe(response => {
      if (response.code == 200) {
        this.modulesList = response.data;
        this.modulesList.forEach(element => {
          let iconName = element.name.toLowerCase() + '.svg'
          element['icon'] = 'assets/images/icons/' + iconName;
        });
      }
    })
  }


  nameToInitials(fullName) {
    if (fullName) {
      const namesArray = fullName.trim().split(' ');
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
    }
  };

}
