import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: any;
  resetPasswordToken: any;
  showPassword: any;
  showConfirmPassword: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('code')) {
      let code = this.route.snapshot.paramMap.get('code');
      this.resetPasswordToken = code;
    }
    this.initializeResetPasswordForm();
  }

  initializeResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$")])],
      confirmPassword: new FormControl(null, [Validators.required])
    });
  }

  resetPassword() {
    let requestObject = {
      password: this.resetPasswordForm.value.password,
      value: this.resetPasswordToken
    }
    this.apiService.post(constants.api_end_points.RESET_PASSWORD, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Your Password is reset. Login using new password!');
        this.router.navigate(['login']);
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    })
  }

}
