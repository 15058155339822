import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MenuService } from 'src/app/services/menu.service';
@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.scss'],
})
export class AccountSelectionComponent implements OnInit {

  accountsList: any = [];
  searchInput: string = '';

  constructor(
    private router: Router,
    private apiService: ApiService,
    private menuService: MenuService,
    private loaderService: LoaderService,
    private navCtrl: NavController,) { }

  ngOnInit() {
    this.getUserAccounts();
  }

  getUserAccounts() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.USER_ACCOUNTS).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.accountsList = response.data;
        // this.presentPopover(response.data);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  selectAccount(account) {
    this.loaderService.showLoader();
    localStorage.setItem('account', JSON.stringify(account));
    // this.menuService.setAccountDetails(account);
    this.menuService.switchAccount(account).then(data => {
      this.menuService.setAccountDetails(account); 
      this.getUserDetails().then(data => {
        this.loaderService.hideLoader();
        // this.navCtrl.navigateRoot('user-settings');
        this.router.navigate(["feed"]);
      })
    })
  }

  getUserDetails() {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(constants.api_end_points.USER_DETAILS)
        .subscribe((response) => {
          if (response) {
            if (response.code == 200) {
              console.log(response);
              localStorage.setItem(
                "SS-user-details",
                JSON.stringify(response.data)
              );
              this.menuService.setUserDetails(response.data);
              resolve(response.data);
            } else if (response.code == 401) {
              alert(response.message);
              reject();
            }
          } else {
            reject();
          }
        });
    })
  }

}
