import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { ApiService } from 'src/app/services/api.service';
import { MenuService } from 'src/app/services/menu.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

  comment1: any = {};
  comment2: any = {};
  constructor(
    private router: Router,
    private platform: Platform,
    private location: Location,
    private apiService: ApiService,
    public menuService: MenuService,
    private route: ActivatedRoute,
    public commonMethodsService: CommonMethodsService,
  ) { }

  ngOnInit() { }

  ionViewDidEnter() {
    this.selectedSegment = this.route.snapshot.paramMap.get('tabId') ? this.route.snapshot.paramMap.get('tabId') : '1';
    this.onSegmentChange(this.selectedSegment);
  }

  selectedSegment: any = "1";

  setHeightForSegmentContainer() {
    let classHeight = $('body')[0].clientHeight - 50;
    return {
      height: classHeight + 'px',
      'max-height': classHeight + 'px'
    };
  }

  onSegmentChange(event) {
    switch (this.selectedSegment) {
      case '1': break;
      case '2': break;
    }
  }
  
  navigateToTabs() {
    this.onSegmentChange(this.selectedSegment);
    this.router.navigate(['help/' + this.selectedSegment]);
  }

  
  goBack() {
    this.location.back();
  }


}

