import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './auth-guards/login.guard';
import { RouteAuthGuard } from './auth-guards/route-auth.guard';
import { ViewFormComponent } from './common-components/view-form/view-form.component';
import { AccountSelectionComponent } from './components/account-selection/account-selection.component';
import { AddActionComponent } from './components/add-action/add-action.component';
import { AddElementComponent } from './components/add-element/add-element.component';
import { FeedComponent } from './components/feed/feed.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HelpComponent } from './components/help/help.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CustomTableComponent } from './common-components/custom-table/custom-table.component';
import { CommonFeedComponent } from './components/common-feed/common-feed.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'landing-page',
    component: LandingPageComponent,
    canActivate: [RouteAuthGuard, LoginGuard]
  },
  {
    path: 'login/:code',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'reset-password/:code',
    component: ResetPasswordComponent,
    // canActivate: [LoginGuard]
    // canActivate: [RouteAuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
    // canActivate: [RouteAuthGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'register/:code',
    component: RegisterComponent,
    canActivate: [RouteAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoginGuard]
    // canActivate: [RouteAuthGuard]
  },
  {
    path: 'add/:type',
    component: AddElementComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'add-action/:eventId',
    component: AddActionComponent
  },
  {
    path: 'add-action',
    component: AddActionComponent
  },
  {
    path: 'view-action/:id',
    component: AddActionComponent
  },
  {
    path: 'edit-user/:userId',
    component: ProfileComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'view-user/:userId',
    component: ProfileComponent,
    canActivate: [LoginGuard]
  },
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full',
    canActivate: [LoginGuard]
  },
  {
    path: 'account-settings',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/account-settings/account-settings.module').then(m => m.AccountSettingsPageModule)
  },
  {
    path: 'user-settings',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/user-settings/user-settings.module').then(m => m.UserSettingsPageModule)
  },
  // {
  //   path: 'module-settings/:id/:tabId',
  //   // canActivate: [LoginGuard],
  //   loadChildren: () => import('./pages/module-settings/module-settings.module').then(m => m.ModuleSettingsPageModule)
  // },
  {
    path: 'module-settings/:moduleId',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/module-settings/module-settings.module').then(m => m.ModuleSettingsPageModule)
  },
  {
    path: ':moduleId/:templateId/form',
    component: FormBuilderComponent
  },
  {
    path: 'form/:formId',
    component: FormBuilderComponent
  },
  // {
  //   path: 'view-form/:formId',
  //   component: ViewFormComponent
  // },
  {
    path: 'template-settings/:moduleId/:templateId',
    loadChildren: () => import('./pages/template-settings/template-settings.module').then(m => m.TemplateSettingsPageModule)
  },
  {
    path: 'actions',
    loadChildren: () => import('./pages/actions/actions.module').then(m => m.ActionsPageModule)
  },
  {
    path: 'feed',
    component: FeedComponent
  },
  {
    path: 'common-feed/:eventId',
    component: CommonFeedComponent
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help-module/help-module.module').then(m => m.HelpPageModule)
  },
  // {
  //   path: 'feed',
  //   loadChildren: () => import('./pages/feed/feed.module').then( m => m.FeedPageModule)
  // },
  {
    path: 'feed-module',
    loadChildren: () => import('./pages/feed-module/feed-module.module').then(m => m.FeedModulePageModule)
  },
  {
    path: 'account-selection',
    component: AccountSelectionComponent
  },
  {
    path: 'custom-table',
    component: CustomTableComponent
  },
  {
    path: 'module-listing/:moduleId',
    loadChildren: () => import('./pages/module-listing/module-listing.module').then( m => m.ModuleListingPageModule)
  },
  {
    path: 'my-items',
    loadChildren: () => import('./pages/module-listing/module-listing.module').then( m => m.ModuleListingPageModule)
  },
  {
    path: 'event-details/:moduleId/:eventId',
    loadChildren: () => import('./pages/event-details/event-details.module').then( m => m.EventDetailsPageModule)
  },
  {
    path: 'view-form/:eventId/:formId',
    loadChildren: () => import('./pages/form-details/form-details.module').then( m => m.FormDetailsPageModule)
  },
  {
    path: 'view-form/:formId',
    loadChildren: () => import('./pages/form-details/form-details.module').then( m => m.FormDetailsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
