import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as $ from 'jquery';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { state, keyframes, style, animate, trigger, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from "src/app/services/menu.service";
import { TourService } from "src/app/services/tour.service";
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { IonSlides, Platform } from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { saveAs } from 'file-saver';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
  animations: [
    trigger("inOutAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [
        animate(
          500,
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0.25, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.75, offset: 0.75 }),
            style({ opacity: 1, offset: 1 }),
          ])
        )
      ]),
      transition(":leave", [
        animate(
          500,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0.75, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.25, offset: 0.75 }),
            style({ opacity: 0, offset: 1 }),
          ])
        )
      ])
    ]),
    trigger("slideAnimation", [
      state("in", style({ opacity: 1 })),
      state("out", style({ opacity: 1 })),
      transition("in => out", [
        animate(
          300,
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0.25, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.75, offset: 0.75 }),
            style({ opacity: 1, offset: 1 }),
          ])
        )
      ]),
      transition(":leave", [
        animate(
          300,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0.75, offset: 0.25 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.25, offset: 0.75 }),
            style({ opacity: 0, offset: 1 }),
          ])
        )
      ])
    ])
  ]
})

export class FeedComponent implements OnInit {
  @ViewChildren(IonSlides) slides: QueryList<IonSlides>;
  //comment1: any = {};
  //comment2: any = {};
  isAddItemVisible: any;

  feedsData: any = [];
  slideOptions: any = {
    initialSlide: 0,
    speed: 400
  }

  constructor(
    private file: File,
    private platform: Platform,
    private apiService: ApiService,
    private router: Router,
    public menuService: MenuService,
    public tourService: TourService,
    private transfer: FileTransfer,
    private loaderService: LoaderService,
    public commonMethodsService: CommonMethodsService,
  ) { }

  ngOnInit() {
    // this.getFeedData();
  }

  ionViewWillEnter() {
    this.getFeedData();
    this.slides.changes.subscribe(res => this.slides = res)
  }

  showPrev(feed) {
    if (feed.currentSlide > 0 && feed.currentSlide <= feed.event.attachment.length) {
      return true;
    }
    return false;
  }

  showNext(feed) {
    if (feed.currentSlide != (feed.event.attachment.length - 1) && feed.currentSlide <= feed.event.attachment.length) {
      return true;
    }
    return false;
  }

  slidePrev(event, feed) {
    let x = this.slides['_results'].find(x => x.el.id == event);
    feed.currentSlide--;
    x.slidePrev();
  }

  slideNext(event, feed) {
    let x = this.slides['_results'].find(x => x.el.id == event);
    feed.currentSlide++;
    x.slideNext();
    // this.slides.slideNext();
  }

  setHeightForSegmentContainer() {
    let classHeight = $('body')[0].clientHeight - 50;
    return {
      height: classHeight + 'px',
      'max-height': classHeight + 'px'
    };
  }

  getFeedData() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.GET_FEED).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.feedsData = response.data;
        this.feedsData.forEach(i => {
          i['currentSlide'] = 0;
          if (i.event.module.icon) {
            let iconName = i.event.module.icon.toLowerCase().replace('.png', '-colour.svg')
            i.event.module.icon = iconName;
          }
        });
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  toggleIsAddItemVisible(item) {
    if (this.isAddItemVisible == item) {
      this.isAddItemVisible = -1;
    }
    else {
      this.isAddItemVisible = item;
    }
  }

  viewEvent(event) {
    console.log(event);
    if (this.menuService.accountDetails?.user_type_id == 3) {
      this.router.navigate(['view-form/' + event.eventId + '/' + event.form_id]);
    } else if (event.eventId) {
      this.router.navigate(['event-details/' + event.module.id + '/' + event.eventId]);
    }
  }

  like(event) {
    let requestObject = {
      event_id: event.eventId
    };
    this.apiService.post(constants.api_end_points.ADD_LIKE, requestObject).subscribe(response => {
      if (response.code == 200) {
        event.likes = response.data.likes;
        event.isLikedByUser = response.data.isLikedByUser;
        // this.apiService.successSnackBar(response.data.isLikedByUser ? 'Event Liked!' : 'Event Disliked!');
      }
    })
  }

  addComment(event, commentInput) {
    this.loaderService.showLoader();
    let requestObject = {
      event_id: event.eventId,
      title: commentInput.value,
      type: 'Comment'
    };
    this.apiService.post(constants.api_end_points.ADD_COMMENT, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Comment Added!');
        if (response.data.comments)
          event.comments = response.data.comments;
        commentInput.value = '';
        event.commentsCount = response.data.commentsCount;
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  deleteComment(event, commentId) {
    this.loaderService.showLoader();
    let requestObject = {
      id: commentId,
      event_id: event.eventId,
    };
    this.apiService.post(constants.api_end_points.DELETE_COMMENT, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Comment Deleted!');
        event.comments = response.data.comments;
        event.commentsCount = response.data.commentsCount;
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  downloadAttachment(attachment) {
    this.loaderService.showLoader();
    let url = encodeURI(attachment.file_url);
    const fileTransfer = this.transfer.create();
    console.log('called');

    let requestObject = {
      "file_url": attachment.file_url,
      // "file_name" : "anshulTest.png"
    };

    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      fileTransfer.download(attachment.file_url, this.file.externalRootDirectory + 'xyz').then((entry) => {
        //here logging our success downloaded file path in mobile. 
        console.log('download completed: ' + entry.toURL());
        // open downloaded file 
        // this.downloadFile = entry.toURL();

      }).catch((error) => {
        //here logging an error. 
        console.log('download failed: ' + JSON.stringify(error));
      });
    } else {
      this.apiService.fileDownload(constants.api_end_points.DOWNLOAD_FILE, requestObject).subscribe(res => {
        console.log(res);
        this.loaderService.hideLoader();
        saveAs(res, attachment.title);
      }, err => {
        this.loaderService.hideLoader();
      })
    }
    // this.apiService.fileDownload(constants.api_end_points.DOWNLOAD_FILE).subscribe(res => {
    //   console.log(res);
    //   saveAs(res, 'abc');
    // })
  }

  formatUserData(data, type) {
    if(type == 'feedUser') {
      return {
        id: data.created_by_id,
        profile_picture: data.profile_picture,
        full_name: data.created_by_full_name
      };
    } else if(type == 'comment') {
      return {
        id: data.by_id,
        profile_picture: data.profile_picture,
        full_name: data.full_name
      }
    }
  }

}
