import { Injectable } from '@angular/core';
import { constants } from '../providers/constants';
import { ApiService } from './api.service';
import { JoyrideService, JoyrideModule } from 'ngx-joyride';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TourService {


  constructor(
    private apiService: ApiService,
    private readonly joyrideService: JoyrideService,
    private router: Router,
    ) { }

  joyRideWebViewSettingsTour() {
    console.log("joyrideService", this.joyrideService);
    this.router.navigate(['/feed']);
    this.joyrideService
    .startTour({
      steps: 
        [
          'firstWebMenuSettingsStep@feed', 
          'secondWebMenuSettingsStep@feed', 
          'thirdWebMenuSettingsStep@feed',
          'fourthWebMenuSettingsStep@feed',
          'fifthWebMenuSettingsStep@feed',
          'sixthWebMenuSettingsStep@feed', 
          'seventhWebMenuSettingsStep@feed',
          'eighthWebMenuSettingsStep@feed',
          'ninthWebMenuSettingsStep@feed',
        ],
      showPrevButton: true,
      stepDefaultPosition: 'right',
      themeColor: '#0D47A1',
      waitingTime: 1000,
      logsEnabled: true,
    })
    .subscribe(
      (step) => {
        console.log("coming step section");
        console.log(step);
      },
      (error) => {
        console.log("coming in error section");
      },
      () => {
        console.log("tour finished");
      }
    )
  }

  
  joyRideAccountSettingsTour() {
    console.log("joyrideService", this.joyrideService);
    this.joyrideService
    .startTour({
      steps: 
        [
          'firstAccountSettingsStep@account-settings/1',
          'secondAccountSettingsStep@account-settings/2', 
          'thirdAccountSettingsStep@account-settings/3',
          'fourthAccountSettingsStep@account-settings/4',
          'fifthAccountSettingsStep@account-settings/5',
          'sixthAccountSettingsStep@account-settings/6',
        ],
      showPrevButton: true,
      stepDefaultPosition: 'below',
      themeColor: '#0D47A1',
      waitingTime: 1000,
      logsEnabled: true,
    })
    .subscribe(
      (step) => {
        console.log("coming step section");
        console.log(step);
      },
      (error) => {
        console.log("coming in error section");
      },
      () => {
        console.log("tour finished");
      }
    )
  }

  joyRideUserSettingsTour() {
    console.log("joyrideService", this.joyrideService);
    this.joyrideService
    .startTour({
      steps: 
        [
          'firstUserSettingsStep@user-settings/1',
          'secondUserSettingsStep@user-settings/2', 
          'thirdUserSettingsStep@user-settings/3',
          'fourthUserSettingsStep@user-settings/4',
          //'fifthUserSettingsStep@user-settings/5',
        ],
        showPrevButton: true,
        stepDefaultPosition: 'below',
        themeColor: '#0D47A1',
        waitingTime: 1000,
        logsEnabled: true,
      })
      .subscribe(
        (step) => {
          console.log("coming step section");
          console.log(step);
        },
        (error) => {
          console.log("coming in error section");
        },
        () => {
          console.log("tour finished");
        }
      )
    }

  joyRideModulePageTour() {
    console.log("joyrideService", this.joyrideService);
    this.joyrideService
    .startTour({
      steps: 
        [
          'fifthAccountSettingsStep@account-settings/5',
          'firstModuleStep@account-settings/5',
        ],
      showPrevButton: true,
      stepDefaultPosition: 'below',
      themeColor: '#0D47A1',
      waitingTime: 1000,
      logsEnabled: true,
    })
    .subscribe(
      (step) => {
        console.log("coming step section");
        console.log(step);
      },
      (error) => {
        console.log("coming in error section");
      },
      () => {
        console.log("tour finished");
      }
    )
  }

  joyRideFormBuilderTour() {
    console.log("joyrideService", this.joyrideService);
    this.joyrideService
    .startTour({
      steps: 
        [
          'firstFormBuilderStep', 
          'secondFormBuilderStep', 
          'thirdFormBuilderStep',
          'fourthFormBuilderStep',
          'fifthFormBuilderStep',
          'sixthFormBuilderStep', 
          'seventhFormBuilderStep',
          'eighthFormBuilderStep',
          'ninthFormBuilderStep',
          'tenthFormBuilderStep',
          'eleventhFormBuilderStep',
          'twelfthFormBuilderStep', 
          'thirteenthFormBuilderStep',
          'fifteenthFormBuilderStep',
        ],
      showPrevButton: true,
      themeColor: '#0D47A1'
    })
    .subscribe(
      (step) => {
        console.log("coming step section");
        console.log(step);
      },
      (error) => {
        console.log("coming in error section");
      },
      () => {
        console.log("tour finished");
      }
    )
  }

    
  joyRideOrganisationTour() {
    console.log("joyrideService", this.joyrideService);
    this.joyrideService
    .startTour({
      steps: 
        [
          'secondAccountSettingsStep@account-settings/2', 
          'thirdAccountSettingsStep@account-settings/3',
          'firstSiteStep@account-settings/3',
          'secondSiteStep@account-settings/3',
        ],
      showPrevButton: true,
      stepDefaultPosition: 'below',
      themeColor: '#0D47A1',
      waitingTime: 1000,
      logsEnabled: true,
    })
    .subscribe(
      (step) => {
        console.log("coming step section");
        console.log(step);
      },
      (error) => {
        console.log("coming in error section");
      },
      () => {
        console.log("tour finished");
      }
    )
  }

  joyRideUserTour() {
    console.log("joyrideService", this.joyrideService);
    this.joyrideService
    .startTour({
      steps: 
        [
          'firstAccountSettingsStep@account-settings/1',
          'firstUserStep@account-settings/1', 
          'secondUserStep@account-settings/1',
          'thirdUserStep@account-settings/1',
          'fourthUserStep@account-settings/1',
        ],
      showPrevButton: true,
      stepDefaultPosition: 'below',
      themeColor: '#0D47A1',
      waitingTime: 1000,
      logsEnabled: true,
    })
    .subscribe(
      (step) => {
        console.log("coming step section");
        console.log(step);
      },
      (error) => {
        console.log("coming in error section");
      },
      () => {
        console.log("tour finished");
      }
    )
  }

}
