import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  displayLoader: any;

  constructor() { }

  showLoader() {
    this.displayLoader = true;
  }

  hideLoader() {
    this.displayLoader = false;
  }
}
