import { Injectable } from '@angular/core';
import { constants } from '../providers/constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FeedDataService {

  feedData: any = [];

  constructor(
    private apiService: ApiService
  ) { }

  getFeedData(eventId) {
    let requestObject = {
      event_id: Number(eventId)
    };
    this.apiService.post(constants.api_end_points.GET_COMMON_FEED, requestObject).subscribe(response => {
      if (response.code == 200) {
        let feed = [];

        // feedType: 1 - all; 2 - comments; 3 - attachments; 4 - actions
        if (response.data.actions && response.data.actions.length > 0) {
          response.data.actions.forEach(action => {
            let actionObject = {
              id: action.id,
              eventId: action.childEventDetails.eventId,
              title: action.childEventDetails.title,
              created_at: action.childEventDetails.event_date,
              user_id: action?.user.id,
              full_name: action?.user.full_name,
              profile_picture: action?.user.profile_picture,
              feedType: 4,
              field_id: action.field_answer && action.field_answer.field_id ? action.field_answer.field_id : null,
              form_id: action.form_answer && action.form_answer.form_id ? action.form_answer.form_id : null
            };
            feed.push(actionObject)
          });
        }
        if (response.data.comments && response.data.comments.length > 0) {
          response.data.comments.forEach(comment => {
            let commentObject = {
              id: comment.id,
              title: comment.title,
              created_at: comment.created_at,
              user_id: comment.user.id,
              full_name: comment.user.full_name,
              profile_picture: comment?.user.profile_picture,
              feedType: comment.attachment_type_id == 1 ? 3 : 2,
              field_id: comment.field_answer && comment.field_answer.field_id ? comment.field_answer.field_id : null,
              form_id: comment.form_answer && comment.form_answer.form_id ? comment.form_answer.form_id : null
            };
            feed.push(commentObject);
          });
        }
        if (response.data.eventFeed && response.data.eventFeed.length > 0) {
          response.data.eventFeed.forEach(element => {
            let feedObject = {
              id: element.id,
              title: element.new_value,
              created_at: element.created_at,
              user_id: element.user.id,
              full_name: element?.user.full_name,
              profile_picture: element?.user.profile_picture,
              feedType: 1,
              field_id: element.field_answer && element.field_answer.field_id ? element.field_answer.field_id : null,
              form_id: element.form_answer && element.form_answer.form_id ? element.form_answer.form_id : null
            };
            feed.push(feedObject);
          });
        }
        this.feedData = feed.sort((a, b) => {
          if (a.created_at > b.created_at)
            return 1;
          else if (b.created_at > a.created_at)
            return -1;
          else return 0;
        });
        console.log(this.feedData);
      }
    })
  }
}
