import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';

export interface DialogData {
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  viewProfile: any;
  @ViewChild('profileTemplate') profileTemplate: TemplateRef<any>;
  @Input() styles: any = {};
  @Input() userData: any;
  @Input() displayName: any;
  userDetails: any;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    public menuService: MenuService,
    public commonMethodsService: CommonMethodsService
  ) { }

  ngOnInit() { }

  openDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    this.viewProfile = true;
    this.getUserDetails();
    const dialogRef = this.dialog.open(this.profileTemplate, {
      width: '300px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(response => {
      console.log('dialog close', response);
    })
  }

  getUserDetails() {
    let requestObject = {
      user_id: this.userData.by_id ? this.userData.by_id : this.userData.id
    };
    this.apiService.post(constants.api_end_points.USER_INFO, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.userDetails = response.data;
      }
    });
  }

}
