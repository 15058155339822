import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss'],
})
export class CustomFileUploadComponent implements OnInit {
  @Output() uploadFile: any = new EventEmitter<any>();
  @Output() removeFile: any = new EventEmitter<any>();
  file: any;

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // console.log('drag over');
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 1) {
      alert('You cannot attach multiple files!');
    } else {
      this.file = files[0];
      this.uploadFile.emit(this.file);
    }
    console.log(files);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // console.log('drag leave');
  }

  constructor() { }

  ngOnInit() { }

  openFileUpload() {
    let fileUpload = document.getElementById('fileDropRef');
    fileUpload.click();
  }

  fileBrowseHandler(files) {
    if (files.length > 1) {
      alert('You cannot attach multiple files!');
    } else {
      this.file = files[0];
      this.uploadFile.emit(this.file);
    }
  }

  clearFile() {
    this.file = null;
    this.removeFile.emit();
  }

}
