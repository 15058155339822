import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { FeedDataService } from 'src/app/services/feed-data.service';

@Component({
  selector: 'app-custom-bottom-pane',
  templateUrl: './custom-bottom-pane.component.html',
  styleUrls: ['./custom-bottom-pane.component.scss'],
})
export class CustomBottomPaneComponent implements OnInit {
  @Input() eventId: any;
  @Output() updateFeed: any = new EventEmitter<any>();
  @Output() openAddActionSection: any = new EventEmitter<any>();


  selectedSegment: any;
  file: any;
  toggleAction: any;
  toggleComment: any;
  toggleAttachment: any;

  constructor(
    private router: Router,
    private feedDataService: FeedDataService,
    private apiService: ApiService) { }

  ngOnInit() { }

  openAddAction() {
    this.openAddActionSection.emit();
  }

  setSegmentValue(segmentId) {
    this.selectedSegment = segmentId;
    switch (segmentId) {
      case 1: this.toggleComment = !this.toggleComment; this.toggleAttachment = false; break;
      case 2: this.toggleAttachment = !this.toggleAttachment; this.toggleComment = false; break;
      case 3: this.openAddAction(); break;
      // case 3: this.toggleAction = !this.toggleAction; break;
    }
  }

  addComment(input) {
    let requestObject = {
      event_id: Number(this.eventId),
      title: input.value,
      type: 'Comment'
    };
    this.apiService.post(constants.api_end_points.ADD_COMMENT, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Comment Added!');
        if (response.data.comments)
          // event.comments = response.data.comments;
          input.value = '';
        this.toggleComment = !this.toggleComment;
        // this.updateFeed.emit();
        this.feedDataService.getFeedData(this.eventId);
        // event.commentsCount = response.data.commentsCount;
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    })
  }

  uploadFile(event) {
    console.log(event);
    let requestObject = new FormData();
    requestObject.set('file', event);
    this.apiService.fileUpload(constants.api_end_points.UPLOAD_FILE, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.file = response.data;
      }
    });
  }

  addAttachment(input) {
    let extension = this.file.filename.split('.')[this.file.filename.split('.').length - 1];
    let requestObject = {
      type: 'File',
      title: input.value ? input.value : this.file.filename,
      event_id: Number(this.eventId),
      file_name: this.file.url,
      file_extension: extension ? extension : '',
      file_size: this.file.size
    };
    this.apiService.post(constants.api_end_points.ADD_COMMENT, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Attachment Added!');
        // event.comments = response.data.comments;
        this.toggleAttachment = !this.toggleAttachment;
        this.feedDataService.getFeedData(this.eventId);
        // this.updateFeed.emit();
        // event.commentsCount = response.data.commentsCount;
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    })
  }

  cancelUpload() {
    this.toggleAttachment = !this.toggleAttachment;
  }

}
