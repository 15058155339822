import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  registrationForm: FormGroup;
  showPassword: any;
  countries: any = [
    'ABC',
    'CYX',
    'CTY'
  ];
  timeZones: any = [];
  countryItem: any;

  showVerificationCodeTextbox: boolean = true;
  showCodeVerificationScreen: any;
  verificationCode: any;
  filteredCountries: Observable<any[]>;
  // filteredCountries: any = [];


  constructor(
    private router: Router,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit() {
    const inviteCode = this.activatedRoute.snapshot.queryParamMap.get('invite_code');
    if (inviteCode) {
      this.checkUrlValidity(inviteCode.substring(0, inviteCode.length - 4));
    }

    this.activatedRoute.params.subscribe(res => {
      if (res) {
        if (res.code) {
          this.showCodeVerificationScreen = true;
          this.verificationCode = res.code;
          this.showVerificationCodeTextbox = false
        } else {
          this.initializeForm();
          // this.getTimeZones();
          this.getCountries();
        }
      }
    });
  }

  checkUrlValidity(inviteCode) {
    let requestObject = {
      url: location.protocol + "//" + location.host + this.router.url
    }
    this.apiService.post(constants.api_end_points.CHECK_LINK_VALIDITY, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.initializeForm(inviteCode);
      } else {
        this.apiService.errorSnackBar('Invalid Invite Link!');
      }
    })
  }

  initializeForm(inviteCode = null) {
    this.registrationForm = this.formBuilder.group({
      fullName: new FormControl(null, [Validators.required]),
      preferredName: new FormControl(null, [Validators.required]),
      emailId: new FormControl(null, [Validators.required, Validators.email]),
      //mobile: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{10}$")]),
      account: new FormControl(inviteCode ? 2 : 1, [Validators.required]),
      //password: [null, Validators.compose([Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$")])],
      password: [null, Validators.compose([Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^!?&+=])(?=\\S+$).{6,20}$")])],
      companyName: new FormControl(null, []),
      companyId: new FormControl(inviteCode ? inviteCode : null, []),
      country: new FormControl(null, [Validators.required]),
      timezone: new FormControl(null, [Validators.required])
    });
  }

  getCountries() {
    this.apiService.get(constants.api_end_points.COUNTRIES).subscribe(response => {
      if (response.code == 200) {
        this.countries = response.data;
        let timezoneControl = this.registrationForm.get('country');
        timezoneControl.reset();
        this.filteredCountries = timezoneControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.country),
            map(name => name ? this.filterCountries(name) : this.countries.slice())
          );
        // this.getTimeZones(response.data[0].id);
      }
    })
  }

  getTimeZones(id = null) {
    let requestObject = {
      "countryId": id ? id : 1
    };

    this.apiService.post(constants.api_end_points.TIME_ZONES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.timeZones = response.data;
        // this.registrationForm.patchValue({timezone: ''});
        let timezoneControl = this.registrationForm.get('timezone');
        timezoneControl.reset();
      }
    })
  }

  displayFn(value) {
    // console.log(event);
    return value && value.country ? value.country : '';
  }

  optionChange(event) {
    event.option.value ? this.getTimeZones(event.option.value.id) : '';
  }

  onRadioChange(event) {
    let companyIdControl = this.registrationForm.get('companyId');
    let comapnyNameControl = this.registrationForm.get('companyName');

    if (this.registrationForm.value.account == '2') {
      companyIdControl.setValidators(Validators.required);
      companyIdControl.setValue(null);
      companyIdControl.markAsUntouched();

    } else {
      comapnyNameControl.setValue(null);
      companyIdControl.clearValidators();
    }

    // if(this.registrationForm.value.account == 1) {
    //   comapnyNameControl.setValidators(Validators.required);
    // }
    this.registrationForm.updateValueAndValidity();
  }

  getStylesForRadioButton(value) {
    if (this.registrationForm.value.account == value) {
      return {
        'border': 'solid 2px #2196f3',
        'background-color': 'rgba(33,150,243,0.2)',
      };
    }
  }

  filterCountries(value) {
    const filterValue = value ? value.toLowerCase() : '';
    return this.countries.filter(option => option.country.toLowerCase().startsWith(filterValue));
  }

  register() {
    this.loaderService.showLoader();
    // console.log(this.registrationForm.value);
    let requestObject = {
      "full_name": this.registrationForm.value.fullName,
      "preferred_name": this.registrationForm.value.preferredName,
      "email": this.registrationForm.value.emailId,
      //"mobile_number": this.registrationForm.value.mobile,
      "account_type": this.registrationForm.value.account,
      "timezone_id": this.registrationForm.value.timezone,
      "password": this.registrationForm.value.password
    };

    this.registrationForm.value.account == 1 ? (requestObject['company_name'] = this.registrationForm.value.companyName) : (requestObject['code'] = this.registrationForm.value.companyId);

    this.apiService.post(constants.api_end_points.REGISTER, requestObject).subscribe(response => {
      if (response && response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Registration Successful!');
        this.registrationForm.reset();
        this.verificationCode = null;
        this.showCodeVerificationScreen = true;
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Wnet Wrong!');
    });
  }

  processVerificationResponse(apiResponse) {
    if (apiResponse.code == 200) {
      alert('Account Verified');
      this.router.navigate(['login']);
    } else if (apiResponse.code == 401) {
      alert(apiResponse.message);
    }
  }


}
