import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private platform: Platform,
  ) { }

  ngOnInit() { }

  goToLogin() {
    this.router.navigate(['login']);
  }

  goHome() {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      this.router.navigate(['landing-page']);
    } else {
      this.router.navigate(['register']);
    }
  }

}
