import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { UserService } from '../services/user.service';


@Injectable({
  providedIn: 'root'
}
)
export class HttpTokenInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  apisToExcludeHeader: any = ['verify'];
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};

    if (req.url) {
      let end_point = req.url.split('api');
      if (end_point[2] && end_point[2] != '/files/uploadAttachment' && end_point[2] != '/activityFeed/downLoadFile') {
        headersConfig['Content-Type'] = 'application/json';
      }
    }

    // const token = this.userService.getToken();

    const token = localStorage.getItem('SS-user');
    let account = JSON.parse(localStorage.getItem('account'));

    if (token && !req.url.includes('verify')) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    if (account && !req.url.includes('verify')) {
      headersConfig['account-id'] = String(account.id);
    }

    const request = req.clone({ setHeaders: headersConfig });
    // console.log(request);
    // if (req.url) {
    //   let end_point = req.url.split('api');
    //   if (end_point[2] && end_point[2] != '/files/uploadAttachment' && end_point[2] != '/activityFeed/downLoadFile') {
    //     return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
    //       if (event instanceof HttpResponse) {
    //         console.log(event);
    //       }
    //     },
    //       (err: any) => {
    //         console.log(err);
    //       }));
    //   }
    // }

    return next.handle(request);
    

  }
}