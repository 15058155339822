import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { VerificationComponent } from './verification/verification.component';
import { PopoverComponent } from '../common-components/popover/popover.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { FeedComponent } from './feed/feed.component';
import { AccountSelectionComponent } from './account-selection/account-selection.component';
import { CommonFeedComponent } from './common-feed/common-feed.component';
import { AddActionComponent } from './add-action/add-action.component';
import { AccountSettingsContentComponent } from './account-settings-content/account-settings-content.component';
import { AddElementComponent } from './add-element/add-element.component';
import { UsersComponent } from './users/users.component';
import { UserSettingsContentComponent } from './user-settings-content/user-settings-content.component';
import { ModuleSettingsContentComponent } from './module-settings-content/module-settings-content.component';
import { TemplateSettingsContentComponent } from './template-settings-content/template-settings-content.component';
import { HelpComponent } from './help/help.component';
import { JoyrideModule } from 'ngx-joyride';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    FeedComponent,
    LoginComponent,
    RegisterComponent,
    CommonFeedComponent,
    LandingPageComponent,
    VerificationComponent,
    PopoverComponent,
    ResetPasswordComponent,
    ProfileComponent,
    LogoutComponent,
    FormBuilderComponent,
    UsersComponent,
    AddActionComponent,
    AddElementComponent,
    CustomDialogComponent,
    PageNotFoundComponent,
    AccountSelectionComponent,
    UserSettingsContentComponent,
    ModuleSettingsContentComponent,
    AccountSettingsContentComponent,
    TemplateSettingsContentComponent,
    HelpComponent,
  ],
  imports: [
    // BrowserModule,
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonComponentsModule,
    // BrowserAnimationsModule,
    MaterialModule,
    JoyrideModule.forChild(), 
    RouterModule.forChild([]),
  ],
  exports: [
    UsersComponent,
    AddElementComponent,
    VerificationComponent, 
    ProfileComponent, 
    CommonFeedComponent, 
    AddActionComponent, 
    CustomDialogComponent,
    UserSettingsContentComponent,
    ModuleSettingsContentComponent,
    AccountSettingsContentComponent,
    TemplateSettingsContentComponent,
    HelpComponent,
  ]
})
export class ComponentsModule { }
