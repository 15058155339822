import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { WebMenuComponent } from './web-menu/web-menu.component';
import { CustomBottomPaneComponent } from './custom-bottom-pane/custom-bottom-pane.component';
import { MaterialModule } from '../material.module';
import { CustomFileUploadComponent } from './custom-file-upload/custom-file-upload.component';
import { MobileNavbarComponent } from './mobile-navbar/mobile-navbar.component';
import { ViewFormComponent } from './view-form/view-form.component';


import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimplySafePipesModule } from './pipes/pipes.module';
import { CustomTableComponent } from './custom-table/custom-table.component';

import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { JoyrideModule } from 'ngx-joyride';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

// import { AddElementComponent } from '../components/add-element/add-element.component';



@NgModule({
  declarations: [
    SideMenuComponent,
    HeaderComponent,
    WebMenuComponent,
    AddMenuComponent,
    ViewFormComponent,
    CustomTableComponent,
    UserProfileComponent,
    MobileNavbarComponent,
    CustomBottomPaneComponent,
    CustomFileUploadComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    SimplySafePipesModule,
    GridModule,
    ExcelModule,
    InputsModule,
    DateInputsModule,
    FloatingLabelModule,
    PDFModule,
    JoyrideModule.forChild(), 
    RouterModule.forChild([]),
  ],
  exports: [
    SideMenuComponent,
    HeaderComponent,
    WebMenuComponent,
    AddMenuComponent,
    ViewFormComponent,
    CustomTableComponent,
    UserProfileComponent,
    MobileNavbarComponent,
    CustomBottomPaneComponent,
    CustomFileUploadComponent,
    SimplySafePipesModule,]
})
export class CommonComponentsModule { }
