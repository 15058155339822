import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { constants } from 'src/app/providers/constants';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  pageTitle: any;

  constructor(
    private router: Router,
    private location: Location,
    public menuService: MenuService,
    public commonMethodsService: CommonMethodsService
  ) { }

  ngOnInit() {
    // this.router.events.subscribe((val) => {
    //   if(this.router.url.includes('account-settings')) {
    //     this.pageTitle = 'Account Settings';
    //   }
    //   if(this.router.url.includes('user-settings')) {
    //     this.pageTitle = 'User Settings';
    //   }
    //   if(this.router.url.includes('module-settings')) {
    //     this.pageTitle = 'Module Settings';
    //   }
    //   if(this.router.url.includes('template-settings')) {
    //     this.pageTitle = 'Template Settings'
    //   }
    //   if(this.router.url.includes('actions')) {
    //     this.pageTitle = 'Actions';
    //   }
    //   if(this.router.url.includes('feed')) {
    //     this.pageTitle = 'Feed';
    //   }
    //   // switch (this.router.url) {
    //     // case '/account-settings': this.pageTitle = 'Account Settings'; break;
    //     // case '/user-settings': this.pageTitle = 'User Settings'; break;
    //     // case '/module-settings': this.pageTitle = 'Module Settings'; break;
    //   // }
    // });
  }

  getPageTitle() {
    // if (this.router.url.includes('account-settings')) {
    //   return 'Account Settings';
    // }
    // if (this.router.url.includes('user-settings')) {
    //   return 'User Settings';
    // }
    // if (this.router.url.includes('module-settings')) {
    //   return 'Module Settings';
    // }
    // if (this.router.url.includes('template-settings')) {
    //   return 'Template Settings'
    // }
    // if (this.router.url.includes('actions')) {
    //   return 'Actions';
    // }
    // if (this.router.url.includes('feed')) {
    //   return 'Feed';
    // }
    if (this.router.url.endsWith('account-settings')) {
      this.pageTitle = 'Account Settings';
    }
    if (this.router.url.includes('account-settings/1')) {
      this.pageTitle = 'Account Settings / Users';
    }
    if (this.router.url.includes('account-settings/2')) {
      this.pageTitle = 'Account Settings / Organisation';
    }
    if (this.router.url.includes('account-settings/3')) {
      this.pageTitle = 'Account Settings / Sites';
    }
    if (this.router.url.includes('account-settings/4')) {
      this.pageTitle = 'Account Settings / Settings';
    }
    if (this.router.url.includes('account-settings/5')) {
      this.pageTitle = 'Account Settings / Modules';
    }
    if (this.router.url.includes('account-settings/6')) {
      this.pageTitle = 'Account Settings / Lists';
    }
    if (this.router.url.endsWith('user-settings')) {
      this.pageTitle = 'User Settings';
    }
    if (this.router.url.includes('user-settings/1')) {
      this.pageTitle = 'User Settings / Profile';
    }
    if (this.router.url.includes('user-settings/2')) {
      this.pageTitle = 'User Settings / Password';
    }
    if (this.router.url.includes('user-settings/3')) {
      this.pageTitle = 'User Settings / Alerts';
    }
    if (this.router.url.includes('user-settings/4')) {
      this.pageTitle = 'User Settings / Settings';
    }
    if (this.router.url.includes('user-settings/5')) {
      this.pageTitle = 'User Settings / Accounts';
    }
    if (this.router.url.includes('module-settings')) {
      this.pageTitle = 'Module Settings';
    }
    if (this.router.url.includes('template-settings')) {
      this.pageTitle = 'Template Settings'
    }
    if (this.router.url.includes('event-details')) {
      this.pageTitle = 'Event'
    }
    if (this.router.url.includes('module-listing')) {
      this.pageTitle = 'Registers'
    }
    if (this.router.url.includes('my-items')) {
      this.pageTitle = 'My Items'
    }
    if (this.router.url.includes('actions')) {
      this.pageTitle = 'Actions';
    }
    if (this.router.url.includes('feed')) {
      this.pageTitle = 'Feed';
    }
    if (this.router.url.includes('view-form')) {
      this.pageTitle = 'Add New';
    }
    if (this.router.url.includes('/form/')) {
      this.pageTitle = 'Form Builder';
    }
    if (this.router.url.includes('help')) {
      this.pageTitle = 'Help and Support';
    }
    if (this.router.url.includes('notifications')) {
      this.pageTitle = 'Notifications';
    }
    return this.pageTitle;
  }

  isEventPage() {
    if (this.router.url.includes('event-details'))
      return true;
  }

  toggleMenuForWeb() {
    constants.hideMenuForWeb = !constants.hideMenuForWeb;
  }

  nameToInitials(fullName) {
    const namesArray = fullName.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
  }

  navigateTo(url) {
    this.router.navigate([url]);
  }

  goBack() {
    let currentState = this.location.getState();
    if (currentState['navigationId'] && currentState['navigationId'] > 1) {
      this.location.back();
    } else {
      this.router.navigate(['feed']);
    }
  }

}
