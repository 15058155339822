import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
import { AlertController, MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';
import { AddElementComponent } from '../add-element/add-element.component';
import { ProfileComponent } from '../profile/profile.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { PopoverComponent } from 'src/app/common-components/popover/popover.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { state, keyframes, style, animate, trigger, transition } from '@angular/animations';
import * as $ from 'jquery';
import { TourService } from 'src/app/services/tour.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-account-settings-content',
  templateUrl: './account-settings-content.component.html',
  styleUrls: ['./account-settings-content.component.scss'],
})
export class AccountSettingsContentComponent implements OnInit {

  @ViewChild(ProfileComponent, { static: false }) profileComponent: ProfileComponent;
  @ViewChild(AddElementComponent, { static: false }) addElementComponent: AddElementComponent;

  selectedSegment: any = "1";

  profileForm: FormGroup;
  businessUnitForm: FormGroup;
  siteForm: FormGroup;
  settingsForm: FormGroup;

  showAddClass: any;
  showEditUser: any;
  showViewUser: any;
  showPendingUser: any;
  displayEditProfileForm: any;
  optionsList: any = [];

  inviteLink: any;
  userTypes: any = [];
  usersList: any = [];
  timeZones: any = [];
  countries: any = [];
  editUserId: any;
  elementId: any;

  businessUnitData: any = [];

  sitesData: any = [];

  languages: any = [
    {
      id: 1,
      label: 'English'
    },
    {
      id: 2,
      label: 'Spanish'
    },
    {
      id: 3,
      label: 'French'
    }
  ]

  parentUnits: any = [];
  parentSites: any = [];

  modulesList: any = [];

  listData: any = [];

  displayColumns: string[] = ['name', 'template', 'events', 'is_inactive', 'actions'];
  // listColumns: string[] = ['name', 'forms', 'events', 'status', 'actions'];
  dataSource: any;
  activeUsers: any = [];
  inactiveUsers: any = [];
  userDataSource: any = [];
  userDataSource1: any = [];

  subscription: any = new Subscription();

  constructor(
    private router: Router,
    private platform: Platform,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public formBuilder: FormBuilder,
    public menuService: MenuService,
    private loaderService: LoaderService,
    private alertController: AlertController,
    private popoverController: PopoverController,
    public commonMethodsService: CommonMethodsService,
    private _snackBar: MatSnackBar,
    public tourService: TourService,
  ) { }

  ngOnInit() {
    // this.selectedSegment = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '1';
    // console.log(this.selectedSegment);
    this.initializeSettingsForm();
    this.getCountries();
    this.getInviteLink();
    // this.onSegmentChange(this.selectedSegment);
  }

  ionViewDidEnter() {
    // console.log(this.selectedSegment);
    this.selectedSegment = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '1';
    this.onSegmentChange(this.selectedSegment);
  }

  getUserTypes() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.USER_TYPES).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.userTypes = response.data;
        this.optionsList = this.userTypes;
        this.getUsersList();
      } else {
        this.loaderService.hideLoader();
      }
    }, err => {
      this.loaderService.hideLoader();
    })
  }

  getTimeZones() {
    let requestObject = {
      "countryId": 1
    };

    this.apiService.post(constants.api_end_points.TIME_ZONES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.timeZones = response.data;
      }
    })
  }

  getCountries() {
    this.apiService.get(constants.api_end_points.COUNTRIES).subscribe(response => {
      if (response.code == 200) {
        this.countries = response.data;
        // this.getTimeZones(response.data[0].id);
      }
    })
  }

  getInviteLink() {
    this.apiService.get(constants.api_end_points.GET_INVITE_LINK).subscribe(response => {
      if (response.code == 200) {
        if (response.data.is_invite_link_url_active) {
          this.inviteLink = response.data.invite_link_url;
        } else {
          this.inviteLink = '';
        }
      }
    })
  }

  setInviteLinkStatus() {
    let requestObject = {
      is_active: true
    };
    if (this.inviteLink) {
      requestObject.is_active = false;
      this.apiService.successSnackBar('Link Deactivated');
    }
    this.apiService.post(constants.api_end_points.SET_INVITE_LINK_STATUS, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.getInviteLink();
        //this.apiService.successSnackBar('Link Activated');
      }
    })
  }

  copyToClipboard(inviteElement) {
    this.clipboard.copy(inviteElement.text);
    this.apiService.successSnackBar('Link Copied to clipboard');
  }

  deleteInviteLink() {
    this.apiService.get(constants.api_end_points.DELETE_INVITE_LINK).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Invite Link Deleted');
        this.inviteLink = '';
        // this.getInviteLink();
      }
    })
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: "md",
      header: 'Delete invite link?',
      message: 'Once you delete this link, no one will be able to reuse it to join your account again.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Delete',
          handler: () => {
            console.log('Confirm Okay');
            this.deleteInviteLink();
          }
        }
      ]
    });

    await alert.present();
  }

  getUsersList() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.USERS).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        let activeUsers = [], inactiveUsers = [];
        response.data.forEach(element => {
          // if (element.user.id !== this.menuService.userDetails.id) {
          let userType = this.userTypes.find(x => x.id == element.type_id);
          if (userType) {
            element.details['userType'] = userType;
          }
          if (!element.details.is_pending) {
            activeUsers.push(element.details);
          } else {
            inactiveUsers.push(element.details);
          }
          // }
        });
        this.activeUsers = activeUsers;
        this.inactiveUsers = inactiveUsers;
        this.userDataSource = new MatTableDataSource(activeUsers);
        this.userDataSource1 = new MatTableDataSource(inactiveUsers);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  getBusinessUnits() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.BUSINESS_UNITS).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        response.data.forEach(element => {
          if (element.children && element.children.length > 0) {
            element.collapsed = true;
          }
        });
        this.businessUnitData = response.data
        let parentUnits = [];
        let parentSites = [];
        let recursiveList = (list, listToPush) => {
          list.forEach(element => {
            element.collapsed = true;
            listToPush.push({
              id: element.id,
              label: element.name,
            });
            if (element.children && element.children.length > 0) {
              recursiveList(element.children, listToPush);
            }
          });
        }

        // recursiveList(this.businessUnitData, parentUnits);
        // this.parentUnits = parentUnits;
        // this.optionsList = parentUnits;
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  getSites() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.SITES).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        response.data.forEach(element => {
          if (element.children && element.children.length > 0) {
            element.collapsed = true;
          }
        });
        this.sitesData = response.data;
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    });
  }

  getParentBusinessUnits() {
    this.apiService.get(constants.api_end_points.GET_PARENT_UNITS).subscribe(response => {
      if (response.code == 200) {
        this.parentUnits = response.data;
        this.optionsList = response.data;
      }
    });
  }

  getParentSites() {
    this.apiService.get(constants.api_end_points.GET_PARENT_SITES).subscribe(response => {
      if (response.code == 200) {
        this.parentSites = response.data;
        this.optionsList = response.data;
      }
    });
  }

  getSettings() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.GET_SETTINGS).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.initializeSettingsForm(response.data);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  getModules() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.GET_MODULES).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.modulesList = response.data;
        this.modulesList.forEach(i => {
          if (i.name) {
            let iconName = i.icon.toLowerCase().replace('.png', '-colour.svg')
            i['icon'] = iconName;
            console.log(iconName);
          }
        });
        // this.modulesList.forEach(element => {
        //   let iconName = element.name.toLowerCase() + '.png'
        //   element['icon'] = 'assets/images/icons/' + iconName;
        // });
        this.dataSource = new MatTableDataSource(this.modulesList);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  getLists() {
    this.loaderService.showLoader();
    let requestObject = {
      showAccountList: true
    };
    this.apiService.post(constants.api_end_points.GET_LISTS, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        if (response.data.length > 0) {
          this.listData = response.data;
          this.dataSource = new MatTableDataSource(this.listData);
          this.setHeightForSegmentContainer();
        }
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    });
  }

  updateView() {
    if (this.selectedSegment != 2 && this.selectedSegment != 3)
      this.showAddClass = false;
    switch (this.selectedSegment) {
      case "1": this.getUsersList(); break;
      case "2": this.getBusinessUnits(); this.getParentBusinessUnits(); break;
      case "3": this.getSites(); this.getParentSites(); break;
      case "5": this.getModules(); break;
      case "6": this.getLists(); break;
    }
  }

  initializeSettingsForm(data = null) {
    this.settingsForm = this.formBuilder.group({
      organisationName: new FormControl({ value: (data && data.name ? data.name : null), disabled: this.menuService.accountDetails?.user_type_id == 2 || this.menuService.accountDetails?.user_type_id == 3 }, [Validators.required]),
      eventAutoCloseDays: new FormControl((data && data.event_auto_close_days ? data.event_auto_close_days : 0), []),
      language: new FormControl({ value: 1, disabled: true }, [Validators.required])
    });
  }

  displayAddSection(id = null) {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      if (this.selectedSegment == 1) {
        let routeData: NavigationExtras = {
          state: {
            userTypes: this.userTypes,
            timeZones: this.timeZones,
            countries: this.countries
          }
        }
        this.router.navigate(['add/' + this.selectedSegment], routeData);
      } else if (this.selectedSegment == 2 || this.selectedSegment == 3) {
        let routeData: NavigationExtras = {
          state: {
            options: this.selectedSegment == 2 ? this.parentUnits : this.parentSites,
            elementId: id
          }
        };
      } else if (this.selectedSegment == 5) {
        let routeData: NavigationExtras = {
          state: {
            elementId: id
          }
        };
        this.router.navigate(['add/' + this.selectedSegment], routeData);
      } else if (this.selectedSegment == 6) {
        let routeData: NavigationExtras = {
          state: {
            // options: this.selectedSegment == 2 ? this.parentUnits : this.parentSites,
            elementId: id
          }
        };
        this.router.navigate(['add/' + this.selectedSegment], routeData);
      } else {
        this.router.navigate(['add/' + this.selectedSegment]);
      }
    } else {
      this.showAddClass = true;
      this.setHeightForAddSection();
      this.elementId = id;
      this.showEditUser = false;
      if (this.selectedSegment == 1) {
        this.showPendingUser = false;
        this.showViewUser = false;
        this.editUserId = null;
        this.displayEditProfileForm = true;
        if (this.profileComponent) {
          this.profileComponent.editUser = true;
          this.profileComponent.viewUser = false;
          this.profileComponent.viewPendingUser = false;
          this.profileComponent.displayEditProfileForm = true;
          this.profileComponent.initializeForm({}).then(data => {
            this.profileComponent.profileForm = data;
          });
        }
      } else {
        this.showEditUser = false;
        if (this.addElementComponent) {
          if (id) {
            this.addElementComponent.elementId = id;
            this.addElementComponent.getElementData();
          } else {
            this.addElementComponent.elementId = null;
            this.addElementComponent.ngOnInit();
          }
        }
      }
    }
  }

  onSegmentChange(event) {
    switch (this.selectedSegment) {
      case "1": this.getUserTypes(); break;
      case "2": this.getBusinessUnits(); this.getParentBusinessUnits(); break;
      case "3": this.getSites(); this.getParentSites(); break;
      case "4": this.getSettings(); break;
      case "5": {
        this.getModules();
        this.displayColumns = ['icon', 'name', 'description', 'templates', 'events', 'is_inactive', 'actions'];
        break;
      }
      case "6": {
        this.getLists();
        this.displayColumns = ['icon', 'name', 'description', 'forms', 'created_by_name', 'is_inactive'];
        break;
      }
    }

  }

  navigateToTab(event) {
    this.showAddClass = false;
    this.router.navigate(['account-settings/' + this.selectedSegment]);
  }

  toggleChildren(item) {
    item.collapsed = !item.collapsed;
    let recursiveList = (list) => {
      list.forEach(element => {
        element.collapsed = item.collapsed;
        element['hideChildren'] = item.collapsed ? false : true;
        if (element.children && element.children.length > 0) {
          recursiveList(element.children);
        }
      });
    }
    if (item.children && item.children.length > 0) {
      recursiveList(item.children);
    }
  }

  displayPopover(event) {
    event.preventDefault();
    event.stopPropagation();
    this.presentPopover(event);
  }

  async presentPopover(ev: any) {
    let popoverList = [
      {
        id: 1,
        title: 'Edit'
      },
      {
        id: 1,
        title: 'Disable'
      }
    ]
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      componentProps: { popoverList: popoverList },
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  editUser(event) {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      this.router.navigate(['edit-user/' + event.id]);
    } else {
      this.showEditUser = true;
      this.showAddClass = true;
      this.setHeightForAddSection();
      this.showViewUser = false;
      this.editUserId = event.id;
      this.showPendingUser = false;
      if (this.profileComponent) {
        this.profileComponent.editUser = true;
        this.profileComponent.viewUser = false;
        this.profileComponent.viewPendingUser = false;
        this.profileComponent.displayEditProfileForm = true;
        this.profileComponent.getUserDetails(event.id);
      }
    }
  }

  viewUser(event) {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      this.router.navigate(['view-user/' + event.id]);
    } else {
      this.showEditUser = true;
      this.showViewUser = true;
      this.showAddClass = true;
      this.setHeightForAddSection();
      this.editUserId = event.id;
      this.showPendingUser = false;
      if (this.profileComponent) {
        // this.profileComponent.
        this.profileComponent.viewUser = true;
        this.profileComponent.editUser = true;
        this.profileComponent.viewPendingUser = false;
        this.profileComponent.displayEditProfileForm = true;
        this.profileComponent.getUserDetails(event.id);
      }
    }
  }

  viewPendingUser(event) {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      this.router.navigate(['view-user/' + event.id]);
    } else {
      this.showViewUser = false;
      this.showEditUser = true;
      this.showAddClass = true;
      this.setHeightForAddSection();
      this.editUserId = event.id;
      this.showPendingUser = true;
      if (this.profileComponent) {
        this.profileComponent.viewPendingUser = true;
        // this.profileComponent.editUser = true;
        this.profileComponent.viewUser = false;
        this.profileComponent.displayEditProfileForm = false;
        this.profileComponent.getUserDetails(event.id);
      }
    }
  }

  deactivateUser(event) {
    let requestObject = {
      id: event.id,
      is_inactive: !event.is_inactive,
      // is_pending: event.is_pending
    };
    this.apiService.post(constants.api_end_points.DEACTIVATE_USER, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar(event.is_inactive ? 'User Activated' : 'User Deactivated');
        this.getUserTypes();
      }
    });
  }

  approveUser(event) {
    let requestObject = {
      id: event.id,
      is_pending: false
    };
    this.apiService.post(constants.api_end_points.APPROVE_USER, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('User Approved');
        this.getUserTypes();
      }
    });
  }

  //module
  viewModule(element) {
    if (element.module_type_id == 2 || element.module_type_id == 3) {
      this.viewForms(element)
    } else {
      this.viewTemplates(element)
    }
  }
  //checklists
  viewTemplates(element) {
    this.router.navigate(['module-settings/' + element.id]);
  }

  //IncidentsHazards
  viewForms(element) {
    this.router.navigate(['template-settings/' + element.id + '/' + element.top_template_id]);
  }
  // editModule(element) {
  //   if (this.selectedSegment == 5) {
  //     this.router.navigate(['module-settings/' + element.id + '/2']);
  //   }
  // }

  resetSettings() {
    this.getSettings();
  }

  updateSettings() {
    this.loaderService.showLoader();
    let requestObject = {
      name: this.settingsForm.value.organisationName,
      event_auto_close_days: this.settingsForm.value.eventAutoCloseDays
    };
    this.apiService.post(constants.api_end_points.UPDATE_SETTINGS, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Settings Updated!');
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  setHeightForSegmentContent() {
    let addClassHeight = $('body')[0].clientHeight - 96 - 25;
    return {
      height: addClassHeight + 'px',
      width: this.showAddClass ? '75%' : '100%'
    };
  }

  setHeightForSegmentContainer() {
    setTimeout(() => {
      console.log($('.modules-container'));
      let x = document.getElementsByClassName('modules-container');
      if (x) {
        for (let i = 0; i < x.length; i++) {
          // console.log($(x[i]));
          $(x[i]).css('height', x[i].children[0].scrollHeight + 'px');
        }
      }
    }, 500);
  }

  setHeightForAddSection() {
    let addClassHeight = $(".account-settings-content-class")[0].clientHeight - $("#accounts-tab-bar")[0].clientHeight;
    return {
      height: addClassHeight + 'px'
    }
  }

}
