import { Injectable } from '@angular/core';
import { constants } from '../providers/constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  userDetails: any;
  accountDetails: any;
  modulesList: any = [];
  activeModulesList: any = [];
  unreadNotificationsCount: any = 0;

  constructor(private apiService: ApiService) { }

  setUserDetails(userData = null) {
    if (userData) {
      this.userDetails = userData;
    } else {
      let userDetails = localStorage.getItem('SS-user-details');
      if (userDetails) {
        this.userDetails = JSON.parse(userDetails);
      }
    }
  }

  updateUserDetails() {
    this.getUserDetails();
  }

  getUserDetails() {
    this.apiService
      .get(constants.api_end_points.USER_DETAILS)
      .subscribe((response) => {
        if (response) {
          if (response.code == 200) {
            console.log(response);
            localStorage.setItem(
              "SS-user-details",
              JSON.stringify(response.data)
            );
            this.setUserDetails(response.data);
          } else if (response.code == 401) {
            alert(response.message);
          }
        }
      });
  }

  switchAccount(account) {
    let requestObject = {
      account_id: account.id
    };
    return new Promise((resolve, reject) => {
      this.apiService.post(constants.api_end_points.SET_ACCOUNT, requestObject).subscribe(response => {
        if (response && response.code == 200) {
          localStorage.setItem("SS-user", response.data.token);
          resolve(response);
        }
      })
    })
  }

  setAccountDetails(account = null) {
    if (account) {
      this.accountDetails = account;
      this.getActiveModules(true);
      if (account.user_type_id != 3)
        this.getModules(true);
    } else {
      let accountDetails = localStorage.getItem('account');
      if (accountDetails) {
        this.accountDetails = JSON.parse(accountDetails);
        this.getActiveModules();
        if (this.accountDetails.user_type_id != 3)
          this.getModules();
      }
    }
    this.getNotificationsCount();
  }

  getModules(setActionModuleData = false) {
    this.apiService.get(constants.api_end_points.GET_MODULES).subscribe(response => {
      if (response.code == 200) {
        this.modulesList = response.data;
        this.modulesList.forEach(element => {
          let iconName = element.name.toLowerCase() + '.svg'
          element['icon'] = 'assets/images/icons/' + iconName;
        });
        // if (setActionModuleData) {
        //   let actionModule = this.modulesList.find(x => x.module_type_id == 1);
        //   if (actionModule)
        //     localStorage.setItem('actionModule', JSON.stringify(actionModule));
        // }
      }
    });
  }

  getActiveModules(setActionModuleData = false) {
    this.apiService.get(constants.api_end_points.GET_ACTIVE_MODULES).subscribe(response => {
      if (response.code == 200) {
        response.data.forEach(element => {
          let controlName = element.name.toLowerCase();
          controlName = controlName.replace(/ /g, "_");
          element['value'] = controlName;
          element.templates.forEach(e => {
            e['module_id'] = element.id
          });
        });
        this.activeModulesList = response.data;
        //console.log(this.activeModulesList);
        this.activeModulesList.forEach((module) => {
          if (module.module_type_id == 1 && setActionModuleData)
            localStorage.setItem('actionModule', JSON.stringify(module));
          module.templates.forEach((e, index) => {
            if (index == 0 && module.templates.length == 1) {
              module['single_template_form_id'] = e.forms[0].id;
              module['single_template_name'] = e.name;
              //delete module['templates'];
              //console.log(e.id);
              //console.log(this.activeModulesList.indexOf('templates'));
            }
            ///console.log(this.activeModulesList.single_template_id);
          })
        });
        //console.log(this.activeModulesList);
      }
    });
  }

  nameToInitials(fullName) {
    const namesArray = fullName.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
  }

  getNotificationsCount() {
    this.apiService.get(constants.api_end_points.GET_NOTIFICATIONS_COUNT).subscribe(response => {
      if (response.code == 200) {
        this.unreadNotificationsCount = response.data;
      }
    })
  }

  resetNotificationsCount() {
    this.unreadNotificationsCount = 0;
  }
}
