import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {

  @Input() showVerificationCodeTextbox: boolean = true;
  verificationForm: any;
  @Input() verificationCode: any;
  @Output() apiResponse = new EventEmitter<any>();

  

  constructor(private formBuilder: FormBuilder, private apiService: ApiService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.verificationForm = this.formBuilder.group({
      code: new FormControl(this.verificationCode ? this.verificationCode : null, [Validators.required])
    });
  }

  verifyCode() {
    let requestObject = {};

    if (this.verificationCode) {
      requestObject['type'] = 'link';
      requestObject['value'] = this.verificationCode;
    } else {
      requestObject['type'] = 'code';
      requestObject['value'] = this.verificationForm.value.code;
    }

    this.apiService.post(constants.api_end_points.VERIFY_USER, requestObject).subscribe(response => {
      if (response) {
        this.apiResponse.emit(response);
      }
    })

  }

}
