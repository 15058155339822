import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  @Input() popoverType: any;
  @Input() popoverList: any = [];
  @Input() label: any = 'Disable';

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  editUser() {
    this.popoverCtrl.dismiss('edit');
  }

  deactivateUser() {
    this.popoverCtrl.dismiss('deactivate');
  }

  selectAccount(account) {
    this.popoverCtrl.dismiss(account);
  }

}
