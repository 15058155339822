import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuService } from 'src/app/services/menu.service';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-template-settings-content',
  templateUrl: './template-settings-content.component.html',
  styleUrls: ['./template-settings-content.component.scss'],
})
export class TemplateSettingsContentComponent implements OnInit {

  moduleId: any;
  templateId: any;
  tabId: any;

  modulesData: any = [];
  moduleReferenceName: any;
  moduleReferenceId: any;
  templateReferenceName: any;
  moduleType: any;
  templateData: any = [];
  templateIsEdit: boolean = false;
  isAddForm: any = false;

  formsList: any  = [];
  templatesData: any = [];
  dataSource: any;
  displayColumns: any = ['form_icon', 'name', 'description', 'position','is_inactive'];

  selectedSegment: any = '1';
  settingsForm: any;
  userTypes: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public menuService: MenuService,
    private _snackBar: MatSnackBar,
    private location: Location,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.initializeSettingsForm();
    // this.getUserTypes();
  }

  ionViewDidEnter() {
    this.moduleId = this.router.url.split('/')[2];
    this.templateId = this.router.url.split('/')[3];
    console.log(this.templateId);
    // this.selectedSegment = this.route.snapshot.paramMap.get('tabId') ? this.route.snapshot.paramMap.get('tabId') : '1';
    // this.onSegmentChange(this.selectedSegment);
    this.getForms(); 
    this.getTemplate();
    this.getModuleName();
  }

  initializeSettingsForm(data = null) {
    this.settingsForm = this.formBuilder.group({
      is_inactive: new FormControl((data && data.is_inactive) ? data.is_inactive : false, []),
      templateName: new FormControl((data && data.name) ? data.name : null, [Validators.required]),
      prefix: new FormControl({value: (data && data.prefix) ? data.prefix : null, disabled: true},[Validators.required]),
      description: new FormControl((data && data.description) ? data.description : null, []),
      // userType: new FormControl((data && data.user_modify_type_id) ? data.user_modify_type_id : 1, [Validators.required]),
      access_to_team_lead: new FormControl((data && data.access_to_team_lead) ? data.access_to_team_lead : false, [Validators.required])
    });
  }

  getUserTypes() {
    this.apiService.get(constants.api_end_points.USER_TYPES).subscribe(response => {
      if (response.code == 200) {
        this.userTypes = response.data;
      }
    })
  }

  getForms() {
    this.loaderService.showLoader();
    let requestObject = {
      template_id: Number(this.templateId)
    }
    this.apiService.post(constants.api_end_points.GET_FORMS, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.formsList = response.data;
        this.dataSource = new MatTableDataSource(this.formsList);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!')
    })
  }

  navigateToForm(form) {
    this.router.navigate(['form/' + form.id]);
  }

  // onSegmentChange(event) {
  //   switch (this.selectedSegment) {
  //     case '1': this.getForms(); this.getModuleName(); this.getTemplateName(); break;
  //     case '2': this.getTemplate(); this.getModuleName(); break;
  //   }
  // }

  // navigateToTab(event) {
  //   this.onSegmentChange(this.selectedSegment);
  //   this.router.navigate(['template-settings/' + this.route.snapshot.paramMap.get('moduleId') + '/' + this.route.snapshot.paramMap.get('templateId') + '/' + this.selectedSegment]);
  // }

  changeEditMode() {
    this.templateIsEdit = !this.templateIsEdit;
  }

  getTemplate(templateId = null) {
    if (this.templateId) {
      let id = this.templateId;
      this.initializeSettingsForm();
      let requestObject = {
        template_id: Number(templateId ? templateId : id),
        module_id: Number(this.moduleId)
      };
      this.apiService.post(constants.api_end_points.GET_TEMPLATE, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.templatesData = [];
          this.templatesData.push(response.data);

          this.templateData = response.data;
          this.templateReferenceName = response.data.name;

          this.initializeSettingsForm(this.templatesData[0]);
          this.templateReferenceName = response.data.name;
        }
        else {
          this.apiService.errorSnackBar(response.message);
        }
      })
    }
  }

  updateTemplate() {
    let requestObject = {
      template_id: this.templatesData[0].id,
      module_id: Number(this.moduleId),
      name: this.settingsForm.value.templateName,
      prefix: this.settingsForm.getRawValue().prefix,
      description: this.settingsForm.value.description,
      is_inactive: this.settingsForm.value.is_inactive,
      // type_id: this.settingsForm.value.userType,
      access_to_team_lead: this.settingsForm.value.access_to_team_lead
    };
    console.log(requestObject);
    this.apiService.post(constants.api_end_points.UPDATE_TEMPLATE, requestObject).subscribe(response => {
      if (response.code == 200) {
        console.log(requestObject);
        this.apiService.successSnackBar('Template Updated!');
        this.getTemplate(this.templatesData[0].id);
        this.templateIsEdit = false;
      }
      else  {
        this.apiService.errorSnackBar(response.message);
      }
    })
  }
  navigateToFomBuilder() {
    console.log(this.moduleId, this.templateId);
    this.router.navigate([this.moduleId + '/' + this.templateId + '/form']);
  }

  getModuleName(moduleId = null) {
    if (this.moduleId) {
      let id = this.moduleId;
      let requestObject = {
        id: Number(moduleId ? moduleId : id)
      };
      this.apiService.post(constants.api_end_points.GET_MODULE, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.moduleReferenceName = response.data.name;
          this.moduleReferenceId = response.data.id;
          this.moduleType = response.data.module_type.name;
        }
      })
    }
  }

  displayAddFormButton() {
    if (this.moduleType == 'Incidents' && this.moduleType == 'Hazards') {
      this.isAddForm = true;
    }
  }

  goBack() {
    this.location.back();
    //this.router.navigate(['module-settings/' + this.moduleId]);
  }
}
