
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { FeedDataService } from 'src/app/services/feed-data.service';
import { AddElementComponent } from '../add-element/add-element.component';

@Component({
  selector: 'app-common-feed',
  templateUrl: './common-feed.component.html',
  styleUrls: ['./common-feed.component.scss'],
})
export class CommonFeedComponent implements OnInit {

  selectedSegment: any = 1;
  eventId: any;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private modalCtrl: ModalController,
    public feedDataService: FeedDataService,
    public commonMethodsService: CommonMethodsService
  ) { }

  ngOnInit() {
    // this.feedDataService.getFeedData(4);
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    if (this.eventId)
      this.feedDataService.getFeedData(this.eventId);

  }

  getFeedList() {
    if (this.selectedSegment == 1)
      return this.feedDataService.feedData;
    else
      return this.feedDataService.feedData.filter(x => x.feedType == this.selectedSegment);
  }

  setSegmentValue(value) {
    this.selectedSegment = value;
  }

  openModal() {
    this.presentModal();
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: AddElementComponent,
      componentProps: { type: 4 },
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  displayAddSection() {
    this.router.navigate(['add-action/' + this.eventId])
  }

  formatUserData(data) {
    return {
      id: data.user_id,
      profile_picture: data.profile_picture,
      full_name: data.full_name
    };
  }

  goBack() {
    let currentState = this.location.getState();
    if (currentState['navigationId'] && currentState['navigationId'] > 1) {
      this.location.back();
    } else {
      this.router.navigate(['feed']);
    }
  }

}
