import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { MenuService } from 'src/app/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedDataService } from 'src/app/services/feed-data.service';

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss'],
})
export class AddActionComponent implements OnInit {
  @ViewChild('formTemplate', { read: ViewContainerRef }) template: ViewContainerRef;
  @ViewChild('shortTemplate', { read: TemplateRef }) shortTemplate: TemplateRef<any>;
  @ViewChild('paragraphTemplate', { read: TemplateRef }) paragraphTemplate: TemplateRef<any>;
  @ViewChild('dropdownTemplate', { read: TemplateRef }) dropdownTemplate: TemplateRef<any>;
  @ViewChild('checkboxTemplate', { read: TemplateRef }) checkboxTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { read: TemplateRef }) dateTemplate: TemplateRef<any>;
  @ViewChild('wholeTemplate', { read: TemplateRef }) wholeTemplate: TemplateRef<any>;
  @ViewChild('decimalTemplate', { read: TemplateRef }) decimalTemplate: TemplateRef<any>;
  @ViewChild('currencyTemplate', { read: TemplateRef }) currencyTemplate: TemplateRef<any>;

  // @ViewChild('textTemplate', { read: ViewContainerRef }) textTemplate: ViewContainerRef;
  // @ViewChild('dropdownTemplate', { read: ViewContainerRef }) dropdownTemplate: ViewContainerRef;
  // @ViewChild('checkboxTemplate', { read: ViewContainerRef }) checkboxTemplate: ViewContainerRef;
  // @ViewChild('formTemplate', { read: ViewContainerRef }) template: ViewContainerRef;
  @Input() eventId: any;
  @Input() elementId: any;
  @Input() isRelatedEvent: any;
  @Input() fieldAnswerId: any;
  @Input() addActionToNewField: any;
  @Input() formEventId: any;
  @Input() formModuleId: any;
  @Input() formTemplateId: any;
  @Output() updateView: any = new EventEmitter<any>();
  @Output() cancelEvent: any = new EventEmitter<any>();
  @Output() addActionToEvent: any = new EventEmitter<any>();
  @Output() openAddActionSection: any = new EventEmitter<any>();

  actionForm: FormGroup;
  editAction: boolean;
  elementData: any;
  formFields: any = [];
  responseData: any;

  formRendered: any;
  formDetails: any;
  formData: any;
  pages: any = [];

  constructor(
    private router: Router,
    private platform: Platform,
    private location: Location,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private activatedRoute: ActivatedRoute,
    private feedDataService: FeedDataService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // this.isRelatedEvent = false;
    this.activatedRoute.params.subscribe(res => {
      if (res && res.id)
        this.elementId = res.id;
      if (res && res.eventId) {
        this.isRelatedEvent = true;
        this.eventId = res.eventId;
      }
    });
    this.initializeAddActionsForm();
    if (this.elementId)
      this.getElementData();
    this.getActionForm();
  }

  initializeAddActionsForm(data = null, disable = false) {
    this.actionForm = this.formBuilder.group({
      title: new FormControl({ value: (data && data.title) ? data.title : null, disabled: disable }, [Validators.required]),
      dueDate: new FormControl({ value: (data && data.dueDate) ? data.dueDate : null, disabled: disable }, [Validators.required]),
      owner: new FormControl({ value: (data && data.assignee) ? data.assignee : null, disabled: disable }, [Validators.required]),
      description: new FormControl({ value: (data && data.description) ? data.description : null, disabled: disable }, [Validators.required]),
      completed: new FormControl({ value: (data && data.completed) ? data.completed : false, disabled: disable }, [])
    });
  }

  // getTypeLists(data, type) {
  //   let requestObject = {
  //     type: type
  //   };

  //   this.apiService.post(constants.api_end_points.GET_TYPE_LISTS, requestObject).subscribe(response => {
  //     if (response && response.code == 200) {
  //       if (response.data.length > 0) {
  //         data['options'] = response.data;
  //       }
  //     }
  //   });
  // }

  getActionForm(formId = null) {
    let actionModule = localStorage.getItem('actionModule');
    if (actionModule)
      actionModule = JSON.parse(actionModule);
    let requestObject = {
      form_id: formId ? formId : actionModule['templates'][0].forms[0].id
    };
    if (formId)
      requestObject['event_id'] = Number(this.elementId);
    let url = formId ? constants.api_end_points.GET_FILLED_FORM : constants.api_end_points.GET_FORM_FIELDS;
    this.apiService.post(url, requestObject).subscribe(response => {
      if (response.code == 200) {
        console.log(response);
        if (response.data.pages)
          this.pages = response.data.pages;
        // this.initializeSettingsForm(response.data);
        this.formData = response.data;
        console.log(this.formData);
        this.pages.forEach(page => {
          page.sections.forEach((section, sectionIndex) => {
            section['collapsed'] = false;
            section['show_section'] = true;
            let sectionForm;
            let formObject = {};
            section.fields.forEach(field => {
              let controlName = field.label.toLowerCase();
              controlName = controlName.replace(/ /g, "_");
              field['controlName'] = controlName;
              if (field.fieldDetails.category == 'choice' && (!field.list || (field.list && !field.list.list_values))) {
                field['list'] = {
                  list_values: []
                };
                let choiceTypes = ['dropdown', 'checkbox', 'radio'];
                if (!choiceTypes.includes(field.fieldDetails.type)) {
                  // field.list.list_values = this.getTypeLists(field.fieldDetails.type);
                  this.getTypeLists(field.fieldDetails.type).then(value => {
                    field.list.list_values = value;
                  })
                }
              }
              if (field.fieldDetails.style == 'datetime' && field.field_answer && field.field_answer[field.fieldDetails.style]) {
                field.field_answer[field.fieldDetails.style] = new Date(field.field_answer[field.fieldDetails.style]);
              }
              formObject[field.controlName] = new FormControl((field.field_answer && field.field_answer[field.fieldDetails.style]) ? field.field_answer[field.fieldDetails.style] : null, field.is_required ? Validators.required : []);
              // formObject[field.controlName] = new FormControl(null, field.is_required ? Validators.required : []);
              if (formId)
                formObject[field.controlName].disable();
              if (field.fieldDetails.type == 'whole')
                formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]{0,20}"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]{0,20}")]);
              formObject[field.controlName].updateValueAndValidity();
              if (field.fieldDetails.type == 'decimal')
                formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]+(\.[0-9]{1,6})?$"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]+(\.[0-9]{0,6})?$")]);
              formObject[field.controlName].updateValueAndValidity();
              if (field.fieldDetails.type == 'currency')
                formObject[field.controlName].setValidators([Validators.pattern("^-?[0-9]+(\.[0-9]{1,2})?$"), field.is_required ? Validators.required : Validators.pattern("^-?[0-9]+(\.[0-9]{1,2})?$")]);
              formObject[field.controlName].updateValueAndValidity();
            });
            section['sectionForm'] = this.formBuilder.group(formObject);
          });
        });
        this.formDetails = {
          pages: this.pages,
          formData: this.formData,
        };
        if (this.isRelatedEvent)
          this.formDetails['eventId'] = this.eventId;
        if (formId)
          this.formDetails['eventId'] = this.elementId;
        this.formRendered = true;
        // this.predefinedDropdownList = response.data;
        console.log(this.pages);
      }
    })


    // this.apiService.get(constants.api_end_points.GET_ACTION_FORM).subscribe(response => {
    //   if (response.code == 200) {
    //     console.log(response.data.templates[0].form.pages[0].sections[0].fields);
    //     this.responseData = response.data;
    //     this.formRendered = true;
    //     this.formFields = response.data.templates[0].form.pages[0].sections[0].fields;
    //     this.renderForm(this.formFields);
    //   }
    // })
  }

  getTypeLists(type) {
    let requestObject = {
      type: type
    };
    return new Promise((resolve, reject) => {
      this.apiService.post(constants.api_end_points.GET_TYPE_LISTS, requestObject).subscribe(response => {
        if (response && response.code == 200) {
          if (response.data.length > 0) {
            resolve(response.data);
          }
          reject();
        }
      }, err => {
        reject();
      });
    })
  }

  renderForm(formData) {
    let template;
    // this.actionForm = this.formBuilder.group({});
    let formObject = {};
    formData.forEach(element => {
      let controlName = element.label.toLowerCase();
      controlName = controlName.replace(/ /g, "_");
      element['controlName'] = controlName;

      switch (element.fieldDetails.type) {
        case 'short':
          template = this.shortTemplate
          break;
        case 'paragraph':
          template = this.paragraphTemplate
          break;
        case 'whole':
          template = this.wholeTemplate;
          break;

        case 'decimal':
          template = this.decimalTemplate;
          break;

        case 'currency':
          template = this.currencyTemplate;
          break;

        case 'date':
          template = this.dateTemplate;
          break;

        case 'time':
          template = this.dateTemplate;
          break;

        case 'datetime':
          template = this.dateTemplate;
          break;

        case 'dropdown':
          template = this.dropdownTemplate;
          break;

        case 'radio':
          template = this.dropdownTemplate;
          break;

        case 'checkbox':
          template = this.checkboxTemplate;
          break;

        case 'site':
          template = this.dropdownTemplate;
          if (element.fieldDetails.internal_note.toLowerCase().includes('foreign')) {
            // this.getTypeLists(element.fieldDetails, element.fieldDetails.type);
          }
          break;

        case 'business_unit':
          template = this.dropdownTemplate;
          if (element.fieldDetails.internal_note.toLowerCase().includes('foreign')) {
            // this.getTypeLists(element.fieldDetails, element.fieldDetails.type);
          }
          break;

        case 'user':
          template = this.dropdownTemplate;
          if (element.fieldDetails.internal_note.toLowerCase().includes('foreign')) {
            // this.getTypeLists(element.fieldDetails, element.fieldDetails.type);
          }
          break;

        //case 'image':
        //  break;

      }

      if (template)
        formObject[element.controlName] = new FormControl(null, element.is_required ? Validators.required : []);
      // this.template.createEmbeddedView(template, { $implicit: element });
      if (element.fieldDetails.type == 'whole')
        formObject[element.controlName].setValidators([Validators.pattern("^[0-9]{0,20}"), element.is_required ? Validators.required : Validators.pattern("^[0-9]{0,20}")]);
      formObject[element.controlName].updateValueAndValidity();
      if (element.fieldDetails.type == 'decimal')
        formObject[element.controlName].setValidators([Validators.pattern("^[0-9]+(\.[0-9]{1,6})?$"), element.is_required ? Validators.required : Validators.pattern("^[0-9]+(\.[0-9]{0,6})?$")]);
      formObject[element.controlName].updateValueAndValidity();
      if (element.fieldDetails.type == 'currency')
        formObject[element.controlName].setValidators([Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$"), element.is_required ? Validators.required : Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]);
      formObject[element.controlName].updateValueAndValidity();

      this.actionForm = this.formBuilder.group(formObject);
      console.log(this.actionForm);

    });
  }

  getElementData() {
    // this.elementData = {
    //   id: 1,
    //   title: 'ACT-192 - Remove Damaged Signage',
    //   dueDate: '24-Mar-2020',
    //   description: 'Action Description',
    //   assignee: 'Joel Kininmonth',
    //   status: 'Not Started',
    //   dateClosed: '24-Mar-2020',
    //   completed: true
    // };
    // this.initializeAddActionsForm(this.elementData, true);

    let requestObject = {
      id: Number(this.elementId)
      // id: 1
    };
    // this.eventId = 1;
    this.apiService.post(constants.api_end_points.GET_EVENT, requestObject).subscribe(response => {
      if (response.code == 200) {
        // this.eventDetails = response.data;
        // this.getRegisters();
        this.getActionForm(response.data.template.forms[0].id);
        this.feedDataService.getFeedData(this.elementId);
      }
    })
  }

  editActionForm() {
    this.editAction = true;
    this.formDetails.pages.forEach(page => {
      page.sections.forEach(section => {
        section.fields.forEach(field => {
          section.sectionForm.get(field.controlName).enable();
        });
      });
    });
    // this.initializeAddActionsForm(this.elementData, false);
  }

  displayAddSection() {
    if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      this.router.navigate(['add-action/' + this.elementId]);
    } else {
      this.openAddActionSection.emit(true);
    }
  }

  addAction() {
    let requestObject = {
      module_id: this.responseData.id,
      template_id: this.responseData.templates[0].id,
      form_id: this.responseData.templates[0].form.id,
      page_id: this.responseData.templates[0].form.pages[0].id,
      section_id: this.responseData.templates[0].form.pages[0].sections[0].id,
      assigned_user_id: this.menuService.userDetails.id,
      position: 1
    };
    let answers = [];
    this.formFields.forEach(element => {
      let answerObject = {
        field_id: element.id,
        form_field_metadata: element.form_field_metadata_id,
        is_inactive: false,
        position: element.position
      };
      answerObject[element.fieldDetails.style] = this.actionForm.value[element.controlName];
      // if (answerObject.field_id == 20)
      //   answerObject[element.fieldDetails.style] = 5;
      answers.push(answerObject);
    });
    requestObject['answers'] = answers;
    console.log(requestObject);

    this.apiService.post(constants.api_end_points.ADD_ACTION, requestObject).subscribe(response => {
      if (response && response.code == 200) {
        this.apiService.successSnackBar('Action Added');
        this.actionForm.reset();
        if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
          this.router.navigate(['actions']);
        } else {
          // this.updateView.emit(true);
        }
      }
      else {
        this.apiService.errorSnackBar(response.message);
      }
    });
  }

  addActionCustomDialog(event) {
    this.addActionToEvent.emit(event);
  }

  updateACtionsList() {
    this.updateView.emit();
  }

  cancel() {
    if (!this.editAction) {
      this.elementId = null;
      this.actionForm.reset();
      if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
        if (!this.isEventPage())
          this.location.back();
        else
          this.cancelEvent.emit();
        // this.router.navigate(['account-settings']);
      } else {
        this.cancelEvent.emit(true);
      }
    } else {
      this.editAction = false;
      this.getElementData();
      this.initializeAddActionsForm(this.elementData, true);
    }
  }

  getStlyesForAddContainer() {
    return {
      'padding-bottom': '54px',
      'position': 'relative'
    };
  }

  isEventPage() {
    if (this.router.url.includes('event-details') || this.router.url.includes('view-form'))
      return true;
  }

  goBack() {
    let currentState = this.location.getState();
    if (currentState['navigationId'] && currentState['navigationId'] > 1) {
      this.location.back();
    } else {
      this.router.navigate(['actions']);
    }
  }

}
