export let constants: any = {
    api_end_points: {
        COUNTRIES: 'timeZones/getAllCountries',
        TIME_ZONES: 'timeZones/getTimeZones',
        UPDATE_TIME_ZONE: 'timeZones/updateTimeZones',
        REGISTER: 'user/register',
        LOGIN: 'user/login',
        VERIFY_USER: 'user/verify',
        REQUEST_RESET_PASSWORD_LINK: 'user/requestResetPasswordLink',
        RESET_PASSWORD: 'user/resetPassword',
        USER_DETAILS: 'user/getDetails',
        USER_INFO: 'user/getUserDetails',
        UPDATE_PROFILE: 'user/updateDetails',
        CHANGE_PASSWORD: 'user/changePassword',
        GET_INVITE_LINK: 'user/getInviteLinkUrl',
        DELETE_INVITE_LINK: 'user/deleteInviteLinkUrl',
        SET_INVITE_LINK_STATUS: 'user/changeInviteUrlStatus',
        CHECK_LINK_VALIDITY: 'user/checkLinkValidity',
        USER_ACCOUNTS: 'user/getUserAccounts',
        SET_ACCOUNT: 'user/userAccountSelection',
        USER_TYPES: 'user/getUserTypes',
        USERS: 'user/getTeamMembers',
        ADD_USER: 'user/addUser',
        APPROVE_USER: 'user/approveUsers',
        GET_TEAM_MEMBER_DETAILS: 'user/getTeamMemberDetail',
        UPDATE_TEAM_MEMBER_DETAILS: 'user/updateTeamMemberDetails',
        DEACTIVATE_USER: 'user/updateTeamMemberStatus',
        BUSINESS_UNITS: 'user/getAllBusinessUnits',
        GET_PARENT_UNITS: 'user/getParentBusinessUnits',
        ADD_BUSINESS_UNIT: 'user/addBusinessUnit',
        GET_BUSINESS_UNIT: 'user/getSingleOrganizationDetail',
        UPDATE_BUSINESS_UNIT: 'user/editBusinessUnit',
        SITES: 'user/getAllSites',
        ADD_SITE: 'user/addSite',
        GET_PARENT_SITES: 'user/getAllParentSites',
        GET_SITE: 'user/getSingleSiteDetail',
        UPDATE_SITE: 'user/editSite',
        GET_SETTINGS: 'user/getAccountName',
        UPDATE_SETTINGS: 'user/updateAccountName',
        GET_ACTIVE_MODULES: 'user/getActiveModules',
        GET_MODULES: 'user/getAllModules',
        GET_MODULE: 'user/getSingleModuleData',
        UPDATE_MODULE: 'user/editModule',
        ADD_TEMPLATE: 'templates/addTemplateDetails',
        GET_TEMPLATES: 'templates/getAllTemplateDetails',
        GET_TEMPLATE: 'templates/getSingleTemplateDetails',
        UPDATE_TEMPLATE: 'templates/updateTemplateDetails',
        GET_ACTIONS_LIST: 'actions/getActionList',
        GET_ACTION_FORM: 'actions/getFormFields',
        ADD_ACTION: 'actions/addAction',
        ADD_FORM: 'formBuilder/insertForm',
        UPDATE_FORM: 'formBuilder/editForm',
        SAVE_FORM: 'formBuilder/saveForm',
        UPDATE_FILLED_FORM: 'events/updateFormDetails',
        SAVE_FORM_SETTINGS: 'formBuilder/updateConditionalFormSettings',
        GET_RESERVED_DROPDOWNS: 'formBuilder/getFormSettingsDropDowns',
        GET_FORMS: 'formBuilder/getformLists',
        GET_FORM: 'formBuilder/getformFields',
        GET_FILLED_FORM: 'formBuilder/fetchFormDetails',
        GET_FIELD_TYPES: 'formBuilder/getDataTypes',
        GET_FORM_FIELDS: 'formBuilder/getformFields',
        GET_TYPE_LISTS: 'formBuilder/getforeignLists',
        GET_DROPDOWN_LIST: 'formBuilder/getDropDowns',
        ADD_LIST: 'lists/addList',
        UPDATE_LIST: 'lists/updateList',
        GET_LIST: 'lists/getsingleList',
        GET_LISTS: 'lists/getAllList',
        GET_REGISTERS: 'moduleRegister/getModulesRegister',
        GET_EVENT: 'events/getEventDetails',
        GET_MY_ITEMS: 'events/fetchMyItems',
        GET_FEED: 'activityFeed/getFeed',
        GET_ALERTS_SETTINGS: 'userSettings/getAlertSettings',
        UPDATE_ALERT_SETTINGS: 'userSettings/updateAlertSettings',
        LOGOUT: 'user/logout',
        GET_REPORT_SINGLE_MODULE: 'reports/getSingleModuleDashboardReport',
        GET_REPORT_ALL_MODULE: 'reports/getAllModuleDashboardReport',
        GET_NOTIFICATIONS_LIST: 'events/fetchNotifications',
        GET_NOTIFICATIONS_COUNT: 'events/fetchNotificationsCount',
        MARK_NOTIFICATIONS_AS_READ: 'events/markNotificationsRead',
        UPLOAD_FILE: 'files/uploadAttachment',
        REMOVE_ATTACHMENT: 'files/deleteAttachment',
        ADD_ATTACHMENT: 'activityFeed/addComment',
        ADD_COMMENT: 'activityFeed/addComment',
        ADD_ATTACHMENT_TO_FORM: 'formBuilder/addAttachmentToForm',
        DELETE_COMMENT: 'activityFeed/deleteComment',
        ADD_LIKE: 'activityFeed/addLike',
        GET_FORM_OWNERS: 'user/getFromOwners',
        GET_COMMON_FEED: 'events/getCommonFeed',
        ADD_WATCHER: 'events/addWatchers',
        DOWNLOAD_FILE: 'activityFeed/downLoadFile'
        },
    hideMenuForWeb: false
};
