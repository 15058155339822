import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {

  sliderOptions: any = {
    initialSlide: 0,
    slidesPerView: 1,
    speed: 400
  }

  sliderData: any = [
    {
      id: 0,
      imageLink: 'assets/images/1.png',
      title: 'Select your Modules',
      description: 'Choose from a wide range of modules to meet your needs and select from a wide range of templates'
    },
    {
      id: 1,
      imageLink: 'assets/images/2.PNG',
      title: 'Build and replace your old forms',
      description: 'Build, customise and manage entire workflows for your business’s processes and procedures'
    },
    {
      id: 2,
      imageLink: 'assets/images/3.PNG',
      title: 'Keep up to date with all the events in your organisation',
      description: 'Improve your safety culture with positive feedback for completed tasks and view all completed activities in a single place'
    },
    {
      id: 3,
      imageLink: 'assets/images/4.PNG',
      title: 'GAIN INSIGHT into your incidents',
      description: 'Turn data into intelligence. Analyze compliance issues to prevent them occurring in the future.'
    }
  ];

  constructor() { }

  ngOnInit() {}

}
