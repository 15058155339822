import { Component } from '@angular/core';

import { ActionSheetController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuService } from './services/menu.service';
import { TourService } from './services/tour.service';
import * as $ from 'jquery';
import { CommonMethodsService } from './services/common-methods.service';
import { LoaderService } from './services/loader.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddMenuComponent } from './common-components/add-menu/add-menu.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  showHeaderForRoutes: any = ['/user-settings', '/account-settings', '/module-settings', '/module-settings/2', '/form-builder', '/actions', '/feed', '/help', '/notifications'];
  hideHeaderForRoutes: any = ['/login', '/register', '/landing-page'];

  showActionSheet: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public menuService: MenuService,
    public loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private commonMethodsService: CommonMethodsService,
    public actionSheetController: ActionSheetController,
    public tourService: TourService,
    private bottomSheet: MatBottomSheet
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString('#2196f3');
      this.splashScreen.hide();
      setTimeout(() => {
        if (!(this.router.url.includes('register') || this.router.url.includes('login'))) {
          this.menuService.setUserDetails();
          this.menuService.setAccountDetails();
        }
      }, 500);
      // console.log(this.router.url);
      // if (this.router.url.includes('register')) {
      //   this.activatedRoute.params.subscribe(res => {
      //     if (!res.code) {
      // if (this.platform.is('pwa') || this.platform.is('mobileweb')) {
      //   this.router.navigate(['landing-page']);
      // } else {
      //   this.router.navigate(['login']);
      // }
      //     }
      //   })
      // } else {
      //   if (this.platform.is('pwa') || this.platform.is('mobileweb')) {
      //     // this.router.navigate(['landing-page']);
      //   } else {
      //     this.router.navigate(['login']);
      //   }
      // }
      // if (this.platform.is('pwa') || this.platform.is('mobileweb')) {
      //   // this.router.navigate(['landing-page']);
      // } else {
      //   this.router.navigate(['login']);
      // }

      this.router.events.subscribe(res => {
        // console.log(res);
        if (res instanceof NavigationEnd) {
          let id = this.router.url.split("/")[2];
          this.commonMethodsService.setSegmentId(id);
        }
      })
    });
  }

  showHeader() {
    if (this.router.url.includes('module-settings') || this.router.url.includes('account-settings') || this.router.url.includes('user-settings') || this.router.url.includes('module-listing') || this.router.url.includes('event-details')) {
      return true;
    }
    if (this.router.url.includes('template-settings')) {
      return true;
    }
    if (this.router.url.includes('help')) {
      return true;
    }
    if (this.router.url.includes('form')) {
      return true;
    }
    if (this.router.url.includes('my-items')) {
      return true;
    }
    if (this.showHeaderForRoutes.includes(this.router.url)) {
      return true;
    }
    return false;
  }

  showBottomPane() {
    let width = $('body')[0].clientWidth;
    // console.log($('body')[0].clientWidth);
    let showPane;

    let routes = ['account-settings', 'user-settings', 'my-items', 'feed', 'notifications', 'actions', 'help', 'module-listing'];

    for (let i = 0; i < routes.length; i++) {
      if (this.router.url.includes(routes[i]) && !this.router.url.includes('add') && !this.router.url.includes('common-feed')) {
        showPane = true;
        break;
      }
    }

    if (width > 1024 || !showPane) {
      return false;
    } else {
      return true;
    }
  }

  presentActionSheet() {
    // this.showActionSheet = true;
    this.bottomSheet.open(AddMenuComponent);
  }

  async presentActionSheet1() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Albums',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Delete',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          console.log('Delete clicked');
        }
      }, {
        text: 'Share',
        icon: 'share',
        handler: () => {
          console.log('Share clicked');
        }
      }, {
        text: 'Play (open modal)',
        icon: 'caret-forward-circle',
        handler: () => {
          console.log('Play clicked');
        }
      }, {
        text: 'Favorite',
        icon: 'heart',
        handler: () => {
          console.log('Favorite clicked');
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }
}
