import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentUser = localStorage.getItem("SS-user");
    if (state.url.includes('register') || state.url.includes('reset-password') || state.url.includes('/login') || state.url.includes('/landing-page')) {
      if (currentUser) {
        this.router.navigate(['feed']);
        return false;
      } else {
        return true;
      }
    } else {
     
      if (currentUser) {
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    }
  }

}
