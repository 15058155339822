import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController, PopoverController } from '@ionic/angular';
import { PopoverComponent } from 'src/app/common-components/popover/popover.component';
import { constants } from "src/app/providers/constants";
import { ApiService } from "src/app/services/api.service";
import { MenuService } from "src/app/services/menu.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from "src/app/services/loader.service";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  changePasswordForm: FormGroup;

  resetPasswordForm: any;
  resetPasswordToken: any;

  showPassword: any;

  showLogin: any = true;
  showResetPassword: any;
  showChangePassword: any;
  showRequestResetPassword: any;

  userEmailAddress: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private loaderService: LoaderService,
    private popoverController: PopoverController,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('code')) {
      let code = this.route.snapshot.paramMap.get('code');
      this.activateUser(code);
    }
    this.initializeForm();
    this.intializeChangePasswordForm();
    this.setRememberMe();
  }

  ionViewDidEnter() {
    this.showLogin = true;
    this.showRequestResetPassword = false;
    this.showChangePassword = false;
    this.initializeForm();
    this.intializeChangePasswordForm();
    this.setRememberMe();
  }

  activateUser(code) {
    let requestObject = {
      type: 'link',
      value: code,
      isAddedByAdmin: true
    };
    this.apiService.post(constants.api_end_points.VERIFY_USER, requestObject).subscribe(response => {
      if (response) {
        if (response.code == 200) {
          this.apiService.successSnackBar('User activated! Please login to continue.')
          this.router.navigate(['login']);
        }
      }
    })
  }

  initializeForm() {
    this.loginForm = this.formBuilder.group({
      emailId: new FormControl(null, [Validators.required, Validators.email]),
      password: [null, Validators.compose([Validators.required])],
      rememberMe: new FormControl(false),
    });
  }

  intializeChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$")]),
      // confirmNewPassword: new FormControl(null, [Validators.required])
    });
  }

  getTitle() {
    return this.showRequestResetPassword ? 'Reset Password' : (this.showChangePassword ? 'Change Password' : 'Login');
  }

  setRememberMe() {
    if (JSON.parse(localStorage.getItem('rememberMe')) !== null) {
      this.loginForm.patchValue({
        emailId: localStorage.getItem('username'),
        rememberMe: JSON.parse(localStorage.getItem('rememberMe')
        )
      })
    }
  }


  login() {
    this.loaderService.showLoader();
    let requestObject = {
      email: this.loginForm.value.emailId,
      password: this.loginForm.value.password,
    };

    this.apiService
      .post(constants.api_end_points.LOGIN, requestObject)
      .subscribe((response) => {
        if (response) {
          if (response.code == 200) {
            this.apiService.autoLoggedOut = false;
            if (this.loginForm.value.rememberMe == true) {
              localStorage.setItem('username', this.loginForm.value.emailId);
              localStorage.setItem('rememberMe', JSON.stringify(this.loginForm.value.rememberMe));
              this.loginForm.reset();
              localStorage.setItem("SS-user", response.data.token);
            } else {
              localStorage.setItem('rememberMe', JSON.stringify(false));
              this.loginForm.reset();
              localStorage.setItem("SS-user", response.data.token);
            }
            if (response.data.isNewUser) {
              this.showLogin = false;
              this.showChangePassword = true;
              this.loaderService.hideLoader();
            } else {
              setTimeout(() => {
                // this.getUserDetails();
              }, 50);
              // this.router.navigate(["user-settings/1"]);
              // this.getUserAccounts();
              if (response.data.noOfAccounts && response.data.noOfAccounts > 1) {
                this.loaderService.hideLoader();
                this.navCtrl.navigateRoot('account-selection');
              } else {
                this.getUserAccounts().then(() => {
                  this.getUserDetails().then(() => {
                    this.loaderService.hideLoader();
                    this.router.navigate(["feed"]);
                  })
                })
              }
            }
          } else if (response.code == 401) {
            this.loaderService.hideLoader();
            this.apiService.errorSnackBar(response.message);
          } else {
            this.loaderService.hideLoader();
            this.apiService.errorSnackBar(response.message);
          }
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar("Invalid username or password, please try again with the correct details");
        }
      }, err => {
        this.loaderService.hideLoader();
      });
  }

  getUserDetails() {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(constants.api_end_points.USER_DETAILS)
        .subscribe((response) => {
          if (response) {
            if (response.code == 200) {
              console.log(response);
              localStorage.setItem(
                "SS-user-details",
                JSON.stringify(response.data)
              );
              this.menuService.setUserDetails(response.data);
              resolve();
            } else if (response.code == 401) {
              this.apiService.errorSnackBar(response.message);
              reject();
            }
          }
        });
    })
  }

  changePassword() {
    let requestObject = {
      password: this.changePasswordForm.value.oldPassword,
      newPassword: this.changePasswordForm.value.newPassword
    }
    this.apiService.post(constants.api_end_points.CHANGE_PASSWORD, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Password Changed!');
        this.getUserDetails();
        this.router.navigate(["feed"]);
        // this.commonMethodsService.logout();
        // this.router.navigate(['login']);
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    })
  }

  requestResetPasswordLink() {
    if (!this.userEmailAddress) {
      alert('Please Enter Email Address');
      return;
    }
    let requestObject = {
      email: this.userEmailAddress
    };
    this.apiService.post(constants.api_end_points.REQUEST_RESET_PASSWORD_LINK, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.showRequestResetPassword = false;
        this.showLogin = true;
        this.userEmailAddress = '';
        this.apiService.successSnackBar('Please reset your password using link sent to your mail');
      } else {
        this.showRequestResetPassword = false;
        this.showLogin = true;
        this.userEmailAddress = '';
        this.apiService.successSnackBar('If this user exists, we have sent you a password reset email');
      }
    })
  }

  getUserAccounts() {
    return new Promise((resolve, reject) => {
      this.apiService.get(constants.api_end_points.USER_ACCOUNTS).subscribe(response => {
        if (response.code == 200) {
          // this.presentPopover(response.data);
          localStorage.setItem('account', JSON.stringify(response.data[0]));
          this.menuService.setAccountDetails(response.data[0]);
          resolve();
        } else {
          reject();
        }
      })
    })
  }

  async presentPopover(popoverList) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      componentProps: { popoverList: popoverList, popoverType: true },
      // event: ev,
      mode: "md",
      translucent: true,
      backdropDismiss: false
    });

    popover.onDidDismiss().then((result) => {
      if (result && result.data) {
        // switch (result.data) {
        //   case 'edit': this.editUser.emit(e); break;
        //   case 'deactivate': this.deactivateUser.emit(e); break;
        // }
        // console.log(result);
        localStorage.setItem('account', JSON.stringify(result.data));
        this.router.navigate(["feed"]);
      }
    });

    return await popover.present();
  }
}
