import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/services/loader.service';

export interface DialogData {
  dialogTitle: any,
  buttonTitle: any;
  ownersList: any;
  eventId: any;
  fieldId: any;
  formId: any;
  isComplete: any;
  type: any;
  moduleId: any;
  templateId: any;
  addActionToNewField: any;
}

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'],
})


export class CustomDialogComponent implements OnInit {

  commentInput: any;
  fileTitle: any;
  ownerId: any;
  file: any;
  listForm: FormGroup;
  listItems: any = [{
    label: '',
    value: ''
  }];
  colourData: any = [
    { name: "Red", value: "Red", rgbValue: "#F44336" },
    { name: "Pink", value: "Pink", rgbValue: "#E91E63" },
    { name: "Purple", value: "Purple", rgbValue: "#9C27B0" },
    { name: "Deep Purple", value: "DeepPurple", rgbValue: "#673AB7" },
    { name: "Indigo", value: "Indigo", rgbValue: "#5C6BC0" },
    { name: "Blue", value: "Blue", rgbValue: "#42A5F5" },
    { name: "Light Blue", value: "LightBlue", rgbValue: "#03A9F4" },
    { name: "Cyan", value: "Cyan", rgbValue: "#00BCD4" },
    { name: "Teal", value: "Teal", rgbValue: "#009688" },
    { name: "Green", value: "Green", rgbValue: "#4CAF50" },
    { name: "Light Green", value: "LightGreen", rgbValue: "#8BC34A" },
    { name: "Lime", value: "Lime", rgbValue: "#CDDC39" },
    { name: "Yellow", value: "Yellow", rgbValue: "#FFEB3B" },
    { name: "Amber", value: "Amber", rgbValue: "#FFC107" },
    { name: "Orange", value: "Orange", rgbValue: "#FF9800" },
    { name: "Deep Orange", value: "DeepOrange", rgbValue: "#FF5722" },
    { name: "Brown", value: "Brown", rgbValue: "#795548" },
    { name: "Gray", value: "Gray", rgbValue: "#9E9E9E" },
    { name: "Blue Gray", value: "BlueGray", rgbValue: "#607D8B" },
  ];

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    console.log(this.data);
    this.initializeAddListForm();
  }

  initializeAddListForm(data = null) {
    this.listForm = this.formBuilder.group({
      name: new FormControl((data && data.name) ? data.name : null, [Validators.maxLength(40), Validators.required]),
      // description: new FormControl((data && data.description) ? data.description : null, [Validators.maxLength(255)]),
      is_scored: new FormControl((data && data.is_scored) ? data.is_scored : false, [])
    });
  }

  uploadFile(event) {
    this.loaderService.showLoader();
    console.log(event);
    let requestObject = new FormData();
    requestObject.set('file', event);
    this.apiService.fileUpload(constants.api_end_points.UPLOAD_FILE, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.file = response.data;
        this.loaderService.hideLoader();
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    });
  }

  add(params = null) {
    switch (this.data['type']) {
      case 'list': this.addList(); break;
      case 'owner': this.addOwner(); break;
      case 'comment': this.addComment(); break;
      case 'attachment': this.addAttachment(); break;
    }
  }

  addOwner() {
    this.loaderService.showLoader();
    let requestObject = {
      event_id: Number(this.data.eventId),
      assigned_user_id: this.ownerId
    };
    if (this.data.formId)
      requestObject['form_id'] = Number(this.data.formId);
    this.apiService.post(constants.api_end_points.ADD_WATCHER, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Watcher Added!');
        this.dialogRef.close('add');
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    });
  }

  addComment() {
    this.loaderService.showLoader();
    if (this.data.isComplete) {
      let requestObject = {
        event_id: Number(this.data.eventId),
        title: this.commentInput,
        type: 'Comment',
        field_id: this.data.fieldId,
        form_id: this.data.formId
      };
      this.apiService.post(constants.api_end_points.ADD_COMMENT, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.loaderService.hideLoader();
          this.apiService.successSnackBar('Comment Added!');
          this.commentInput = '';
          this.dialogRef.close('comment');
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar(response.message);
        }
      }, err => {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar('Something Went Wrong!');
      })
    } else {
      this.addAttachmentToForm('Comment', this.commentInput);
    }
  }

  addAttachment() {
    this.loaderService.showLoader();
    let extension = this.file.filename.split('.')[this.file.filename.split('.').length - 1];
    if (this.data.isComplete) {
      let requestObject = {
        type: 'File',
        title: this.fileTitle ? this.fileTitle : this.file.filename,
        event_id: Number(this.data.eventId),
        file_name: this.file.url,
        file_extension: extension ? extension : '',
        file_size: this.file.size,
        form_id: this.data.formId,
        field_id: this.data.fieldId
      };
      this.apiService.post(constants.api_end_points.ADD_COMMENT, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.loaderService.hideLoader();
          this.apiService.successSnackBar('Attachment Added!');
          this.fileTitle = '';
          this.dialogRef.close('attachment');
        } else {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar(response.message);
        }
      }, err => {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar('Something Went Wrong!');
      })
    } else {
      let params = {
        file_name: this.file.url,
        file_extension: extension ? extension : '',
        file_size: this.file.size
      };
      this.addAttachmentToForm('File', this.fileTitle ? this.fileTitle : this.file.filename, params);
    }
  }

  addAttachmentToForm(type, title, params = null) {
    let requestObject = {
      module_id: this.data.moduleId,
      template_id: this.data.templateId,
      // type: 'Comment',
      // field_id: this.data.fieldId,
      "form_type": "Form",
      form_id: this.data.formId,
      attachmentDetails: [
        {
          attachment_type: type,
          title: title
        }
      ]
    };

    if (params) {
      requestObject.attachmentDetails[0] = Object.assign(requestObject.attachmentDetails[0], params);
    }


    if (this.data.eventId)
      requestObject['event_id'] = Number(this.data.eventId)

    this.apiService.post(constants.api_end_points.ADD_ATTACHMENT_TO_FORM, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.apiService.successSnackBar('Comment Added!');
        this.dialogRef.close({
          type,
          eventId: response.data[0].event_id,
          title: title,
          attachmentId: response.data[0].attachment_id
        });
        title = '';
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  addAction(event) {
    this.dialogRef.close(event);
  }

  drop(event: CdkDragDrop<string[]>, list) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  onFocus(event, index) {
    if (!event.target.value && index == (this.listItems.length - 1) || (this.listItems[this.listItems.length - 1].value != "")) {
      this.addOption();
    }
  }

  addOption() {
    this.listItems.push({
      label: '',
      value: ''
    });
  }

  removeOption(list, index) {
    list.splice(index, 1);
  }

  addList() {
    if (this.listForm.valid && this.listItems.length > 0 && this.listItems[0].value) {
      this.loaderService.showLoader();
      let listObjects = [];
      this.listItems.forEach((element, i) => {
        let listObject = {};
        if (element.value) {
          listObject['value'] = element.value;
          listObject['position'] = (i + 1);
          listObject['is_inactive'] = false;
          listObject['score'] = element.score;
          listObject['colour_rgb'] = element.colour_rgb;
          listObjects.push(listObject);
        }
      });

      let requestObject = {
        name: this.listForm.value.name,
        description: this.listForm.value.description,
        label: this.listForm.value.name,
        is_scored: this.listForm.value.is_scored,
        listValues: listObjects,
        is_account_list: true
      };

      this.apiService.post(constants.api_end_points.ADD_LIST, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.loaderService.hideLoader();
          this.apiService.successSnackBar('List Added!');
          this.dialogRef.close({
            id: response.data[0].id,
            listName: this.listForm.value.name,
            listValues: response.data[0].list_values
          });
          this.listForm.reset();
        }
        else {
          this.apiService.errorSnackBar(response.message)
        }
      })
    } else {
      if (!this.listForm.valid)
        this.apiService.errorSnackBar('Please enter a valid list name');
      else if (this.listItems.length > 0 && !this.listItems[0].value)
        this.apiService.errorSnackBar('Please enter a label');
    }
  }

  updateList(event) {
    this.dialogRef.close(event);
  }

  cancel() {
    this.dialogRef.close();
  }

}
