import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { constants } from '../providers/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'; //remove when the snackbar is removed
import { LoaderService } from './loader.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  autoLoggedOut: any;

  constructor(private http: HttpClient,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private _snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private router: Router, //remove when the snackbar is removed
    ) { }

  get(path: string, options = {}): Observable<any> {
    return this.http
      .get(`${environment.api_url}${path}`, options)
      .pipe(map(response => {
        if (response['code'] == 401) {
          this.autoLogoutUser();
          return false;
        }
        return response as any;
      }),
        catchError(this.handleErrors.bind(this)));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${environment.api_url}${path}`, JSON.stringify(body))
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  post(path: string, body): Observable<any> {
    return this.http
      .post(`${environment.api_url}${path}`, JSON.stringify(body))
      .pipe(map(response => {
        if (response['code'] == 401) {
          this.autoLogoutUser();
          return false;
        }
        return response as any;
      }),
        catchError(this.handleErrors.bind(this)));
  }

  fileUpload(path: string, body): Observable<any> {
    return this.http
      .post(`${environment.api_url}${path}`, body)
      .pipe(map(response => {
        if (response['code'] == 401) {
          // this.autoLogoutUser();
          return false;
        }
        return response as any;
      }),
        catchError(this.handleErrors.bind(this)));
  }

  fileDownload(url, body) {
    return this.http
        .post(`${environment.api_url}${url}`, body, {responseType: 'blob'})
  }

  handleErrors(error: any) {
    console.log(error);
    return throwError(error.error);
  }

  autoLogoutUser() {
    if (!this.autoLoggedOut) {
      this.loaderService.hideLoader();
      this.autoLoggedOut = true;
      this.get(constants.api_end_points.LOGOUT).subscribe(response => {
        // localStorage.setItem('autoLoggedOut', 'true');
        //alert('Session Expired! Please login again.');
        this.actionSnackBarLogin('Session Expired! Please login again.', 'Log in');
        this.menuCtrl.close();
        localStorage.removeItem('SS-user');
        // this.router.navigate(['login']);
        this.navCtrl.navigateRoot(['logout']);
      })
    }
  }

  successSnackBar(message: string) {
    this._snackBar.open(message, 'Close', {
      duration: 4000,
      panelClass: ['snackbar-success']
    });
  }

  errorSnackBar(message: string) {
    this._snackBar.open(message, 'Close', {
      duration: 4000,
      panelClass: ['snackbar-error']
    });
  }

  successActionSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      panelClass: ['snackbar-success']
    });
  }

  actionSnackBarLogin(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      panelClass: ['snackbar-success']
    })
    .onAction()
    .subscribe(() => this.router.navigateByUrl('/login'));
    ;
  }
}
