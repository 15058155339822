import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import { TourService } from 'src/app/services/tour.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-user-settings-content',
  templateUrl: './user-settings-content.component.html',
  styleUrls: ['./user-settings-content.component.scss'],
})
export class UserSettingsContentComponent implements OnInit {

  selectedSegment: any = 1;
  changePasswordForm: any;
  timezoneForm: any;
  country: any;
  timezone: any;
  timeZones: any = [];
  countries: any = [];
  userTypes: any = [];

  filteredCountries: Observable<any[]>;

  accountsList: any = [];
  searchInput: string = '';

  alertsData: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public menuService: MenuService,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    public commonMethodsService: CommonMethodsService,
    private _snackBar: MatSnackBar,
    public tourService: TourService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.selectedSegment = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '1';
    console.log(this.selectedSegment);
    this.initializeTimezoneForm();
    this.intializeChangePasswordForm();
  }

  ionViewDidEnter() {
    this.onSegmentChange(this.selectedSegment);
  }

  resetTimeZoneValue() {
    this.setTimezone();
  }

  updateTimeZone() {
    let requestObject = {
      timezone_id: this.timezoneForm.value.timezone
    };
    this.apiService.post(constants.api_end_points.UPDATE_TIME_ZONE, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Settings updated!');
        this.menuService.updateUserDetails();
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    })
  }

  intializeChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=])(?=\\S+$).{8,20}$")]),
      confirmNewPassword: new FormControl(null, [Validators.required])
    });
  }


  initializeTimezoneForm() {
    this.timezoneForm = this.formBuilder.group({
      country: new FormControl(null, [Validators.required]),
      timezone: new FormControl(null, [Validators.required])
    });

    let countryControl = this.timezoneForm.get('country');
    countryControl.reset();
    this.filteredCountries = countryControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value['country']),
        map(name => name ? this.filterCountries(name) : this.countries.slice())
      );
  }

  getUserAlertsSettings() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.GET_ALERTS_SETTINGS).subscribe(response => {
      if (response.code == 200) {
        this.alertsData = response.data;
        this.loaderService.hideLoader();
        this.alertsData.forEach(alert => {
          alert.items.forEach(element => {
            element['status'] = !element.is_inactive;
          });
        });
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.apiService.errorSnackBar('Something Went Wrong!');
      this.loaderService.hideLoader();
    })
  }

  getUserAccounts() {
    this.loaderService.showLoader();
    this.apiService.get(constants.api_end_points.USER_ACCOUNTS).subscribe(response => {
      if (response.code == 200) {
        this.loaderService.hideLoader();
        this.accountsList = response.data;
        // this.presentPopover(response.data);
      } else {
        this.loaderService.hideLoader();
        this.apiService.errorSnackBar(response.message);
      }
    }, err => {
      this.loaderService.hideLoader();
      this.apiService.errorSnackBar('Something Went Wrong!');
    })
  }

  getCountries() {
    this.apiService.get(constants.api_end_points.COUNTRIES).subscribe(response => {
      if (response.code == 200) {
        this.countries = response.data;
        this.setTimezone();
        // this.getTimeZones(response.data[0].id);
      }
    })
  }

  getTimeZones(id = null) {
    let requestObject = {
      "countryId": id ? id : 1
    };

    this.apiService.post(constants.api_end_points.TIME_ZONES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.timeZones = response.data;
        // this.registrationForm.patchValue({timezone: ''});
        // let timezoneControl = this..get('timezone');
        // timezoneControl.reset();
        this.timezone = null;
      }
    });
  }

  setTimezone() {
    let country = this.countries.find(x => x.id == this.menuService.userDetails.time_zone.country_id);
    if (country)
      this.getTimeZones(country.id);
    this.timezoneForm.patchValue({ country: country ? country : null, timezone: this.menuService.userDetails.time_zone.id })
  }

  onSegmentChange(event) {
    switch (this.selectedSegment) {
      case '2': this.intializeChangePasswordForm(); break;
      case '3': this.getUserAlertsSettings(); break;
      case '4': this.initializeTimezoneForm(); this.getCountries(); break;
      case '5': this.getUserAccounts(); break;
    }
  }

  navigateToTab(event) {
    this.router.navigate(['user-settings/' + this.selectedSegment]);
  }

  changePassword() {
    let requestObject = {
      password: this.changePasswordForm.value.oldPassword,
      newPassword: this.changePasswordForm.value.newPassword
    };
    this.apiService.post(constants.api_end_points.CHANGE_PASSWORD, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.apiService.successSnackBar('Password Changed! Please re-login to continue');
        this.commonMethodsService.logout();
        // this.router.navigate(['login']);
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    });
  }

  setHeightForSegmentContainer() {
    $(document).ready(function () {
      setTimeout(() => {
        // console.log($(".content-class")[0].clientHeight);
        // console.log($('body')[0].clientHeight);
        // console.log($(".verification-header")[0].clientHeight);
        // console.log($("ion-segment")[0].clientHeight);
        // let addClassHeight = $(".content-class")[0].clientHeight - 48 - 45;
        let addClassHeight = $('body')[0].clientHeight - 96 - 51;
        if (addClassHeight > 0) {
          $(".user-settings-segment-container").css('height', addClassHeight + 'px');
          console.log(addClassHeight)
        }
      }, 500);
    });
  }

  getClassForSelectedAccount(id) {
    if (id == this.menuService.accountDetails.id)
      return 'selected-item';
    return '';
  }

  displayFn(value) {
    // console.log(event);
    return value && value.country ? value.country : '';
  }

  optionChange(event) {
    event.option.value ? this.getTimeZones(event.option.value.id) : '';
  }

  filterCountries(value) {
    const filterValue = value ? value.toLowerCase() : '';
    return this.countries.filter(option => option.country.toLowerCase().startsWith(filterValue));
  }

  switchAccount(account) {
    account.id != this.menuService.accountDetails.id ? this.presentAlertConfirm(account) : '';
  }

  updateAlertSettings() {
    let alerts = [];
    this.alertsData.forEach(alert => {
      alert.items.forEach(element => {
        let alertObject = {
          is_inactive: !element.status,
          id: element.id
        };
        alerts.push(alertObject);
      });
    });
    let requestObject = {
      settings: alerts
    };
    this.apiService.post(constants.api_end_points.UPDATE_ALERT_SETTINGS, requestObject).subscribe(response => {
      this.getUserAlertsSettings();
      if (response.code == 200) {
        this.apiService.successSnackBar('Your alert settings have been updated');
      } else {
        this.apiService.errorSnackBar(response.message);
      }
    });
  }

  async presentAlertConfirm(account) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: "md",
      header: 'Switch Account',
      message: 'Are you sure you want to switch account?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            localStorage.setItem('account', JSON.stringify(account));
            this.loaderService.showLoader();
            this.menuService.switchAccount(account).then(res => {
              this.loaderService.hideLoader();
              this.menuService.setAccountDetails(account);
            });
            this.router.navigate(['feed']);
            this.apiService.successSnackBar('Account changed')
          }
        }
      ]
    });

    await alert.present();
  }

}
