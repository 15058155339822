import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { constants } from "src/app/providers/constants";
import { ApiService } from "src/app/services/api.service";
import { MenuService } from "src/app/services/menu.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonMethodsService } from "src/app/services/common-methods.service";
import { LoaderService } from "src/app/services/loader.service";
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { File } from "@ionic-native/file/ngx";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  @Input() displayEditProfileForm: any;
  @Input() viewPendingUser: any;
  @Input() viewUser: any;
  @Input() userId: any;

  @Output() updateView: any = new EventEmitter<any>();
  @Output() cancelEvent: any = new EventEmitter<any>();

  editUser: any;

  userDetails: any;
  userTypes: any = [];
  timezones: any = [];
  countries: any = [];

  filteredCountries: Observable<any[]>;

  profileForm: any;
  // file: any;

  constructor(
    private crop: Crop,
    private file: File,
    public router: Router,
    private camera: Camera,
    private platform: Platform,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public menuService: MenuService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private loaderService: LoaderService,
    public commonMethodsService: CommonMethodsService,
    private actionSheetController: ActionSheetController

  ) { }

  ngOnInit() {
    this.initializeForm().then(data => {
      this.profileForm = data;
      this.profileForm.updateValueAndValidity();
      if (this.route.snapshot.paramMap.get('userId') || this.userId) {
        if (!this.viewPendingUser) {
          this.displayEditProfileForm = true;
        }
        this.getUserTypes();
        this.getTimeZones();
        this.getCountries();
        this.getUserDetails(this.userId ? this.userId : this.route.snapshot.paramMap.get('userId'));
      } else {
        if (this.menuService.userDetails)
          this.userDetails = this.menuService.userDetails;
        else {
          let userDetails = localStorage.getItem('SS-user-details');
          if (userDetails) {
            this.userDetails = JSON.parse(userDetails);
          }
        }
      }
    });
  }

  getUserDetails(id) {
    console.log(this.viewUser);
    this.initializeForm().then(data => {
      this.profileForm = data;
      this.profileForm.updateValueAndValidity();
      if (!this.viewPendingUser) {
        this.editUser = true;
        this.displayEditProfileForm = true;
      }
      let requestObject = {
        id: id
      };
      this.userId = id;
      console.log(this.viewUser);
      this.apiService.post(constants.api_end_points.GET_TEAM_MEMBER_DETAILS, requestObject).subscribe(response => {
        console.log(response.data);
        this.userDetails = response.data;
        if (this.userDetails.type_id) {
          let userTypeLabel = this.userTypes.find(x => x.id == this.userDetails.type_id);
          if (userTypeLabel) {
            this.userDetails['userTypeLabel'] = userTypeLabel.name;
          }
        }
        if (this.userDetails.timezone_id) {
          let timezoneLabel = this.timezones.find(x => x.id == this.userDetails.timezone_id);
          if (timezoneLabel) {
            this.userDetails['timezoneLabel'] = timezoneLabel.timeZoneName + '(' + timezoneLabel.timeZoneValue + ')';;
          }
        }
        let country = this.countries.find(x => x.id == response.data.country_id);
        if (country) {
          this.getTimeZones(country.id);
          response.data['country'] = country;
        }
        this.initializeForm(response.data).then(data => {
          console.log(data);
          this.profileForm = data;
          this.profileForm.updateValueAndValidity();
          console.log(this.viewUser);
          let countryControl = this.profileForm.get('country');
          // countryControl.reset();
          this.filteredCountries = countryControl.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value['country']),
              map(name => name ? this.filterCountries(name) : this.countries.slice())
            );
        });
      });
    })
  }

  getUserTypes() {
    this.apiService.get(constants.api_end_points.USER_TYPES).subscribe(response => {
      if (response.code == 200) {
        this.userTypes = response.data;
      }
    })
  }

  getCountries() {
    this.apiService.get(constants.api_end_points.COUNTRIES).subscribe(response => {
      if (response.code == 200) {
        this.countries = response.data;
        // this.getTimeZones(response.data[0].id);
      }
    })
  }

  getTimeZones(id = null) {
    let requestObject = {
      "countryId": id ? id : 1
    };

    this.apiService.post(constants.api_end_points.TIME_ZONES, requestObject).subscribe(response => {
      if (response.code == 200) {
        this.timezones = response.data;
        // this.registrationForm.patchValue({timezone: ''});
        // let timezoneControl = this.profileForm.get('timezone');
        // timezoneControl.reset();
      }
    });
  }

  // getTimeZones() {
  //   let requestObject = {
  //     "countryId": 1
  //   };

  //   this.apiService.post(constants.api_end_points.TIME_ZONES, requestObject).subscribe(response => {
  //     if (response.code == 200) {
  //       this.timezones = response.data;
  //     }
  //   })
  // }

  async initializeForm(data = null) {
    // this.profileForm = this.formBuilder.group({
    return this.formBuilder.group({
      fullName: new FormControl(
        {
          value: data && data.full_name
            ? data.full_name
            : null, disabled: ((this.router.url == '/view-user/' + this.userId) || this.viewUser) ? true : false
        },
        [Validators.required]
      ),
      preferredName: new FormControl(
        {
          value: data && data.preferred_name
            ? data.preferred_name
            : null, disabled: ((this.router.url == '/view-user/' + this.userId) || this.viewUser) ? true : false
        },
        [Validators.required]
      ),
      emailId: new FormControl(
        {
          value: data && data.email
            ? data.email
            : null,
          disabled: ((this.router.url == '/view-user/' + this.userId) || this.viewUser) ? true : false,
        },
        [Validators.required]
      ),
      // mobile: new FormControl(
      //   {
      //     value: data.mobile_number
      //       ? data.mobile_number
      //       : null, disabled: ((this.router.url == '/view-user/' + this.userId) || this.viewUser) ? true : false
      //   },
      //   [Validators.required, Validators.pattern("^[0-9]{10}$")]
      // ),
      profile_picture: new FormControl((data && data.profile_picture) ? data.profile_picture : null, []),
      is_inactive: new FormControl((data && (data.is_inactive || data.is_inactive == false)) ? data.is_inactive : false, [Validators.required]),
      userType: new FormControl(data && data.type_id ? data.type_id : null, this.editUser ? [Validators.required] : []),
      country: new FormControl({ value: (data && data.country ? data.country : null), disabled: ((this.router.url == '/view-user/' + this.userId) || this.viewUser) ? true : false }, this.editUser ? [Validators.required] : []),
      timezone: new FormControl(data && data.timezone_id ? data.timezone_id : null, this.editUser ? [Validators.required] : [])
    });
  }

  setTimezone() {
    let country = this.countries.find(x => x.id == this.menuService.userDetails.time_zone.country_id);
    if (country)
      this.getTimeZones(country.id);
    this.profileForm.patchValue({ country: country ? country : null, timezone: this.menuService.userDetails.time_zone.id })
  }

  displayFn(value) {
    // console.log(event);
    return value && value.country ? value.country : '';
  }

  optionChange(event) {
    event.option.value ? this.getTimeZones(event.option.value.id) : '';
  }

  filterCountries(value) {
    const filterValue = value ? value.toLowerCase() : '';
    return this.countries.filter(option => option.country.toLowerCase().startsWith(filterValue));
  }

  disableSelectControl() {
    if ((this.router.url == '/view-user/' + this.userId) || this.viewUser)
      return true;
    return false;
  }

  editProfile() {
    if (this.viewPendingUser)
      this.viewUser = false;
    this.displayEditProfileForm = true;
    this.initializeForm(this.userDetails).then(data => {
      this.profileForm = data;
      this.profileForm.updateValueAndValidity();
    });
  }

  updateProfile() {
    let requestObject = {
      full_name: this.profileForm.value.fullName,
      preferred_name: this.profileForm.value.preferredName,
      email: this.profileForm.value.emailId,
      mobile_number: this.profileForm.value.mobile,
      is_inactive: this.profileForm.value.is_inactive,
      profile_picture: this.profileForm.value.profile_picture
    };
    if (!this.editUser) {
      if (!(this.route.snapshot.paramMap.get('userId') || this.userId)) {
        requestObject = {
          full_name: this.userDetails.full_name,
          preferred_name: this.userDetails.preferred_name,
          email: this.userDetails.email,
          mobile_number: this.userDetails.mobile_number,
          is_inactive: this.userDetails.is_inactive,
          profile_picture: this.userDetails.profile_picture
        }
      }
      this.apiService
        .post(constants.api_end_points.UPDATE_PROFILE, requestObject)
        .subscribe((response) => {
          if (response.code == 200) {
            this.loaderService.hideLoader();
            this.apiService.successSnackBar("Profile Updated");
            this.displayEditProfileForm = false;
            // this.menuService.setUserDetails(response.data);
            this.menuService.getUserDetails();
          } else {
            this.loaderService.hideLoader();
            this.apiService.errorSnackBar(response.message);
          }
        }, err => {
          this.loaderService.hideLoader();
          this.apiService.errorSnackBar('Something Went Wrong!');
        });
    } else {
      requestObject['timezone_id'] = this.profileForm.value.timezone;
      requestObject['type_id'] = this.profileForm.value.userType;
      requestObject['id'] = Number(this.userId);
      this.apiService.post(constants.api_end_points.UPDATE_TEAM_MEMBER_DETAILS, requestObject).subscribe(response => {
        if (response.code == 200) {
          this.apiService.successSnackBar('User Details Updated');
          this.displayEditProfileForm = false;
          if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
            // this.location.back();
            this.router.navigate(['account-settings']);
          } else {
            this.updateView.emit(true);
          }
        }
      })
    }
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Choose an option',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'Open Camera',
          icon: 'camera-outline',
          handler: () => {
            // console.log('Share clicked');
            this.openCamera(1);
          }
        },
        {
          text: 'Open Gallery',
          icon: 'image-outline',
          handler: () => {
            // this.openGallery();
            this.openCamera(0);
          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]
    });
    await actionSheet.present();

  }

  fileUriToBlob(imageData) {
    return new Promise((resolve, reject) => {
      let fileName = "";
      this.file
        .resolveLocalFilesystemUrl(imageData)
        .then(fileEntry => {
          let { name, nativeURL } = fileEntry;

          let path = nativeURL.substring(0, nativeURL.lastIndexOf("/"));
          console.log("path", path);
          console.log("fileName", name);

          fileName = name;
          return this.file.readAsArrayBuffer(path, name);
        })
        .then(buffer => {
          let imgBlob = new Blob([buffer], {
            type: "image/jpeg"
          });

          resolve({
            fileName,
            imgBlob
          });
        })
        .catch(e => reject(e));
    });
  }


  openFileUpload() {
    if ((this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb')) {
      this.presentActionSheet();
    } else {
      let fileUpload = document.getElementById('fileDropRef');
      fileUpload.click();
    }
  }

  openCamera(sourceType) {
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      // targetHeight: 250,
      // targetWidth: 250,
      sourceType
    }
    this.camera.getPicture(options).then((image) => {
      if (sourceType == 0) {
        image = 'file:///' + image;
      }
      this.crop.crop(image, { quality: 50 }).then(croppedImage => {
        console.log('cropped', croppedImage);
        this.fileUriToBlob(croppedImage).then((res) => {
          console.log(res);
          this.loaderService.showLoader();
          this.uploadProfilePicture(res['imgBlob'], res['fileName']);
        }, err => {
          console.log(err);
        })
      }, err => {
        console.log(err);
      })
    }, (err) => {
      // Handle error
      console.log(err);
    });

  }


  fileBrowseHandler(files) {
    let file;
    console.log(files);
    if (files.length > 1) {
      alert('You cannot attach multiple files!');
    } else {
      file = files[0];
      this.loaderService.showLoader();
      this.uploadProfilePicture(file, file.filename);
    }
  }

  uploadProfilePicture(file, filename) {
    console.log(file);
    let requestObject = new FormData();
    requestObject.set('file', file, filename);
    this.apiService.fileUpload(constants.api_end_points.UPLOAD_FILE, requestObject).subscribe(response => {
      if (response.code == 200) {
        // this.file = response.data;
        this.profileForm.patchValue({ profile_picture: response.data.url });
        this.userDetails.profile_picture = response.data.url;
        this.updateProfile();
      }
    });
  }

  cancel() {
    if (this.editUser || this.viewPendingUser) {
      if ((this.platform.is('pwa') && !this.platform.is('desktop')) || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
        // this.location.back();
        this.router.navigate(['account-settings']);
      } else {
        this.cancelEvent.emit(true);
      }
    } else {
      this.displayEditProfileForm = false;
    }

  }

}
