import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from 'src/app/common-components/popover/popover.component';
import { constants } from 'src/app/providers/constants';
import { ApiService } from 'src/app/services/api.service';
import { MenuService } from 'src/app/services/menu.service';
import { TourService } from 'src/app/services/tour.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  @Input() userTypes: any = [];
  @Input() usersList: any = [];
  @Input() activeUsers: any = [];
  @Input() inactiveUsers: any = [];
  @Input() dataSource: any = [];
  @Input() dataSource1: any = [];

  @Output() editUser: any = new EventEmitter<any>();
  @Output() viewUser: any = new EventEmitter<any>();
  @Output() viewPendingUser: any = new EventEmitter<any>();
  @Output() deactivateUser: any = new EventEmitter<any>();
  @Output() approveUser: any = new EventEmitter<any>();

  selectedSegment: any = 0;

  userTableData: any = [
    {
      user: 'Joel Kininmonth',
      email_id: 'joel.kininmonth@solv.com.au',
      user_type: 'employee',
      status: true,
    },
    {
      user: 'Glen McIvor',
      email_id: 'glen.mcivor@solv.com.au',
      user_type: 'employee',
      status: true,
    },
    {
      user: 'Neil Mogridge',
      email_id: 'neil.mogridge@solv.com.au',
      user_type: 'employee',
      status: true,
    },
  ]


  displayedColumns: string[] = ['avatar', 'full_name', 'email', 'user_type', 'is_inactive', 'actions'];

  constructor(private router: Router,
    private apiService: ApiService,
    public menuService: MenuService,
    private popoverController: PopoverController,
    public tourService: TourService,
    ) { }

  ngOnInit() {
    // this.getUsersList();
  }

  getUsersList() {
    this.apiService.get(constants.api_end_points.USERS).subscribe(response => {
      if (response.code == 200) {
        let activeUsers = [], inactiveUsers = [];
        response.data.forEach(element => {
          // if (element.user.id !== this.menuService.userDetails.id) {
          let userType = this.userTypes.find(x => x.id == element.type_id);
          if (userType) {
            element.details['userType'] = userType;
          }
          if (!element.details.is_pending) {
            activeUsers.push(element.details);
          } else {
            inactiveUsers.push(element.details);
          }
          // }
        });
        this.activeUsers = activeUsers;
        this.inactiveUsers = inactiveUsers;
        this.dataSource = new MatTableDataSource(activeUsers);
        this.dataSource1 = new MatTableDataSource(inactiveUsers);
      }
    })
  }

  displayPopover(event, e) {
    this.presentPopover(event, e);
  }

  async presentPopover(ev: any, e) {
    let popoverList = [
      {
        id: 1,
        title: 'Edit'
      },
      {
        id: 1,
        title: 'Disable'
      }
    ]
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      componentProps: { popoverList: popoverList, label: e.is_inactive ? 'Enable' : 'Disable' },
      event: ev,
      translucent: true
    });

    popover.onDidDismiss().then((result) => {
      if (result && result.data) {
        switch (result.data) {
          case 'edit': this.editUser.emit(e); break;
          case 'deactivate': this.deactivateUser.emit(e); break;
        }
      }
    });

    console.log(e);
    return await popover.present();
  }

  emitViewUser(e) {
    this.viewUser.emit(e);
  }

  emitViewPendingUser(e) {
    this.viewPendingUser.emit(e);
  }

  emitEditUser(e) {
    this.editUser.emit(e);
  }

  emitDeactivateUser(e) {
    this.deactivateUser.emit(e);
  }

  emitApproveUser(e) {
    this.approveUser.emit(e);
  }
  
  nameToInitials(fullName) {
    if (fullName) {
      const namesArray = fullName.trim().split(' ');
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
    }
  };

  logout() {
    this.apiService.get(constants.api_end_points.LOGOUT).subscribe(response => {
      if (response.code == 200) {
        localStorage.removeItem('SS-user');
        this.router.navigate(['login']);
      }
    })
  }

}
