import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { CommonMethodsService } from 'src/app/services/common-methods.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss'],
})
export class AddMenuComponent implements OnInit {

  list: any = [1,23,3];

  constructor(
    private router: Router,
    public menuService: MenuService,
    private bottomSheet: MatBottomSheet,
    public commonMethodsService: CommonMethodsService
    ) { }

  ngOnInit() {
    console.log(this.menuService.activeModulesList);
  }

  navigateTo(route) {
    this.bottomSheet.dismiss();
    this.router.navigate([route]);
  }

}
